import {useState} from 'react';
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';

interface NodeFilterBooleanProps {
    selected?: string | null,
    onSearch: (value: string) => void
}

const NodeFilterBoolean = (props: NodeFilterBooleanProps) => {

    const [search, setSearch] = useState<string>(props.selected || '');

    const handleChange = (event: SelectChangeEvent) => {
        setSearch(event.target.value);
        props.onSearch(event.target.value);
    };


    return (
        <div style={{padding: '1em'}}>
            <FormControl sx={{margin: 0, width: '100%'}} size="small">
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={search}
                    onChange={handleChange}
                >
                    <MenuItem value={'true'}>Да</MenuItem>
                    <MenuItem value={'false'}>Нет</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
};

export {NodeFilterBoolean};
