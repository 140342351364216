import {Button} from '@mui/material';
import {ExpandLess} from '@mui/icons-material';
import React, {useEffect, useState} from 'react';
import './scroll-to-top.scss';

const ScrollToTop = () => {

    const [visible, setVisible] = useState<boolean>(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        });
    };

    const handleScroll = () => {
        setVisible(!!window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });
    return (
        <>
            {visible &&
                <div className={'scroll-to-top'}>
                    <Button
                        className={'icon-button'}
                        disableElevation={true}
                        variant={'outlined'}
                        onClick={scrollToTop}
                    >
                        <ExpandLess/>
                    </Button>
                </div>
            }
        </>
    );
};

export {ScrollToTop};
