import {createSlice} from '@reduxjs/toolkit';


const alertSlice = createSlice({
    name: 'alert',
    initialState:{
        message: null,
        header: null,
        errorColor: '',
    },
    reducers: {
        showAlert(state, action){
            state.message = action.payload.message;
            state.header = action.payload.header;
            state.errorColor = action.payload.errorColor;
        },
        resetAlert(state){
            state.message = null;
            state.header = null;
            state.errorColor = '';
        }
    }
});

export const {showAlert, resetAlert} = alertSlice.actions;

export default alertSlice.reducer;
