import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {LoadingButton} from '@mui/lab';

import {SyntheticEvent, useEffect, useState} from 'react';
import {JsonSchema} from '@jsonforms/core';
import {materialCells, materialRenderers} from '@jsonforms/material-renderers';
import {JsonForms} from '@jsonforms/react';
import {componentItemDialogUiSchema, ComponentVariants} from './component-item-dialog.meta';
import {ValidationMode} from '@jsonforms/core';
import {ItemComponentFormData} from '../component-item-links.interface';
import {ApiLinkedNodeItem, ApiNodeItem, ApiNodeItemVariant} from '../../../../../interface';
import {useAppDispatch, useAppSelector} from '../../../../../hook/store';
import {getNode, NodeState} from '../../../../../store/slice/node-slice';
import {NodeTypeState} from '../../../../../store/slice/node-type-slice';
import FormulaInputRenderer, {
    formulaInputTester
} from '../../../../../ui/json-form-renderers/formula-input-renderer/formula-input-renderer';
import DependentAutocompleteRenderer, {
    dependentAutocompleteTester
} from './dependent-autocomplete-renderer/dependent-autocomplete-renderer';
import {CompassContext} from '../../../../../hook/compas-context';
import AutocompleteMultipleRenderer, {
    autocompleteMultipleTester
} from '../../../../../ui/json-form-renderers/autocomplete-multiple-renderer/autocomplete-multiple-renderer';
import AutocompleteMultipleAsyncRenderer, {
    autocompleteMultipleAsyncTester
} from './async-autocomplete-multiple-renderer/autocomplete-multiple-async-renderer';

interface NewComponentItemDialogProps {
    handleClose: () => void;
    handleUpdate: (data: ApiNodeItem) => void;
    itemData?: any | null;
    readonly: boolean;
    optionId?: string;
}

const renderers = [...materialRenderers,
    {tester: formulaInputTester, renderer: FormulaInputRenderer},
    {tester: autocompleteMultipleTester, renderer: AutocompleteMultipleRenderer},
    {tester: dependentAutocompleteTester, renderer: DependentAutocompleteRenderer},
    {tester: autocompleteMultipleAsyncTester, renderer: AutocompleteMultipleAsyncRenderer},

];

interface TypeOption {
    const: string;
    title: string;
}


const CustomError = {
    instancePath: '',
    message: 'Обязательное поле',
    schemaPath: '',
    keyword: '',
    params: {},
}

const ComponentItemDialog = ({
                                 handleClose,
                                 handleUpdate,
                                 itemData,
                                 readonly,
                                 optionId
                             }: NewComponentItemDialogProps) => {
    const dispatch = useAppDispatch();
    const [formSchema, setFormSchema] = useState<JsonSchema>();
    const [validationMode, setValidationMode] = useState<ValidationMode>('ValidateAndHide');
    const [formData, setFormData] = useState<any>();
    const [additionalError, setAdditionalError] = useState<any[]>([]);
    const [inProgress, setInProgress] = useState(false);
    const {currentNodeItem, filteredNodes} = useAppSelector<NodeState>(store => store.node);
    const {allNodeTypes} = useAppSelector<NodeTypeState>(store => store.nodeType);


    const createFormSchema = () => {
        if (!allNodeTypes || !currentNodeItem) {
            return;
        }
        const type = allNodeTypes.find(t => t.id === currentNodeItem.type);
        const types = type?.allowChildren || [];

        const typeOptions: TypeOption[] = types.map(t => {
            return {
                const: t,
                title: allNodeTypes?.find(v => v.id === t)?.typeName || ''
            };
        });


        const schema: JsonSchema = {
            type: 'object',
            properties: {
                name: {
                    title: 'ID',
                    type: 'string',
                    ...!!itemData && {'readOnly': readonly},
                },
                variant: {
                    title: 'Способ выбора',
                    type: 'string',
                    enum: Object.values(ComponentVariants),
                    ...!!itemData && !readonly ? {'readOnly': true} : readonly ? {'readOnly': true} : {'readOnly': false},
                },
                type: {
                    title: 'Тип*',
                    oneOf: typeOptions,
                    ...!!itemData && !readonly ? {'readOnly': true} : readonly ? {'readOnly': true} : {'readOnly': false},
                },
                variantTypes: {
                    title: 'Тип(ы)*',
                    type: 'array',
                    items: {
                        enum: typeOptions
                    },
                },
                linkedNodeId: {
                    type: 'integer',
                    title: 'Компонент*',

                },
                variantNodeIds: {
                    title: 'Варианты компонентов*',
                    type: 'array',
                },
                variantName: {
                    title: 'Название*',
                    type: 'string',
                },
                value: {
                    type: 'object',
                    title: 'Количество',
                    properties: {
                        value: {
                            title: 'Количество (значение)',
                            type: 'integer',
                        },
                        formula: {
                            title: 'Количество (формула)',
                            type: 'string',
                        },
                    }
                }
            },
            required: ['name', 'value']

        };
        setFormSchema(schema);
    };

    const handleChange = (nextData: any) => {
        const {data} = nextData
        removeVariantErrors(data);
        if (data && data.name) {
            if (currentNodeItem?.options && optionId && currentNodeItem?.options?.[optionId]) {
                const optionKeys = Object.keys(currentNodeItem?.options?.[optionId].links || {});
                if (optionKeys.includes(data.name) && (!itemData || (!!itemData && data.name !== itemData.name))) {
                    const error = {
                        instancePath: '/name',
                        message: 'этот id не может быть использован',
                        schemaPath: '',
                        keyword: '',
                        params: {},
                    };
                    setAdditionalError([error]);
                } else {
                    if (additionalError.length) {
                        setAdditionalError([]);
                    }
                }
            } else if (!optionId) {
                const linkKeys = currentNodeItem?.links && Object.keys(currentNodeItem.links) || [];
                const variantKeys = currentNodeItem?.linkVariants && Object.keys(currentNodeItem.linkVariants) || [];
                let keys = [...linkKeys, ...variantKeys];

                if ((itemData) && keys) {
                    keys = keys.filter(v => v !== itemData.name && v !== itemData.id);
                }

                if (keys?.includes(data.name)) {
                    const error = {
                        ...CustomError,
                        instancePath: '/name',
                        message: 'этот id не может быть использован',
                    };
                    setAdditionalError((prev) => [...prev.filter((e) => e.instancePath !== '/name'), error]);
                } else if (!/^[a-zA-Z0-9]*$/gm.test(data.name)) {
                    const error = {
                        ...CustomError,
                        instancePath: '/name',
                        message: 'Поле может содержать только латинские буквы и цифры',
                    };
                    setAdditionalError((prev) => [...prev.filter((e) => e.instancePath !== '/name'), error]);
                } else {
                    if (additionalError.length) {
                        setAdditionalError((prev) => prev.filter((e) => e.instancePath !== '/name'));
                    }
                }
            }
        }

        setFormData(nextData);
    };

    const removeVariantErrors = (data: any) => {
        let errors = [...additionalError]
        if (data.variantTypes && data.variantTypes.length) {
            errors = errors.filter((e: any) => e.instancePath !== '/variantTypes');
        }
        if (data.variantNodeIds && data.variantNodeIds.length) {
            errors = errors.filter((e: any) => e.instancePath !== '/variantNodeIds');
        }
        if (!!data.type) {
            errors = errors.filter((e: any) => e.instancePath !== '/type');
        }
        if (!!data.linkedNodeId) {
            errors = errors.filter((e: any) => e.instancePath !== '/linkedNodeId');
        }
        if (!!data.variantName) {
            errors = errors.filter((e: any) => e.instancePath !== '/variantName');
        }
        setAdditionalError(errors)
    };

    const checkVariantErrors = () => {
        const {data} = formData;

        const errors = [...additionalError];
        if (data.variant !== ComponentVariants.library) {
            if (!data.variantName) {
                errors.push({
                    ...CustomError,
                    instancePath: '/variantName',
                });
            }
            if (!data.variantTypes || !data.variantTypes.length) {
                errors.push({
                    ...CustomError,
                    instancePath: '/variantTypes',
                });
            }
            if (data.variant === ComponentVariants.oneOf && (!data.variantTypes || !data.variantNodeIds.length)) {
                errors.push({
                    ...CustomError,
                    instancePath: '/variantNodeIds',
                });
            }
        } else {
            if (!data.type) {
                errors.push({
                    ...CustomError,
                    instancePath: '/type',
                });
            }
            if (!data.linkedNodeId) {
                errors.push({
                    ...CustomError,
                    instancePath: '/linkedNodeId',
                });
            }
        }
        setAdditionalError(errors);
        return !!errors.length;
    };


    const onSubmit = (e: SyntheticEvent) => {

        if (checkVariantErrors()) {
            e.preventDefault();
            return;
        }
        const errors = formData?.errors ? formData.errors.filter((e: any) => e.keyword !== 'oneOf' && e.keyword !== 'const') : [];
        if ((errors.length) || additionalError.length) {
            setValidationMode('ValidateAndShow');
        } else if (optionId) {
            if (formData.data.variant === ComponentVariants.library) {
                updateNodeItemOptions(formData.data);
            } else {
                updateNodeVariants(optionId);
            }
        } else {
            if (formData.data.variant === ComponentVariants.library) {
                updateNodeItem(formData.data);
            } else {
                updateNodeVariants();
            }
        }
        e.preventDefault();
    };


    const updateNodeItemOptions = (data: ItemComponentFormData) => {
        if (currentNodeItem && optionId) {
            setInProgress(true);
            const nodeType = data.type;
            dispatch(getNode({id: nodeType, params: {}})).unwrap()
                .then((res) => {
                    const typeItem = res.result.find((v: ApiNodeItem) => v.id === data.linkedNodeId);
                    const linkName = data.name;
                    const linkData: ApiLinkedNodeItem = {
                        linkedNode: {...typeItem},
                        linkedNodeId: data.linkedNodeId,
                        masterNodeId: currentNodeItem.id,
                        quantityFormula: data.value.formula || null,
                        quantityValue: data.value.value || null,
                        name: data.name,
                    };

                    let newOptionLinks;
                    if (currentNodeItem.options?.[optionId]) {
                        newOptionLinks = {...currentNodeItem.options?.[optionId].links};
                    }

                    if (!!itemData && newOptionLinks) {
                        delete newOptionLinks[itemData.name];
                    }

                    if (newOptionLinks) {
                        newOptionLinks[linkName] = linkData;
                    }

                    const formData: ApiNodeItem = {
                        ...currentNodeItem,
                        options: {
                            ...currentNodeItem.options,
                            [optionId]: {
                                ...currentNodeItem.options?.[optionId],
                                links: newOptionLinks,
                            }
                        } as any,
                    };
                    handleUpdate(formData);
                })
                .finally(() => {
                    setInProgress(false);
                });
        }
    };

    const updateNodeVariants = (optionId?: string) => {

        const {data} = formData
        let variant: ApiNodeItemVariant = {} as ApiNodeItemVariant;
        if (data.variant !== ComponentVariants.library) {
            const nodeIds = data.variant == ComponentVariants.oneOf ? data.variantNodeIds.map((t: any) => t.const) : []

            variant = {
                name: data.variantName,
                id: data.name,
                nodeTypes: data.variantTypes.map((t: any) => t.const),
                nodeIDs: nodeIds,
                nodeIdFormula: '',
                quantityFormula: data.value.formula || null,
                quantityValue: data.value.value || null,
            };

            let formData: ApiNodeItem = {} as ApiNodeItem;

            if (optionId) {
                const option = currentNodeItem?.options[optionId];
                const linkVariants = {...option?.linkVariants};
                if(!!itemData){
                    delete linkVariants[itemData.name]
                }
                const updatedOption = {
                    ...option,
                    linkVariants: {
                        ...linkVariants,
                        [data.name]: variant
                    }
                }
                const updatedOptions = {
                    ...currentNodeItem?.options,
                    [optionId]: updatedOption,
                };
                formData = {
                    ...currentNodeItem,
                    options: updatedOptions
                } as ApiNodeItem;
            } else {
                const linkVariants = {...currentNodeItem?.linkVariants};
                if(!!itemData){
                    delete linkVariants[itemData.name]
                }
                formData = {
                    ...currentNodeItem,
                    linkVariants: {
                        ...linkVariants,
                        [data.name]: variant
                    },
                } as ApiNodeItem;
            }


            handleUpdate(formData);
        }
    };

    const updateNodeItem = (data: ItemComponentFormData) => {
        if (currentNodeItem) {
            setInProgress(true);
            const nodeType = data.type;
            dispatch(getNode({id: nodeType, params: {}})).unwrap()
                .then((res) => {
                    const typeItem = res.result.find((v: ApiNodeItem) => v.id === data.linkedNodeId);
                    const linkName = data.name;
                    const linkData: ApiLinkedNodeItem = {
                        linkedNode: {...typeItem},
                        linkedNodeId: data.linkedNodeId,
                        masterNodeId: currentNodeItem.id,
                        quantityFormula: data.value.formula || null,
                        quantityValue: data.value.value || null,
                        name: data.name,
                    };

                    const newLinks = {...currentNodeItem.links};
                    if (!!itemData) {
                        delete newLinks[itemData.name];
                    }

                    newLinks[linkName] = linkData;

                    const formData: ApiNodeItem = {
                        ...currentNodeItem,
                        links: newLinks,
                    };
                    handleUpdate(formData);
                })
                .finally(() => {
                    setInProgress(false);
                });
        }
    };

    useEffect(() => {
        if (!!allNodeTypes && !!currentNodeItem) {
            if (itemData) {
                let variant = ComponentVariants.library;
                let variantTypes: any[] = [];
                let variantNodeIds: any[] = [];
                if ((Array.isArray(itemData.nodeTypes))) {
                    const types = allNodeTypes.filter(t => itemData.nodeTypes.includes(t.id))
                    if (Array.isArray(types) && types.length) {
                        variantTypes = types.map(t => (
                            {
                                const: t.id,
                                title: t.typeName
                            }
                        ))
                    }

                    if (Array.isArray(itemData.nodeIDs) && itemData.nodeIDs.length) {
                        variant = ComponentVariants.oneOf;
                        variantNodeIds = itemData.nodeIDs.map((id: number) => {
                            const node = filteredNodes?.find(n => n.id === id);
                            return {
                                const: id,
                                title: node?.name || id,
                            }
                        });
                    } else {
                        variant = ComponentVariants.any;
                    }
                }


                setFormData({
                    data: {
                        name: variant !== ComponentVariants.library ? itemData.id : itemData.name,
                        type: itemData.linkedNode.type,
                        variant: variant,
                        variantName: itemData.variantName || '',
                        variantTypes: variantTypes,
                        variantNodeIds: variantNodeIds,
                        linkedNodeId: itemData.linkedNodeId,
                        value: itemData.quantityFormula ? {
                            formula: itemData.quantityFormula
                        } : {
                            value: itemData.quantityValue
                        }
                    }
                });
            } else {
                setFormData({
                    data: {
                        variant: ComponentVariants.library,
                        variantTypes: undefined,
                        variantNodeIds: undefined,
                    }
                });
            }
            createFormSchema();
        }
    }, [allNodeTypes, currentNodeItem]);


    useEffect(() => {
        // delete selected node variants on node type selection cehcnge
        if (Array.isArray(filteredNodes) && Array.isArray(formData?.data?.variantNodeIds)) {

            const nodeIds = formData?.data?.variantNodeIds.filter((v: any) => {
                return filteredNodes.some((f: ApiNodeItem) => {
                    return f.id === v.const;
                });
            });

            const variantNodeIds = nodeIds.map((n: any) => {
                return {
                    ...n,
                    title: filteredNodes.find(f => f.id === n.const)?.name || n.const
                };
            });

            setFormData((prevValue: any) => {
                return {
                    data: {
                        ...prevValue.data,
                        variantNodeIds
                    }
                }
            });
        }

    }, [filteredNodes])

    return <>
        <CompassContext.Provider value={{item: itemData || null, isLink: true}}>
            <Dialog
                className="new-item-dialog"
                open={true}
                maxWidth={'xl'}
                onClose={handleClose}
            >
                <DialogTitle className="dialog-title">
                    {!!itemData ? 'Редактировать Компонент' : 'Добавить Компонент'}

                </DialogTitle>
                <form onSubmit={onSubmit}>
                    <DialogContent style={{minHeight: 280}}>
                        {formSchema && (
                            <JsonForms
                                readonly={readonly}
                                data={formData?.data}
                                schema={formSchema}
                                uischema={componentItemDialogUiSchema}
                                renderers={renderers}
                                cells={materialCells}
                                validationMode={validationMode}
                                additionalErrors={additionalError}
                                onChange={handleChange}
                            />
                        )}
                    </DialogContent>
                    <DialogActions className="form-buttons">
                        {readonly ? (
                            <Button
                                variant={'contained'}
                                onClick={handleClose}
                            >
                                Закрыть
                            </Button>
                        ) : (
                            <>
                                <Button
                                    variant={'outlined'}
                                    onClick={handleClose}
                                    disabled={inProgress}
                                >
                                    Отмена
                                </Button>
                                <LoadingButton
                                    loading={inProgress}
                                    type={'submit'}
                                    variant={'contained'}
                                    disabled={inProgress}
                                >
                                    {itemData ? 'Сохранить' : 'Добавить'}
                                </LoadingButton>
                            </>
                        )}
                    </DialogActions>
                </form>
            </Dialog>
        </CompassContext.Provider>
    </>;
};

export {ComponentItemDialog};
