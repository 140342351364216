import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';

import { useEffect } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './component-item-testing-dialog.scss';
import { TestingParamsProps } from '../../../../interface';
import { DictionaryTypeState,  } from '../../../../store/slice/dictionary-type-slice';
import { useAppDispatch, useAppSelector } from '../../../../hook/store';
import { getAllDictionaryTypes } from '../../../../store/slice/dictionary-type-slice';
import { ComponentItemTestParamRow } from './component-item-testing-param-row';

interface ComponentItemTestingDialogProps {
  toggleTesting: () => void;
  handleClose: () => void;
  testParams: TestingParamsProps[];
  handleChangeTestParams: (nextState: any) => void;
}

export enum TestParamsType  {
  string = 'Строка',
  number = 'Число',
  boolean = 'Да/нет',
  policy = 'Политика',
}

const ComponentItemTestingDialog = ({ toggleTesting, handleClose, testParams, handleChangeTestParams }: ComponentItemTestingDialogProps) => {
    const {allDictionaryTypes} = useAppSelector<DictionaryTypeState>(store => store.dictionaryType);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (!allDictionaryTypes) {
        dispatch(getAllDictionaryTypes());
      }
    }, [allDictionaryTypes, dispatch]);  

    const handleChangeTestParam = (rowId: number, e: any) => {
      const findParamIndex = testParams.findIndex(testParam => testParam.id === rowId);
      const newErrors = testParams[findParamIndex].errors.filter(item => item !== 'parameter');
      if (findParamIndex > -1) {
        const newState = testParams.map((testParam) => {
          if (testParam.id === rowId) {
            return { ...testParam, parameter: e.target.value, errors: newErrors };
          }
          return testParam;
        });
        handleChangeTestParams(newState);
      }
    };

    const handleChangeParamType = (rowId: number, e: any) => {
      const findParamIndex = testParams.findIndex(testParam => testParam.id === rowId);
      const newErrors = testParams[findParamIndex].errors.filter(item => item !== 'type');
      if (findParamIndex > -1) {
        const newState = testParams.map((testParam) => {
          if (testParam.id === rowId) {
            return { ...testParam, type: e.target.value, errors: newErrors };
          }
          return testParam;
        });
        handleChangeTestParams(newState);
      }
    };

    const handleChangeParamValue = (rowId: number, e: any, dictType?: any) => {
      const findParamIndex = testParams.findIndex(testParam => testParam.id === rowId);
      const newErrors = testParams[findParamIndex].errors.filter(item => item !== 'value');
      if (findParamIndex > -1) {
        const newState = testParams.map((testParam) => {
          if (testParam.id === rowId) {
            if(!dictType) {
              return { ...testParam, value: e.target.value, errors: newErrors };
            } else {
              return { ...testParam, value: { id: dictType.id, name: dictType.typeName }, errors: newErrors };
            }
          }
          return testParam;
        });
        handleChangeTestParams(newState);
      }
    }; 

    const handleDeleteParamsRow = (rowId: number) => {
      const filteredParams = testParams.filter(row => row.id !== rowId);
      handleChangeTestParams(filteredParams);
    };

    const handleAddParamsRow = () => {
      handleChangeTestParams([...testParams, {id: Date.now(), parameter: '', type: '', value: '', errors: []}]);
    };

    const getParameterValueField = (row: any, rowId: number) => {
      const inputStyles = {
        border: row.errors.includes('value') ? '1px solid #EF4444' : '1px solid var(--color-divider-nested)',
      }
      const errorMessage = 'Обязательное поле для заполнения';

      const findParameterType = testParams.find(testParam => testParam.id === rowId);
      if(findParameterType) {
        switch(findParameterType.type) {
          case TestParamsType.string:
            return (
              <>
                <input name={`value${rowId}`} type="text" value={row.value} onChange={(e) => handleChangeParamValue(rowId, e)} style={inputStyles} />
                {row.errors.includes('value') && <p className="errorMessage">{errorMessage}</p>}
              </>
              );
          case TestParamsType.number:
            return (
              <>
                <input name={`value${rowId}`} type="number" value={row.value} onChange={(e) => handleChangeParamValue(rowId, e)} style={inputStyles} />
                {row.errors.includes('value') && <p className="errorMessage">{errorMessage}</p>}
              </>
            )
          case TestParamsType.boolean:
            return (
              <>
                <FormControl sx={{ margin: 0, width: '100%' }} size="small">
                  <Select
                    className="testDialogSelect"
                    defaultValue={row.value}
                    onChange={(e) => handleChangeParamValue(rowId, e)}
                    sx={inputStyles}
                  >
                    <MenuItem value={'Да'}>Да</MenuItem>
                    <MenuItem value={'Нет'}>Нет</MenuItem>
                  </Select>
                </FormControl>
                {row.errors.includes('value') && <p className="errorMessage">{errorMessage}</p>}
              </>
            );
          case TestParamsType.policy:
            return (
              <div>
                <FormControl sx={{ margin: 0, width: '100%' }} size="small">
                  <Select
                    className="testDialogSelect"
                    name={`policy${rowId}`}
                    defaultValue={row.value.name}
                    sx={inputStyles}
                  >
                    {allDictionaryTypes
                      ?.filter(
                        (dictType) =>
                          dictType.typeSchema.properties.hasOwnProperty('predicate') &&
                          dictType.typeSchema.properties.hasOwnProperty('weight') &&
                          dictType.typeSchema.properties.hasOwnProperty('value')
                      )
                      .map((dictType) => (
                        <MenuItem
                          value={dictType.typeName}
                          onClick={(e) =>
                            handleChangeParamValue(rowId, e, dictType)
                          }
                          key={dictType.id}
                        >
                          {dictType.typeName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {row.errors.includes('value') && <p className="errorMessage">{errorMessage}</p>}
              </div>
            );
          default:
            return (
              <div className="inputContainer">
                <input name={`value${rowId}`} type="text" value={row.value} onChange={(e) => handleChangeParamValue(rowId, e)} style={inputStyles} />
                {row.errors.includes('value') && <p className="errorMessage">{errorMessage}</p>}
              </div>
            )
        }
      }
    };

    const handleRecalculateNode = () => {
      toggleTesting();
    };

    return (
      <>
        <Dialog
          className="component-testing-dialog"
          open={true}
          maxWidth={'lg'}
          onClose={handleClose}
        >
          <DialogTitle className="dialog-title">Тестовые параметры</DialogTitle>
          <div className="infoContainer">
            <InfoOutlinedIcon style={{ color: '#6C757D' }} />
            <p>
              Для собранной конфигурации требуется добавить и настроить тестовые
              параметры.
            </p>
          </div>
          <div className="formContainer">
            <div className="labelContainer">
              <p className="label">Параметр</p>
              <p className="label">Тип</p>
              <p className="label">Значение</p>
            </div>
            <div className="inputsContainer">
              {testParams.map((row) => (
                <ComponentItemTestParamRow
                  key={row.id}
                  row={row}
                  getParameterValueField={getParameterValueField}
                  handleChangeTestParam={handleChangeTestParam}
                  handleChangeParamType={handleChangeParamType}
                  handleDeleteParamsRow={handleDeleteParamsRow}
                />
              ))}
            </div>
            <Button
              onClick={handleAddParamsRow}
              variant={'outlined'}
              className="addButton"
            >
              <AddIcon /> Добавить еще параметр
            </Button>
          </div>
          <DialogActions className="testing-buttons">
            <Button variant={'outlined'} onClick={handleClose}>
              Отменить проверку
            </Button>
            <LoadingButton
              type={'submit'}
              variant={'contained'}
              onClick={handleRecalculateNode}
            >
              Подтвердить проверку
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </>
    );
};

export { ComponentItemTestingDialog };
