import {
    Grid,
    GridCell,
    GridCellProps,
    GridColumn,
    GridSortChangeEvent,
    GridColumnReorderEvent,
    GridNoRecords as NoRecords
} from '@progress/kendo-react-grid';
import {GridHeaderCellProps} from '@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps';
import React, {ReactElement} from 'react';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {SortDescriptor} from '@progress/kendo-data-query';
import {useNavigate} from 'react-router-dom';
import {format, parseISO} from 'date-fns';

import {useAppDispatch, useAppSelector} from '../../../../hook/store';
import {AppSuspense} from '../../../../components/app-suspense/app-suspense';
import {changeSpecAuthor} from '../../../../store/slice/spec-slice';
import {DATE_TIME} from '../../../../constants/date-time';
import {
    ApiUser,
    SearchParams,
    SpecInterface, SpecItem, StatusLabel
} from '../../../../interface';
import {NODE_FILTER} from '../../../component/node-item/node-item.interface';
import ObjectHelper from '../../../../helpers/object.helper';
import {SpecificationActions} from '../specification-actions/specification-actions';
import {AppPagination} from '../../../../components/app-pagination/app-pagination';
import {setSortParam} from '../../../../helpers';
import {SpecTypeState} from '../../../../store/slice/spec-type-slice';
import {ROUTE_PATH} from '../../../../constants/routes';
import {TypeColumnMenu, ColumnInterface} from '../../../../components';
import {CheckboxFilter, CheckboxFilterOption} from '../../../component/components/grid-header-filters/type-filter-cell';
import {TextFilter} from '../../../component/components/grid-header-filters/text-filter';
import {NodeFilter} from '../../../component/components/grid-header-filters/node-filter';
import {DateFilter} from '../../../component/components/grid-header-filters/date-filter';
import './specification-list-grid.scss';

interface SpecificationListGridProps {
    searchParams: URLSearchParams;
    sort: SortDescriptor[];
    columns: ColumnInterface[];
    data: SpecInterface[];
    totalSpec: number;
    onChanges: (params: any) => void;
    onColumnsChange: (columns: ColumnInterface[]) => void;
    resetFilter: (field: string) => void;
}

const SpecificationListGrid = ({
                                   searchParams,
                                   sort,
                                   columns,
                                   data,
                                   totalSpec,
                                   onChanges,
                                   onColumnsChange,
                                   resetFilter
                               }: SpecificationListGridProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {allSpecTypes} = useAppSelector<SpecTypeState>(store => store.specType);
    const exceptionalFields = ['id', 'price', 'deliveryWeeks', 'currencyRate', 'supportYears'];

    const onSort = (data: SortDescriptor[]): void => {
        if (data.length && data[0].field === 'typeId') {
            onChanges(setSortParam([{
                field: 'specTypeName',
                dir: data[0].dir
            }], searchParams as unknown as SearchParams));
        } else {
            onChanges(setSortParam(data, searchParams as unknown as SearchParams));
        }
    };

    const gridActions = (props: GridCellProps): ReactElement => {
        return <td style={{textAlign: 'right'}}>
            <SpecificationActions itemData={props.dataItem} itemPage={false} onAuthorChange={handleAuthorChange}/>
        </td>;
    };

    const handleAuthorChange = (user: ApiUser, itemData: SpecItem) => {
        dispatch(changeSpecAuthor({specId: itemData.id, authorName: user.fullName}));
    };

    const handleRowClick = (data: any, event?: React.MouseEvent<HTMLTableDataCellElement>): void => {
        let nativeEvent;
        if (event) {
            nativeEvent = event.nativeEvent;
        } else {
            nativeEvent = data.nativeEvent;
        }
        if (nativeEvent?.ctrlKey || nativeEvent?.metaKey) {
            window.open(`/${ROUTE_PATH.specifications}/${data.dataItem.id}`, '_blank');
        } else {
            navigate(`/${ROUTE_PATH.specifications}/${data.dataItem.id}`);
        }
        if (!!nativeEvent) {
            nativeEvent.stopPropagation();
        }
    };

    const nameCell = (props: GridCellProps): ReactElement => {
        const dataItem: SpecInterface = props.dataItem;
        return <td onClick={(e) => handleRowClick(props, e)}>
            <div className="grid-cell" style={{paddingRight: '8px'}}>{dataItem.name}</div>
        </td>;
    };

    const dateCell = (props: GridCellProps): ReactElement => {
        const dataItem: SpecInterface = props.dataItem;
        return (
            <td onClick={(e) => handleRowClick(props, e)}>
                <div className="grid-cell">
                    <p>{format(parseISO(dataItem.lastModified), DATE_TIME)}</p>
                </div>
            </td>
        );
    };

    const typeCell = (props: GridCellProps): ReactElement => {
        if (!allSpecTypes) {
            return <></>;
        }
        const type = allSpecTypes.result.find(t => t.id === props.dataItem.typeId);
        return <td onClick={(e) => handleRowClick(props, e)}>
            <div className="grid-cell">
                <p>{type?.name || ''}</p>
                <span className={'version'}>v{type?.version}</span>
            </div>
        </td>;
    };

    const statusCell = (props: GridCellProps): ReactElement => {
        return <td onClick={(e) => handleRowClick(props, e)}>
            <div className="grid-cell"><p>{StatusLabel[props.dataItem.status as keyof typeof StatusLabel]}</p></div>
        </td>;
    };

    const authorCell = (props: GridCellProps): ReactElement => {
        return <td onClick={(e) => handleRowClick(props, e)}>
            <div className="grid-cell"><p>{props.dataItem.author}</p></div>
        </td>;
    };

    const deliveryWeeksCell = (props: GridCellProps): ReactElement => {
        return (
            <td onClick={(e) => handleRowClick(props, e)}>
                <div className="grid-cell"><p>{Number(props.dataItem.deliveryWeeks) || '-'}</p></div>
            </td>
        );
    };

    const expertModeCell = (props: GridCellProps): ReactElement => {
        return (
            <td onClick={(e) => handleRowClick(props, e)}>
                <div className="grid-cell"><p>{props.dataItem.expertMode ? 'Да' : 'Нет'}</p></div>
            </td>
        );
    };

    const priceCell = (props: GridCellProps): ReactElement => {
        if (props.dataItem.price) {
            const priceStr = props.dataItem.price.toString();
            const parts = priceStr.split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

            return (
                <td onClick={(e) => handleRowClick(props, e)}>
                    <div className="grid-cell amountCell">
                        <p>{parts.join(",")}</p>
                    </div>
                </td>
            );
        }
        return (
            <td onClick={(e) => handleRowClick(props, e)}>
                <div className="grid-cell">
                    <p></p>
                </div>
            </td>
        );
    };

    const currencyRateCell = (props: GridCellProps): ReactElement => {
        return (
            <td onClick={(e) => handleRowClick(props, e)}>
                <div className="grid-cell amountCell" style={{paddingRight: '74px'}}>
                    <p>{props.dataItem.currencyRate}</p>
                </div>
            </td>
        );
    };

    const getCell = (props: GridCellProps): ReactElement<HTMLTableCellElement> => {
        if (props.field === 'name') {
            return nameCell(props);
        }
        if (props.field === 'typeId') {
            return typeCell(props);
        }
        if (props.field === 'status') {
            return statusCell(props);
        }
        if (props.field === 'lastModified') {
            return dateCell(props);
        }
        if (props.field === 'author') {
            return authorCell(props);
        }
        if (props.field === "deliveryWeeks") {
            return deliveryWeeksCell(props);
        }
        if (props.field === "expertMode") {
            return expertModeCell(props);
        }
        if (props.field === "price") {
            return priceCell(props);
        }
        if (props.field === "currencyRate") {
            return currencyRateCell(props);
        }
        return <GridCell {...props}/>;
    };

    const handleReorderColumns = (event: GridColumnReorderEvent) => {
        const newOrder = [...event.columns];
        onChanges({
            ...Object.fromEntries(searchParams.entries()),
            'columns': newOrder.sort((a, b) => a.orderIndex! - b.orderIndex!).map(col => col.field).join(';'),
        });
    };

    const pageChange = (params: SearchParams): void => {
        onChanges(
            {
                ...Object.fromEntries(searchParams.entries()),
                offset: params.offset || '0',
                limit: params.limit || '50'
            });
    };

    const updateFilter = (field: string, filter: string) => {
        if (!!filter) {
            if (field === NODE_FILTER.types) {
                const params = ObjectHelper.pickParams(Object.fromEntries(searchParams.entries()), ['limit', 'showArchive']) as URLSearchParams;
                onChanges(
                    {
                        ...Object.fromEntries(searchParams.entries()),
                        ...params,
                        offset: '0',
                        [field]: filter
                    });
            } else if (field === NODE_FILTER.expertMode) {
                onChanges({
                    ...Object.fromEntries(searchParams.entries()),
                    offset: "0",
                    [field]: filter.toLowerCase() === 'нет' ? false : true,
                });
            } else {
                onChanges({
                    ...Object.fromEntries(searchParams.entries()),
                    offset: "0",
                    [field]: filter,
                });
            }
        }
    };

    const textSearchCell = (props: any): ReactElement<any> => {
        return <div>
            <TextFilter
                {...props}
                onFilter={(filter) => updateFilter('name', filter)}
                selected={searchParams.get('name')}
                onReset={() => resetFilter('name')}
            />
        </div>;
    };

    const typeSelectCell = (props: GridHeaderCellProps): ReactElement<any> => {
        let options: CheckboxFilterOption[] = [];
        if (allSpecTypes && Array.isArray(allSpecTypes.result)) {
            options = [...allSpecTypes.result]
                .sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0)
                .map((v) => {
                    const current = allSpecTypes.result.filter( (o) => o.code === v.code);
                    const multiple = Array.isArray(current) && current.length > 1;

                    return {
                        name: v.name,
                        value: v.id,
                        version: multiple ? v.version : undefined,
                        checked: false
                    }
                });

        }

        return <div>
            <CheckboxFilter {...props}
                            options={options}
                            selected={searchParams.get(NODE_FILTER.types)}
                            onFilter={(filter) => updateFilter(NODE_FILTER.types, filter)}
                            onReset={() => resetFilter(NODE_FILTER.types)}
            />
        </div>;
    };

    const dateSearchCell = (props: any, field: NODE_FILTER): ReactElement<any> => {
        return <div>
            <DateFilter
                {...props}
                onFilter={(filter) => updateFilter(field, filter)}
                selected={searchParams.get(field)}
                onReset={() => resetFilter(field)}
            />
        </div>;
    };

    const statusSelectCell = (props: any) => {
        const options: CheckboxFilterOption[] = Object.entries(StatusLabel)
            .map(([key, value]) => ({
                name: value,
                value: key,
                checked: false
            }));

        return <div>
            <CheckboxFilter {...props}
                            options={options}
                            selected={searchParams.get('status')}
                            onFilter={(filter) => {
                                updateFilter('status', filter);
                            }}
                            onReset={() => resetFilter('status')}
            />
        </div>;
    };

    const getHeaderCell = (props: GridHeaderCellProps, column: ColumnInterface) => {
        switch (column.type) {
            case 'type':
                return <>{typeSelectCell(props)}</>;
            case 'name':
                return <>{textSearchCell(props)}</>;
            case 'modified':
                return <>{dateSearchCell(props, NODE_FILTER.modified)}</>;
            case 'status':
                return <>{statusSelectCell(props)}</>;
            case 'author':
                return <div>
                    <TextFilter
                        {...props}
                        onFilter={(filter) => updateFilter(NODE_FILTER.author, filter)}
                        selected={searchParams.get(NODE_FILTER.author)}
                        onReset={() => resetFilter(NODE_FILTER.author)}
                    />
                </div>;
            default:
                return <div>
                    <NodeFilter {...props}
                                onFilter={(field, filter) => updateFilter(field, filter)}
                                column={column}
                                onReset={resetFilter}
                                ignoreSearchType={exceptionalFields.includes(column.searchField || '')}
                    />
                </div>;
        }
        return null;
    };

    return <AppSuspense condition={!!data}>
        <Card className="h100">
            <CardBody>
                <div className={'universal-grid'}>
                    {!!data ? (
                        <>
                            <Grid
                                className={'spec-grid'}
                                style={{width: '100%'}}
                                sortable={true}
                                sort={sort}
                                data={data}
                                onSortChange={(e: GridSortChangeEvent) => {
                                    onSort(e.sort);
                                }}
                                reorderable={true}
                                resizable={true}
                                rowHeight={32}
                                onRowClick={handleRowClick}
                                onColumnReorder={handleReorderColumns}
                            >
                                {columns.map((col, idx) => col.show && (
                                    <GridColumn
                                        key={idx}
                                        field={col.field}
                                        title={col.title}
                                        orderIndex={idx}
                                        sortable={col.sortable}
                                        cell={(props) => getCell(props)}
                                        width={col.width || 'auto'}
                                        minResizableWidth={100}
                                        headerCell={(props) => getHeaderCell(props, col)}
                                    />
                                ))}
                                <GridColumn
                                    field=""
                                    title=""
                                    width={100}
                                    cell={gridActions}
                                    headerClassName={'actions-menu'}
                                    orderIndex={columns.length + 1}
                                    locked={true}
                                    reorderable={false}
                                    resizable={false}
                                    headerCell={() => (
                                        <TypeColumnMenu
                                            columns={columns}
                                            searchParams={searchParams}
                                            onFilter={(filter: string) => updateFilter('columns', filter)}
                                            onColumnsChange={onColumnsChange}
                                            type={'specification'}
                                        />
                                    )}
                                />

                                <NoRecords>
                                    Список пуст.
                                </NoRecords>
                            </Grid>
                        </>
                    ) : (
                        <div style={{textAlign: 'center'}}>Спецификации не найдены.</div>
                    )}
                </div>
                <div className="spec-pager-wrapper">
                    <div className="page-info">

                    </div>
                    <div className="spec-pager">
                        <AppPagination params={{
                            offset: searchParams.get('offset') || undefined,
                            limit: searchParams.get('limit') || undefined,
                        }} total={totalSpec}
                                       onPageChange={pageChange}
                                       showButtons={true}
                                       pageSizes={[10, 50, 100, 200]}
                        />

                    </div>
                    <div className="download">

                    </div>
                </div>
            </CardBody>
        </Card>
    </AppSuspense>;
};

export {SpecificationListGrid};
