import './login.scss';
import {useAppDispatch} from '../../hook/store';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    FormControlLabel,
    FormGroup,
    TextField
} from '@mui/material';
import {useForm} from 'react-hook-form';
import {userLogin} from '../../store/slice/auth-slice';
import {useAuth} from '../../hook/use-auth';
import {Navigate, useSearchParams,} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {ReactComponent as Logo} from '../../assets/img/logo.svg';
import {getCurrentUser} from '../../store/slice/user-slice';
import {useAppSelector} from '../../hook/store';
import {UserState} from '../../store/slice/user-slice';
import {getSettings, SettingsState} from '../../store/slice/settings-slice';
import {AppSuspense} from '../../components/app-suspense/app-suspense';


const Login = () => {

    const [inProgress, setInProgress] = useState<boolean>(false);
    const [showLoginForm, setShowLoginForm] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const {isAuthorized} = useAuth();
    const {currentUser, isForbiddenUser} = useAppSelector<UserState>(state => state.user);
    const {settings} = useAppSelector<SettingsState>(state => state.settings);


    // TODO: добавлено для игнорирования ssoEnable. Нужно удалить, когда в этом не будет необходимости
    const [params] = useSearchParams();


    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
        setError,
        reset,
    } = useForm({
        mode: 'all'
    });

    const onSubmit = async (data: any) => {
        setInProgress(true);
        await dispatch(userLogin(data)).unwrap()
            .then(() => {
                dispatch(getCurrentUser({isLogin: true}));
            })
            .catch(() => {
                reset();
                setError('login', {type: 'custom', message: ' '});
                setError('password', {type: 'custom', message: 'Пользователь с таким именем и паролем не найден'});
            })
            .finally(() => {
                setInProgress(false);
            })
        ;
    };

    useEffect(() => {
        // TODO: params, params.get('ignoreSso') - добавлены для игнорирования ssoEnable.
        //  Нужно удалить, когда в этом не будет необходимости
        if (!settings) {
            dispatch(getSettings());
        }
        if(!currentUser){
            dispatch(getCurrentUser({isLogin: false}));
        }

        if (settings && params && currentUser) {
            if (params.get('ignoreSSO') || !settings.ssoEnable) {
                setShowLoginForm(true);
            } else {
                window.location.href = '/sso/signin';
            }
        }
    }, [settings, params, currentUser]);

    if (isAuthorized && currentUser) {
        return <Navigate to={'/'}/>;
    }

    return (
        <>
            <AppSuspense condition={showLoginForm}>
                <div className="login-wrapper">
                    <div className="login-form">
                        <div className="logo">
                            <Logo/>
                        </div>
                        <Card>
                            <CardHeader className="title" title="Вход в систему"/>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <CardContent className="constent">

                                    <FormGroup>
                                        <TextField {...register('login', {
                                            required: 'Обязательное поле'
                                        })}
                                                   error={!!errors?.login}
                                                   label="Логин"
                                                   variant="outlined"
                                                   size={'small'}
                                                   helperText={errors?.login ? errors.login.message as string : ''}
                                                   className={errors?.login ? 'form-field form-field-error' : 'form-field'}
                                                   autoFocus
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <TextField
                                            {...register('password', {
                                                required: 'Обязательное поле'
                                            })}
                                            error={!!errors?.password}
                                            type={'password'}
                                            label="Пароль"
                                            variant="outlined"
                                            size={'small'}
                                            helperText={errors?.password ? errors?.password.message as string : ''}
                                            className={errors?.password ? 'form-field form-field-error' : 'form-field'}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormControlLabel control={
                                            <Checkbox defaultChecked
                                                      {...register('rememberMe', {})}
                                            />
                                        } label="Запомнить меня"/>
                                    </FormGroup>
                                    {isForbiddenUser &&
                                        <p style={{marginBottom: 0, color: 'red'}}>Недостаточно прав</p>}
                                </CardContent>
                                <CardActions className="actions">
                                    <LoadingButton
                                        loading={inProgress}
                                        type={'submit'}
                                        variant={'contained'}
                                        disabled={!isValid || inProgress}
                                    >
                                        Войти
                                    </LoadingButton>

                                </CardActions>
                            </form>
                        </Card>
                    </div>
                </div>
                ;
            </AppSuspense>
        </>
    );
};

export {Login};
