import React, {ComponentType} from 'react';
import {ColumnInterface} from '../../../../../components';
import {GridCellProps} from '@progress/kendo-react-grid';


export const componentItemLinkColumns = (
    typeCell: ComponentType<GridCellProps>,
    nameCell: ComponentType<GridCellProps>,
    valueCell: ComponentType<GridCellProps>
): ColumnInterface[] => ([
    {
        title: 'ID',
        field: 'name',
        show: true,
        sortable: true,
        width: '150px'
    }, {
        title: 'Тип',
        field: 'linkedNode.type',
        show: true,
        sortable: true,
        cell: typeCell,
        width: '250px'
    },
    {
        title: 'Название',
        field: 'linkedNode.name',
        show: true,
        sortable: true,
        cell: nameCell,
        width: '250px'
    },
    {
        title: 'Кол-во',
        field: 'quantityValue',
        show: true,
        sortable: true,
        cell: valueCell,
        width: '150px'
    }
]);
