import {useState, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hook/store';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {logout, userLogout} from '../../../store/slice/auth-slice';
import {Card, CardBody, CardTitle} from '@progress/kendo-react-layout';
import {Button} from '@mui/material';
import styles from '../settings.module.scss';
import {AppPrompt} from '../../../components/app-prompt/app-prompt';
import {AppSuspense} from '../../../components/app-suspense/app-suspense';
import {UserState, getCurrentUser} from '../../../store/slice/user-slice';
import {getSettings, SettingsState} from '../../../store/slice/settings-slice';
import {ROUTE_PATH} from '../../../constants/routes';

const SettingsCommon = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const {currentUser} = useAppSelector<UserState>(store => store.user);
    const {settings} = useAppSelector<SettingsState>(state => state.settings);

    // TODO: добавлено для игнорирования ssoEnable. Нужно удалить, когда в этом не будет необходимости
    const [params] = useSearchParams();

    useEffect(() => {
        if(!settings){
            dispatch(getSettings());
        }else{
            dispatch(getCurrentUser({isLogin: false}));
        }
    }, [settings]);
 
    const onLogout = async () => {

        // TODO: params, params.get('ignoreSso') - добавлены для игнорирования ssoEnable.
        //  Нужно удалить, когда в этом не будет необходимости

        if(params.get('ignoreSSO') || !settings?.ssoEnable){
            await dispatch(userLogout()).unwrap()
                .then(() => {
                    dispatch(logout());
                    navigate(`/${ROUTE_PATH.login}`);
                })
                .catch(() => {
                    dispatch(logout());
                    navigate(`/${ROUTE_PATH.login}`);
                });
        }else{
            window.location.href = '/sso/signout';
        }

    };

    return (
        <Card className={styles.settingsCard}>
            <CardTitle className={styles.settingsTitle}>Пользователь</CardTitle>
            <CardBody className={styles.settingsBody}>
                <AppSuspense condition={true}>
                    <div className={styles.row}>
                        <div>{currentUser?.fullName}</div>
                        {!settings?.ssoEnable && (
                            <div>
                                <Button
                                    variant={'contained'}
                                    className={styles.settingsBtn}
                                    onClick={() => navigate('user/fullname')}
                                >Настроить</Button>
                            </div>
                        )}
                    </div>
                    <div className={styles.row}>
                        <div>Доступ: {settings?.ssoEnable ? <span>SSO</span> : <span>логин/пароль</span>}</div>
                        {!settings?.ssoEnable && (
                            <div>
                                <Button
                                    variant={'contained'}
                                    className={styles.settingsBtn}
                                    onClick={() => navigate('user/password')}
                                    style={{ whiteSpace: 'nowrap' }}
                                >Изменить пароль</Button>
                            </div>
                        )}
                    </div>
                </AppSuspense>
                <Button
                    variant={'contained'}
                    className={styles.settingsBtn}
                    onClick={() => setShowConfirm(true)}
                >Выйти</Button>
            </CardBody>
            {showConfirm && (
                <AppPrompt data={{
                    title: 'Подтвердите действие',
                    message: ['Выйти из системы?'],
                    confirmButton: 'Подтвердить',
                    declineButton: 'Отменить'
                }} onClose={() => setShowConfirm(false)} onConfirm={onLogout}/>
            )}
        </Card>
    );
};

export {SettingsCommon};
