import {
  Navigate,
  Outlet,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import "./cabinet-layout.scss";
import { Suspense, useEffect, useState } from "react";
import { useAuth } from "../../../hook/use-auth";
import { AppLoader } from "../../app-loader/app-loader";
import { useAppDispatch, useAppSelector } from "../../../hook/store";
import { getCurrentUser, UserState } from "../../../store/slice/user-slice";

import { AppTitle } from "../../app-title/app-title";
import { LoginError } from "../../../pages/login/login-error";
import {
  getSettings,
  SettingsState,
} from "../../../store/slice/settings-slice";
import Cookies from "js-cookie";
import { login } from "../../../store/slice/auth-slice";
import { HeaderContainer } from "../../header";

export enum SidebarState {
  COLLAPSED = "collapsed",
  EXPANDED = "expanded",
}

const CabinetLayout = () => {
  const getSidebarState = localStorage.getItem("sidebarState");
  const [sidebarState, setSidebarState] = useState(
    getSidebarState ?? SidebarState.EXPANDED
  );
  // const {isAuthorized} = useAuth();
  const dispatch = useAppDispatch();
  const [authChecked, setAuthChecked] = useState<boolean>(false);
  // const [ignoreUser, setIgnoreUser] = useState<boolean>(false);
  const { settings } = useAppSelector<SettingsState>((state) => state.settings);
  const { currentUser } = useAppSelector<UserState>((state) => state.user);
  // const location = useLocation();

  const handleChangeSidebarState = () => {
    if (sidebarState === SidebarState.EXPANDED) {
      setSidebarState(SidebarState.COLLAPSED);
      localStorage.setItem("sidebarState", SidebarState.COLLAPSED);
    } else {
      setSidebarState(SidebarState.EXPANDED);
      localStorage.setItem("sidebarState", SidebarState.EXPANDED);
    }
  };

  const isExpanded = sidebarState === SidebarState.EXPANDED;

  // const canLoad = () => {
  //     return isAuthorized && currentUser && Array.isArray(currentUser?.roles) && !!currentUser.roles.length;
  // };

  // return canLoad() ? <>
  //     <AppTitle/>
  //     <div className={`${isExpanded ? 'cabinet' : 'cabinet collapsed'}`}>
  //         <div className={`${isExpanded ? 'cabinet__sidebar' : 'cabinet__sidebar collapsed'}`}>
  //             <Sidebar isExpanded={isExpanded} handleChangeSidebarState={handleChangeSidebarState}/>
  //         </div>
  //         <div className={isExpanded ? 'cabinet__target' : 'cabinet__target collapsed'}>
  //             <Suspense fallback={<AppLoader/>}>
  //                 <Outlet/>
  //             </Suspense>
  //         </div>
  //     </div>
  // </> : !isAuthorized
  //     ? <Navigate to="/login"/>
  //     : (
  //         <>
  //             {!!currentUser?.roles && (
  //                 <LoginError/>
  //             )}
  //         </>
  //     );

  useEffect(() => {
    if (!settings) {
      dispatch(getSettings());
    }
    if (!currentUser) {
      dispatch(getCurrentUser({ isLogin: false }));
    }

    if (!!settings && !!currentUser) {
      if (Cookies.get("isAuthorized") === "1") {
        dispatch(login());
        // dispatch(getCurrentUser({isLogin: false}))
      }
      setAuthChecked(true);
    }
  }, [dispatch, settings, currentUser]);

  const isReady = (): boolean => {
    return !!authChecked && !!currentUser;
  };

  return (
    <>
      {isReady() && (
        <>
          <AppTitle />
          <HeaderContainer />
          <div className={`${isExpanded ? "cabinet" : "cabinet collapsed"}`}>
            <div
              className={
                isExpanded ? "cabinet__target" : "cabinet__target collapsed"
              }
            >
              <Suspense fallback={<AppLoader />}>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export { CabinetLayout };
