import React, {ReactElement, SyntheticEvent, useEffect, useState} from 'react';
import {
    and, ControlProps,
    isDescriptionHidden,
    isObjectControl,
    JsonSchema,
    rankWith,
    schemaMatches, showAsRequired
} from '@jsonforms/core';
import {NodeItemStatus} from '../../../interface';
import {useAppSelector} from '../../../hook/store';
import {NodeState} from '../../../store/slice/node-slice';
import {withJsonFormsControlProps} from '@jsonforms/react';
import {FormControl, FormHelperText, Hidden, InputLabel} from '@mui/material';
import {useFocus, MuiInputText, MuiInputInteger, MuiInputNumber, MuiCheckbox} from '@jsonforms/material-renderers';
import {ReactComponent as IconFunction} from '../../../assets/img/icon/function.svg';
import {FormulaInputModal} from './formula-input-modal';
import styles from './formula-input-renderer.module.scss';

type FormulaInputView = 'formula' | 'value';

export type FormulaInputAcceptedValues = string | number | boolean | undefined;

interface FormulaInputUserData {
    [key: string]: FormulaInputAcceptedValues;
}

const FormulaInputRenderer = (props: ControlProps) => {

    const {
        id,
        visible,
        required,
        schema,
        uischema,
        config,
        errors,
        description,
        path,
        data,
        rootSchema,
        handleChange
    } = props;
    const [focused, onFocus, onBlur] = useFocus();
    const isValid = errors.length === 0;
    const appliedUiSchemaOptions = {...config, ...uischema.options};
    const [view, setView] = useState<FormulaInputView>();
    const [controlSchema, setControlSchema] = useState({} as JsonSchema);
    const [userData, setUserData] = useState<FormulaInputUserData>({formula: undefined, value: undefined});
    const [formulaChanged, setFormulaChanged] = useState(false);
    const [showFormulaModal, setShowFormulaModal] = useState(false);
    const {currentNodeItem} = useAppSelector<NodeState>(store => store.node);
    const isReadOnly = (): boolean => {
        return currentNodeItem?.status !== NodeItemStatus.draft;
    };

    const showDescription = !isDescriptionHidden(
        visible,
        description,
        focused,
        appliedUiSchemaOptions.showUnfocusedDescription
    );

    const firstFormHelperText = showDescription
        ? description
        : !isValid
            ? errors
            : null;
    const secondFormHelperText = showDescription && !isValid ? errors : null;

    const handleValueChange = (value: FormulaInputAcceptedValues) => {
        if (view === 'value') {
            setUserData({
                ...userData,
                value: value
            });
        }
        updateData(value, view as FormulaInputView);
    };

    const handleFormulaChange = (value: string | undefined) => {
        setUserData({
            ...userData,
            formula: value
        });
        setFormulaChanged(true);
        updateData(value, view as FormulaInputView);
    };

    const updateDataAfterViewChange = (view: FormulaInputView): void => {
        updateData(userData[view], view);
    };

    const updateData = (value: FormulaInputAcceptedValues, selectedView: FormulaInputView) => {
        if (value === undefined || value === null) {
            handleChange(path, undefined);
        } else {
            const newValue = selectedView === 'formula' ? {formula: value} : {value};
            handleChange(path, newValue);
        }
    };

    const handleSaveFormula = (value: FormulaInputAcceptedValues) => {
        updateData(value, view as FormulaInputView);
    };

    const getInnerComponent = () => {
        switch (controlSchema.type) {
            case 'string':
                return MuiInputText;
            case 'number':
                return MuiInputNumber;
            case 'integer':
                return MuiInputInteger;
            case 'boolean':
                return MuiCheckbox;
            default:
                return MuiInputText;
        }
    };

    const handleClose = () => {
        setShowFormulaModal(false);
    };

    const InnerComponent = getInnerComponent();

    const changeView = (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (isReadOnly()) {
            return;
        }
        const newView = view === 'formula' ? 'value' : 'formula';
        setView(newView);
        updateDataAfterViewChange(newView);

    };

    const handleControlClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (view === 'formula') {
            setShowFormulaModal(true);
        }
    };

    const getFunctionLabel = (): ReactElement => {
        const active = view === 'formula' ? styles.fx_active : '';
        const readOnly = isReadOnly() ? styles.readonly : '';
        let hint = view === 'formula' ? 'Переключить в режим ввода значения' : 'Переключить в режим ввода формулы';
        if (isReadOnly()) {
            hint = config[path];
        }
        return (
            <>
                <span className={`${styles.fx_wrapper} ${readOnly}`}
                      onMouseDown={changeView}
                      onClick={(e) => e.preventDefault()}
                      title={hint}>
                    <span className={`${styles.fx_style} ${active} ${readOnly}`}>
                        <IconFunction className={styles.fx_icon_style}/>
                    </span>
                    {view === 'formula' && <span className={active}> = </span>}
                    {view === 'formula' && !formulaChanged &&
                        <span className={active}><span>{config[path]}</span></span>}
                </span>

            </>
        );
    };

    useEffect(() => {
        if (view) {
            const childSchema = schema.properties ? schema.properties[view] : {};
            setControlSchema(childSchema);
        }
    }, [view]);

    useEffect(() => {
        if (!data?.formula) {
            setUserData({
                ...userData,
                formula: undefined
            });
        }
    }, [currentNodeItem?.status]);

    useEffect(() => {
        setFormulaChanged(false);
    }, [currentNodeItem])

    useEffect(() => {

        if (data?.formula) {
            setUserData({
                ...userData,
                formula: data?.formula
            });
        } else {
            setUserData({
                ...userData,
                value: data?.value
            });
        }
    }, [data]);

    useEffect(() => {
        if (data?.formula) {
            setView('formula');
        } else {
            setView('value');
        }
    }, []);

    return (
        <>
            {view && (
                <Hidden xsUp={!visible}>
                    <FormControl
                        fullWidth={!appliedUiSchemaOptions.trim}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        id={id}
                        variant={'standard'}
                    >
                        <InputLabel
                            htmlFor={id + '-input'}
                            error={!isValid}
                            required={showAsRequired(!!required,
                                appliedUiSchemaOptions.hideRequiredAsterisk)}
                            className={styles.fx_label}
                        >
                            {controlSchema.title as string}

                            {getFunctionLabel()}
                        </InputLabel>
                        <InnerComponent
                            {...props}
                            data={userData[view]}
                            path={`${path}.${view}`}
                            id={id + '-input'}
                            isValid={isValid}
                            visible={visible}
                            handleChange={(path, value) => {
                                handleValueChange(value);
                            }}
                            muiInputProps={{
                                readOnly: !isReadOnly() && view === 'formula',
                                onClick: handleControlClick,
                            }}
                        />
                        <FormHelperText error={!isValid && !showDescription}>
                            {firstFormHelperText}
                        </FormHelperText>
                        <FormHelperText error={!isValid}>
                            {secondFormHelperText}
                        </FormHelperText>
                    </FormControl>
                </Hidden>
            )}
            {showFormulaModal && !isReadOnly() && (
                <FormulaInputModal title={controlSchema.title as string}
                                   formulaValue={userData.formula as string}
                                   handleSaveFormula={handleSaveFormula}
                                   handleClose={handleClose}
                                   handleFormulaChange={handleFormulaChange}
                                   rootSchema={rootSchema}
                />
            )}
        </>
    );
};


export const formulaInputTester = rankWith(
    3,
    and(isObjectControl, schemaMatches((schema) => (
        schema?.properties ? schema.properties.hasOwnProperty('formula') : false)
    )));

export default withJsonFormsControlProps(FormulaInputRenderer);









