import {SyntheticEvent, useEffect, useState} from 'react';
import {ApiLinkedNodeItem} from '../../../../../interface';
import {useAppSelector} from '../../../../../hook/store';
import {NodeTypeState} from '../../../../../store/slice/node-type-slice';
import {ReactComponent as InfoIcon} from '../../../../../assets/img/icon/info.svg';
import styles from './component-item-name-cell.module.scss';
import {Popover} from '@mui/material';
import {StatusBadge} from '../../../../../components/status-badge/status-badge';

interface InfoData {
    title: string;
    items: Array<{
        title: string;
        value: any;
    }>
}

interface ComponentItemNameCellProps {
    data: ApiLinkedNodeItem;
    onClick?: (data: ApiLinkedNodeItem, event?: SyntheticEvent) => void;
    showStatus?: boolean;
}

const ComponentItemNameCell = ({data, onClick, showStatus = false}: ComponentItemNameCellProps) => {
    const {allNodeTypes} = useAppSelector<NodeTypeState>(store => store.nodeType);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [itemData, setItemData] = useState<InfoData>();

    const handleClick = (event: any): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleRowClick = (event: SyntheticEvent) => {
        if (onClick) {
            onClick(data, event);
            // navigate(`/components/${data.type}/${data.id}`);
        }
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const collectData = (): void => {
        const info: InfoData = {
            title: data.linkedNode?.name || '-',
            items: []
        };
        const type = allNodeTypes?.find(t => t.id === data.linkedNode?.type);
        if (type?.typeSchema?.properties) {
            const properties = type?.typeSchema?.properties;
            const keys = Object.entries(properties);
            info.items = keys.map(([k, v]) => {
                return {
                    title: v.title,
                    value: data.linkedNode?.calculatedData[k] || '-'
                };
            });
        }
        setItemData(info);
    };

    useEffect(() => {
        if (!!data && !!allNodeTypes) {
            collectData();
        }
    }, [data, allNodeTypes]);

    const open = Boolean(anchorEl);
    const id = open ? data.id : undefined;
    return <>
        <div className={styles.name_cell} onClick={handleRowClick}>
            <div>
                <div className={styles.name_cell__wrap}>
                    <span>
                        {data.linkedNode?.name}
                        <span> {showStatus && <StatusBadge status={data.status}/>}</span>
                    </span>
                    <div className={styles.name_cell__icon}>
                        <div aria-describedby={id} className={styles.info} onClick={handleClick}>
                            <InfoIcon className={'icon'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            onClick={(e) => e.stopPropagation()}
            anchorOrigin={{
                vertical: -100,
                horizontal: 20,
            }}
        >
            {itemData && (
                <div className={styles.info_popover}>
                    <div className={styles.info_popover__title}>{itemData.title}</div>
                    <div className={styles.info_popover__wrapper}>
                        <table>
                            <tbody>
                            {itemData.items.map((item, i) => (
                                <tr key={i}>
                                    <td width={'50%'}>{item.title}</td>
                                    <td width={'50%'}>
                                        {item.value === true ? (
                                            <i className="pi pi-check"></i>
                                        ) : (
                                            item.value
                                        )}

                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </Popover>
    </>;
};

export {ComponentItemNameCell};
