import {useState, useEffect} from 'react';
import { Popover } from '@mui/material';
import {useAppSelector} from '../../../../hook/store';
import {DictionaryTypeState} from '../../../../store/slice/dictionary-type-slice';
import {ReactComponent as InfoIcon} from '../../../../assets/img/icon/info.svg';
import '../dictionary-type.scss';

interface InfoData {
    title: string;
    items: Array<{
        title: string;
        value: any;
    }>
}

const DictionaryTypeNameCell = ({dataItem}: any) => {
    const {allDictionaryTypes} = useAppSelector<DictionaryTypeState>(store => store.dictionaryType);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [itemData, setItemData] = useState<InfoData>();

    const handleClick = (event: any): void => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
      setAnchorEl(null);
    };

    const collectData = (): void => {
        const info: InfoData = {
            title: dataItem.name || '-',
            items: []
        };
        const type = allDictionaryTypes?.find(t => t.id === dataItem.type);
        if (type?.typeSchema?.properties) {
            const properties = type?.typeSchema?.properties;
            const keys = Object.entries(properties);
            info.items = keys.map(([k, v]: any) => {
                return {
                    title: v.title,
                    value: dataItem.data[k] || '-'
                };
            });
        }
        setItemData(info);
    };

    useEffect(() => {
        if (!!dataItem && !!allDictionaryTypes) {
            collectData();
        }
    }, [dataItem]);

    const getItemView = (item: string) => {
        const reg = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

        if(isNaN(Number(item)) && !isNaN(Date.parse(item)) && reg.test(item)) {
            const date = new Date(item);
            const padTo2Digits = (num: number) => {
              return num.toString().padStart(2, '0');
            };
      
            return (
                [
                    padTo2Digits(date.getDate()),
                    padTo2Digits(date.getMonth() + 1),
                    date.getFullYear(),
                ].join('-') +
                ' ' +
                [
                    padTo2Digits(date.getHours()),
                    padTo2Digits(date.getMinutes()),
                    padTo2Digits(date.getSeconds()),
                ].join(':')
            );
        }

        return item;
    };

    const open = Boolean(anchorEl);
    const id = open ? dataItem?.id : undefined;

    return (
      <>
        <div className="name_cell">
          <div>
            <div className="name_cell__wrap">
              <span>{dataItem?.name}</span>
              <div className="name_cell__icon">
                <div
                  aria-describedby={id}
                  className="info"
                  onClick={handleClick}
                >
                  <InfoIcon className={'icon'} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={(e) => e.stopPropagation()}
          anchorOrigin={{
            vertical: -100,
            horizontal: 20,
          }}
        >
          {dataItem && (
            <div className="info_popover">
              <div className="info_popover__title">
                {dataItem?.name}
              </div>
              <div className="info_popover__wrapper">
                <table>
                  <tbody>
                    {itemData?.items.map((item: {title: string, value: any}, i) => (
                      <tr key={i}>
                        <td width={'50%'}>{item.title}</td>
                        <td width={'50%'}>
                            {item.value === true ? (
                                <i className="pi pi-check"></i>
                            ) :  (
                                getItemView(item.value)
                            )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Popover>
      </>
    );
  };

  export {DictionaryTypeNameCell};
