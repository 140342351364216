export interface ComponentItemTab {
    title: string;
    path: string;
    position: 'left' | 'right';
    type: ComponentItemTabType
}

export enum ComponentItemTabType {
    edit = 'edit',
    properties = 'properties',
    history = 'history',
    references = 'references'
}
