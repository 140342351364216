export enum NODE_FILTER {
    types = 'types',
    searchQuery = 'searchQuery',
    created = 'created',
    modified = 'modified',
    author = 'author',
    expertMode = 'expertMode',
}

export enum NODE_FILTER_TYPE {
    boolean = 'boolean',
    integer = 'integer',
    number = 'number',
    string = 'string'
}
