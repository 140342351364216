import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import baseRequest from '../../utils/base-request';
import {ApiNodeType, ApiNodeTypeResult, NodeTypeSummary, SearchParams} from '../../interface';


export interface NodeTypeState {
    allNodeTypes: ApiNodeType[] | null;
    nodeTypes: ApiNodeType[] | null;
    currentNodeType: ApiNodeType | null;
    summary: NodeTypeSummary | null;
    total: number;
}


export const getAllNodeTypes = createAsyncThunk(
    'nodeType/getAllNodeTypes',
    async function (_, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: '/api/nodeType'
        }, dispatch);
        return response.data;
    }
);

export const getNodeTypes = createAsyncThunk(
    'nodeType/getNodeTypes',
    async function (params: SearchParams, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: '/api/nodeType',
            params
        }, dispatch);
        return response.data;
    }
);

export const getNodeTypeSummary = createAsyncThunk(
    'nodeType/getNodeTypeSummary',
    async function (_, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: '/api/nodeType/summary',
        }, dispatch);
        return response.data;
    }
);

export const getNodeTypeById = createAsyncThunk(
    'nodeType/getNodeTypeById',
    async function (id: string, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: `/api/nodeType/${id}`,
        }, dispatch);
        return response.data;
    }
);

const initialState: NodeTypeState = {
    allNodeTypes: null,
    nodeTypes: null,
    currentNodeType: null,
    summary: null,
    total: 0,
};

const nodeTypeSlice = createSlice({
    name: 'nodeType',
    initialState,
    reducers: {
        resetNodeTypeState(){
            return initialState;
        }
    },
    extraReducers: {
        [getNodeTypes.fulfilled as any]: (state: NodeTypeState, action: PayloadAction<ApiNodeTypeResult>) => {
            state.nodeTypes = action.payload.result;
            state.total = action.payload.total;
        },
        [getAllNodeTypes.fulfilled as any]: (state: NodeTypeState, action: PayloadAction<ApiNodeTypeResult>) => {
            state.allNodeTypes = action.payload.result;
        },
        [getNodeTypeById.fulfilled as any]: (state: NodeTypeState, action: PayloadAction<ApiNodeType>) => {
            state.currentNodeType = action.payload;
        },
        [getNodeTypeSummary.fulfilled as any]: (state: NodeTypeState, action: PayloadAction<NodeTypeSummary>) => {
            state.summary = action.payload;
        }
    }
});

export const {resetNodeTypeState} = nodeTypeSlice.actions;
export default nodeTypeSlice.reducer;
