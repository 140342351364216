import React, {useRef, useState} from 'react';
import {Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {ArrowDropDown} from '@mui/icons-material';


interface SplitLoaderButtonProps {
    items: string[];
    onButtonClick?: () => void
    onItemClick: (item: string) => void;
    inProgress: boolean
    disabled: boolean
}


const SplitLoaderButton = ({items, onButtonClick, onItemClick, inProgress, disabled}: SplitLoaderButtonProps) => {

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleMenuItemClick = (index: number) => {
        onItemClick(items[index]);
        setOpen(false);
    };


    return (
        <>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <LoadingButton
                    loading={inProgress}
                    variant={'contained'}
                    disabled={disabled}
                    onClick={onButtonClick}
                >
                    Сохранить
                </LoadingButton>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    disabled={disabled}
                    onClick={handleToggle}
                >
                    <ArrowDropDown/>
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 999,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                placement={'bottom-end'}
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => setOpen(false)}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {items.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            onClick={() => handleMenuItemClick(index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export {SplitLoaderButton};
