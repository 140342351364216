import React, {useEffect} from 'react';
import {Card, CardBody} from '@progress/kendo-react-layout';
import './specification-item-header.scss';
import {StatusBadge} from '../../../../../components/status-badge/status-badge';
import {SpecificationActions} from '../../../components/specification-actions/specification-actions';
import {SpecItem} from '../../../../../interface';
import {useAppDispatch, useAppSelector} from '../../../../../hook/store';
import {clearCurrentType, getSpecType, SpecTypeState} from '../../../../../store/slice/spec-type-slice';
import {NavLink} from 'react-router-dom';
import {SpecificationStatus} from '../../tabs/data-input/specification-data-input.interface';

interface SpecificationItemHeaderProps {
    specificationItem: SpecItem;
    onAuthorUpdate: () => void;
    status?: SpecificationStatus;
}

const SpecificationItemHeader = ({specificationItem, onAuthorUpdate, status}: SpecificationItemHeaderProps) => {
    const dispatch = useAppDispatch();
    const {currentType} = useAppSelector<SpecTypeState>(store => store.specType);

    const isEnabled = () => {
        return status === SpecificationStatus.calculated;
    };

    const handleActionUpdate = () => {
        onAuthorUpdate();
    };


    useEffect(() => {
        dispatch(getSpecType(specificationItem.typeId));

    }, [specificationItem, dispatch]);


    return <>
        <Card orientation={'horizontal'}>
            <CardBody className={'spec-item-header'}>
                <div className="title">
                    {(!!currentType && !!specificationItem) && (
                        <>
                            <span>{specificationItem?.name || ''}</span>
                            <span className={'type'}>{specificationItem?.typeId || ''}</span>
                            {specificationItem?.version && <span className={'version'}>v{currentType?.version || ''}</span>}
                        </>
                    )}
                    <StatusBadge status={specificationItem?.status}/>
                </div>
                <div className="buttons">
                    <SpecificationActions
                        itemData={specificationItem}
                        itemPage={true}
                        onAuthorChange={handleActionUpdate}
                        itemActions={true}
                        disablePublish={!isEnabled()}
                    />

                </div>
            </CardBody>
        </Card>
        <Card>
            <CardBody className="item-nav-tabs">
                <div className="nav-tabs">
                    <NavLink
                        className={({isActive}) => isActive ? 'tab active' : 'tab'}
                        to={'../data-input'}
                    >
                        Ввод данных
                    </NavLink>
                    {isEnabled() ? (
                        <>
                            <NavLink
                                className="tab"
                                to={'../specification'}
                            >
                                Спецификация
                            </NavLink>
                            <NavLink
                                className="tab"
                                to={'../documents'}
                            >
                                Документы
                            </NavLink>
                            <NavLink
                                className="tab"
                                to={'../structure'}
                            >
                                Структура решения
                            </NavLink>
                        </>
                    ) : (
                        <>
                            <div
                                className="tab disabled"
                            >
                                Спецификация
                            </div>
                            <div
                                className="tab disabled"
                            >
                                Документы
                            </div>
                            <div
                                className="tab disabled"
                            >
                                Структура решения
                            </div>
                        </>
                    )}
                </div>
            </CardBody>
        </Card>
    </>;
};

export {SpecificationItemHeader};
