import {useAppSelector} from '../../hook/store';
import {Toast} from './toast/toast';
import './app-toast.scss';

const AppToast = () => {
    const {toasts} = useAppSelector(store => store.toast);

    return <>
        <div className="toast-portal">
            {toasts.map(toast => (
                <Toast key={toast.id} toast={toast}/>
            ))}
        </div>
    </>;
};

export {AppToast};
