import {TextField} from '@mui/material';
import React, {useState} from 'react';

interface NodeFilterStringProps {
    selected?: string | null,
    onSearch: (value: string) => void
}

const NodeFilterString = (props: NodeFilterStringProps) => {

    const [search, setSearch] =useState<string>(props.selected || '');

    const onSearch = (e: any) => {
        setSearch(e.target.value);
        props.onSearch(e.target.value);
    };

    return (
        <div style={{padding: '1em'}}>
            <TextField
                style={{width: '100%'}}
                className={'search-field'}
                label="значение"
                variant="outlined"
                defaultValue={search}
                size={'small'}
                onChange={onSearch}
            />
        </div>
    );
};

export {NodeFilterString};
