import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select, TextField
} from '@mui/material';
import {useForm} from 'react-hook-form';
import {useAppDispatch, useAppSelector} from '../../../../hook/store';
import {ApiNodeType} from '../../../../interface';
import {LoadingButton} from '@mui/lab';
import {useState} from 'react';
import {createNodeItem} from '../../../../store/slice/node-slice';
import {useNavigate} from 'react-router-dom';
import {NodeTypeState} from '../../../../store/slice/node-type-slice';
import {ROUTE_PATH} from '../../../../constants/routes';


interface NewComponentDialogProps {
    handleClose: () => void;
    typeId?: string;
}


const NewComponentDialog = ({handleClose, typeId}: NewComponentDialogProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {allNodeTypes} = useAppSelector<NodeTypeState>(store => store.nodeType);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
    } = useForm({
        mode: 'all',
    });


    const onSubmit = async (data: any) => {
        setInProgress(true);
        await dispatch(createNodeItem(data)).unwrap()
            .then(
                (res) => {
                    navigate(`/${ROUTE_PATH.newComponents}/${data.typeId}/${res.id}`);
                }
            );
    };


    return <>
        <Dialog
            className="new-item-dialog"
            open={true}
            maxWidth={'lg'}
            onClose={handleClose}
        >
            <DialogTitle className="dialog-title">Новый элемент</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <FormGroup className={errors?.typeId ? 'form-field form-field-error' : 'form-field'}>
                        <FormControl error={!!errors?.typeId} size={'small'}>
                            <InputLabel id="type-select">Тип</InputLabel>
                            <Select
                                defaultValue={!!typeId ? typeId : ''}
                                labelId="type-select"
                                id="type-select"
                                label="Тип"
                                variant="outlined"
                                disabled={!!typeId}
                                {...register('typeId', {
                                    required: 'Обязательное поле',
                                    ...typeId && {'value': typeId}
                                })}
                            >
                                {!!allNodeTypes && allNodeTypes.map((type: ApiNodeType) => {
                                    return <MenuItem key={type.id} value={type.id}>{type.typeName}</MenuItem>;
                                })}
                            </Select>
                            <FormHelperText>{errors?.typeId ? errors.typeId.message as string : ''}</FormHelperText>
                        </FormControl>
                    </FormGroup>
                    <FormGroup className={errors?.name ? 'form-field form-field-error' : 'form-field'}>
                        <FormControl>
                            <TextField
                                label="Название"
                                multiline
                                rows={3}
                                variant="outlined"
                                error={!!errors?.name}
                                helperText={errors?.name ? errors?.name.message as string : ''}
                                {...register('name', {
                                    required: 'Обязательное поле',
                                    minLength: {value: 3, message: 'Название должно содержать минимум 3 символов'},
                                    maxLength: {value: 100, message: 'Название должно содержать максимум 100 символов'}
                                })}
                            />
                        </FormControl>
                    </FormGroup>

                </DialogContent>
                <DialogActions className="form-buttons">
                    <Button
                        disabled={inProgress}
                        variant={'outlined'}
                        onClick={handleClose}
                    >
                        Отмена
                    </Button>
                    <LoadingButton
                        loading={inProgress}
                        type={'submit'}
                        variant={'contained'}
                        disabled={!isValid || inProgress}
                    >
                        Добавить
                    </LoadingButton>
                </DialogActions>
            </form>

        </Dialog>

    </>;
};

export {NewComponentDialog};
