import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ApiRole} from '../../interface';
import baseRequest from '../../utils/base-request';

export interface RoleState{
    roleList: ApiRole[] | null;
}


export const getRoles = createAsyncThunk(
    'role/getRoles',
    async function (_, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: '/api/role'
        }, dispatch);
        return response.data;
    }
);



const roleSlice = createSlice({
    name: 'role',
    initialState: {
        roleList: null
    },
    reducers: {},
    extraReducers: {
        [getRoles.fulfilled as any]: (state: RoleState, action: PayloadAction<ApiRole[]>) => {
            state.roleList = action.payload;
        },
    }
});

export default roleSlice.reducer;
