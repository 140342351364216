import {FC, useMemo} from "react";
import {
    Breadcrumbs,
    Container, HeaderContainer,
    Logo, MainNavContainer,
    MainNavigation,
    NavigationContainer,
    SecondaryNavigation,
} from "./header.styles";
import {ReactComponent as LogoIcon} from "../../assets/img/logoDarkBg.svg";
import {HeaderPrimaryTab} from "../header-primary-tab";
import {Tab} from "../tab";
import {Tabs} from "../tabs";
import {SectionViewData} from "./header.types";
import {AppBreadcrumbs} from "../app-breadcrumbs/app-breadcrumbs";

export interface HeaderViewProps {
    sections: SectionViewData[];
    subSections: SectionViewData[];
    onSectionChange: (id: string) => void;
    onSubSectionChange: (id: string) => void;
}

export const HeaderView: FC<HeaderViewProps> = ({
                                                    sections,
                                                    subSections,
                                                    onSectionChange,
                                                    onSubSectionChange,
                                                }) => {
    const subSectionsTabsValue = useMemo(() => {
        console.log(subSections);
        return subSections.findIndex((subSection) => subSection.isActive);
    }, [subSections]);

    return (
        <Container>
            <NavigationContainer>
                <MainNavContainer>
                    <HeaderContainer>
                        <MainNavigation>

                            <Logo>
                                <LogoIcon/>
                            </Logo>
                            {sections.map((section, index) => (
                                <HeaderPrimaryTab
                                    onClick={() => onSectionChange(section.id)}
                                    isActive={section.isActive}
                                    key={index}
                                >
                                    {section.text}
                                </HeaderPrimaryTab>
                            ))}

                        </MainNavigation>
                    </HeaderContainer>
                </MainNavContainer>
                <Breadcrumbs>
                    <HeaderContainer>
                        <AppBreadcrumbs/>
                    </HeaderContainer>
                </Breadcrumbs>
                <HeaderContainer>
                    <SecondaryNavigation>

                        <Tabs
                            sx={{marginLeft: "16px", marginTop: "20px"}}
                            value={subSectionsTabsValue}
                        >
                            {subSections.map((subSection, index) => (
                                <Tab
                                    label={subSection.text}
                                    onClick={() => onSubSectionChange(subSection.id)}
                                    key={index}
                                />
                            ))}
                        </Tabs>
                    </SecondaryNavigation>
                </HeaderContainer>
            </NavigationContainer>
        </Container>
    );
};
