import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import baseRequest from '../../utils/base-request';
import {ApiDictionaryType, ApiDictionaryTypeResult, ApiDictionaryTypeSummary, SearchParams} from '../../interface';


export interface DictionaryTypeState {
    allDictionaryTypes: ApiDictionaryType[] | null;
    dictionaryTypes: ApiDictionaryType[] | null;
    summary: ApiDictionaryTypeSummary | null;
    currentType: ApiDictionaryType | null;
    dictTotal: number;
}


export const getAllDictionaryTypes = createAsyncThunk(
    'dictionaryType/getAllDictionaryTypes',
    async function (_, {dispatch}) {

        const response = await baseRequest({
            method: 'get',
            url: '/api/dictionaryType',
        }, dispatch);
        return response.data;
    }
);

export const getDictionaryTypes = createAsyncThunk(
    'dictionaryType/getDictionaryTypes',
    async function (params: SearchParams, {dispatch}) {

        const response = await baseRequest({
            method: 'get',
            url: '/api/dictionaryType2',
            params
        }, dispatch);
        return response.data;
    }
);

export const getDictionaryTypesSummary = createAsyncThunk(
    'dictionaryType/getDictionaryTypesSummary',
    async function (_, {dispatch}) {

        const response = await baseRequest({
            method: 'get',
            url: '/api/dictionaryType/summary',
        }, dispatch);
        return response.data;
    }
);

export const getDictionaryType = createAsyncThunk(
    'dictionaryType/getDictionaryType',
    async function (id: string, {dispatch}) {

        const response = await baseRequest({
            method: 'get',
            url: `/api/dictionaryType/${id}`,
        }, dispatch);
        return response.data;
    }
);

const initialState: DictionaryTypeState = {
    allDictionaryTypes: null,
    dictionaryTypes: null,
    summary: null,
    currentType: null,
    dictTotal: 0
};

const dictionaryTypeSlice = createSlice({
    name: 'dictionaryType',
    initialState,
    reducers: {
        resetDictionaryTypeState(){
            return initialState;
        }
    },
    extraReducers: {
        [getDictionaryTypes.fulfilled as any]: (state: DictionaryTypeState, action: PayloadAction<ApiDictionaryTypeResult>) => {
            state.dictionaryTypes = action.payload.result;
            state.dictTotal = action.payload.total;
        },
        [getAllDictionaryTypes.fulfilled as any]: (state: DictionaryTypeState, action: PayloadAction<ApiDictionaryTypeResult>) => {
            state.allDictionaryTypes = action.payload.result;
        },
        [getDictionaryTypesSummary.fulfilled as any]: (state: DictionaryTypeState, action: PayloadAction<ApiDictionaryTypeSummary>) => {
            state.summary = action.payload;
        },
        [getDictionaryType.fulfilled as any]: (state: DictionaryTypeState, action: PayloadAction<ApiDictionaryType>) => {
            state.currentType = action.payload;
        },
    }
});

export const {resetDictionaryTypeState} = dictionaryTypeSlice.actions;
export default dictionaryTypeSlice.reducer;
