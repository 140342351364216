export enum ROUTE_PATH {
    components = 'components1',
    newComponents = 'components',
    specifications = 'specifications',
    dictionary = 'dictionary',
    products = 'products',
    users = 'users',
    configurations = 'configurations',
    settings = 'settings',
    login = 'login'
}
