import {SearchParams, SearchParamsWithArchive} from '../interface';
import {SortDescriptor} from '@progress/kendo-data-query';
import ObjectHelper from './object.helper';


export const setSearchParamsTerm = (searchTerm: string, filterParams: SearchParams): SearchParams => {
    let next: SearchParams;
    if (!!searchTerm) {
        next = {
            ...filterParams,
            searchQuery: searchTerm
        };
    } else {
        next = ObjectHelper.unsetParams({...filterParams}, ['searchQuery']);
    }
    return next;
};


export const setArchivedParam = (value: boolean, filterParams: SearchParamsWithArchive): SearchParamsWithArchive => {
    let params: SearchParamsWithArchive;
    if(value){
        params = {
            ...filterParams,
            showArchive: value
        };
    }else{
        params = ObjectHelper.unsetParams({...filterParams}, ['showArchive']);
    }
    return params;
};

export const setSortParam = (data: SortDescriptor[],  params: SearchParams): SearchParams => {
    let next: SearchParams;
    if(data.length){
        next = {
            ...params,
            sortBy: data[0]?.field,
            sortDirection: data[0].dir
        };
    }else{
        next = ObjectHelper.unsetParams({...params}, ['sortBy', 'sortDirection']) as SearchParams;
    }
    return next;
};
