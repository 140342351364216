import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './store';
import {AppAlert} from './components/app-alert/app-alert';
import {AppToast} from './components/app-toast/app-toast';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <AppAlert/>
                <AppToast/>
                <App/>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);
