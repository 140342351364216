export interface ApiDictionaryTypeResult{
    result: ApiDictionaryType[];
    total: number;
}

export interface ApiDictionaryType {
    id: string;
    code: string;
    version: number;
    typeName: string;
    typeSchema: ApiDictionaryTypeSchema;
    modelSchema: any;
    uiShowListColumns: string[];
}

export interface ApiDictionaryTypeSchema {
    type: string;
    properties: ApiDictionaryTypeSchemaProperties;
    required: string[];
}

export interface ApiDictionaryTypeSchemaProperties {
    [key: string]: ApiDictionaryTypeSchemaProperty;
}

export interface ApiDictionaryTypeSchemaProperty {
    title: string;
    type: string;
}

export interface ApiDictionaryTypeSummary {
    dictionaryTypes: ApiDictionaryTypeSummaryTypes;
}

export interface ApiDictionaryTypeSummaryTypes {
    [key: string]: ApiDictionaryTypeSummaryType;
}

export interface ApiDictionaryTypeSummaryType {
    count: number;
}

export interface DictionaryTypePageData {
    id: string;
    name: string;
    version: number;
    itemCount: number;
}


export enum DictionaryTypeAction {
    manage = 'Управление',
    edit = 'Редактировать',
    delete = 'Удалить',
}
