import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../../hook/store';
import {
    getSpecification,
    setSpecificationFormData,
    SpecificationState,
    updateSpecificationForms
} from '../../../../../store/slice/spec-slice';
import {useParams} from 'react-router-dom';
import {AppSuspense} from '../../../../../components/app-suspense/app-suspense';
import {SpecificationTabWrapper} from '../tab-header/specification-tab-wrapper';
import {JsonForms} from '@jsonforms/react';
import {materialCells, materialRenderers} from '@jsonforms/material-renderers';
import {
    SpecFormsRequest,
    SpecificationItemInterface,
    SpecificationItemTab,
    SpecificationItemTabForm,
    SpecificationItemTabFormData,
    SpecItemStatus,
    SpecItemValidationItemType
} from '../../../../../interface';
import AsyncSelectControl, {
    asyncSelectTester
} from '../../../../../ui/json-form-renderers/async-select-renderer/async-select-control';
import FormulaInputRenderer, {
    formulaInputTester
} from '../../../../../ui/json-form-renderers/formula-input-renderer/formula-input-renderer';
import {SpecificationItemTabs} from '../../components/tabs/specification-item-tabs';
import {Card, CardBody} from '@progress/kendo-react-layout';
import '../../specification-item.scss';
import {SpecificationStatus, SpecificationStatusTitle} from './specification-data-input.interface';
import {LoadingButton} from '@mui/lab';
import {SpecificationItemPrice} from '../../components/price/specification-item-price';
import {StatusMessage} from '../../../../../components/status-message/status-message';
import {ScrollToTop} from '../../../../../ui/scroll-to-top/scroll-to-top';
import {getLastCurrencyRate} from '../../../../../store/slice/settings-slice';
import Ajv from 'ajv';
import {showError} from '../../../../../store/slice/toast-slice';

const ajv = new Ajv({
    allErrors: true,
    verbose: true,
    strictSchema: 'log',
    strict: false,
});

const SpecificationDataInput = () => {
    const urlParams = useParams();
    const dispatch = useAppDispatch();
    const {currentSpec, specificationFormData} = useAppSelector<SpecificationState>(store => store.spec);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formsError, setFormsError] = useState<boolean>(false);
    const [formDataError, setFormDataError] = useState<boolean>(false);
    const [errorForms, setErrorForms] = useState<{ [key: string]: boolean }>({});
    const [tabForms, setTabForms] = useState<SpecificationItemTabFormData>({});
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [sticky, setSticky] = useState<boolean>(false);
    const [status, setStatus] = useState<SpecificationStatus>(SpecificationStatus.empty);
    const [specificationItem, setSpecificationItem] = useState<SpecificationItemInterface>({
        tabs: []
    });

    const tabsRef = useRef<HTMLDivElement | null>(null);

    const renderers = [...materialRenderers,
        {tester: asyncSelectTester, renderer: AsyncSelectControl},
        {tester: formulaInputTester, renderer: FormulaInputRenderer},
    ];

    const buildTabs = (): void => {

        if (currentSpec?.id && specificationFormData?.id === currentSpec.id && !isLoading) {
            setSpecificationItem(specificationFormData.data);
        } else {
            const tabs: SpecificationItemTab[] = [];
            if (!currentSpec?.meta?.formTabs || !currentSpec.doc.forms) {
                setFormsError(true);
                setIsReady(true);
                setSpecificationItem({tabs});
                return;
            }
            for (const item of currentSpec.meta.formTabs) {
                const tab: SpecificationItemTab = {} as SpecificationItemTab;
                tab.title = item.name;
                tab.type = 'form';
                tab.forms = [];
                tab.required = false;
                for (const form of item.forms) {
                    const schema = validateSchema(currentSpec.meta.forms[form].schema, form)
                    if (currentSpec.doc?.forms[form]?.visible) {
                        tab.forms.push({
                            key: form,
                            schema: schema,
                            ...currentSpec.meta.forms[form].uiSchema && {'uiSchema': currentSpec.meta.forms[form].uiSchema},
                            data: currentSpec.doc.forms[form].data || {}
                        });
                    }
                    if (Array.isArray(schema?.required) && schema?.required.length) {
                        tab.required = true;
                    }
                }
                if (!!tab.forms.length) {
                    tabs.push(tab);
                }
            }
            setSpecificationItem({tabs});
        }
    };

    const validateSchema = (schema: any, key: string) => {
        try {
            ajv.compile(schema);
            return schema;
        } catch (e: any) {
            console.log(e);
            dispatch(showError(`Form '${key}': ${e.message} `));
            return {};
        }
    };

    const handleFormChange = (key: string, formData: any): void => {
        if (!key) {
            return;
        }

        updateSpecItem(key, formData);

        if (!!formData.errors.length) {
            setErrorForms({...errorForms, [key]: true});
            return;
        } else {
            setErrorForms({...errorForms, [key]: false});
        }
        const next = {
            ...tabForms,
            [key]: {...formData.data}
        };
        setTabForms(next);
    };

    const updateSpecItem = (key: string, formData: any): void => {
        // const errors = Array.isArray(formData.errors) && !!formData.errors.length;
        const currentTabForms = specificationItem?.tabs[selectedTab].forms;
        if (currentTabForms && Array.isArray(currentTabForms)) {
            const form = currentTabForms.find(f => f.key === key);
            if (form) {
                const nextForms: SpecificationItemTabForm[] = currentTabForms.reduce((acc: SpecificationItemTabForm[], curr: SpecificationItemTabForm, i: number) => {
                    const next = {...curr}
                    if (next.key === key) {
                        next.data = formData.data;
                    }
                    acc[i] = next;
                    return acc;
                }, []);
                const nextSpecTabs = specificationItem.tabs.reduce((acc: SpecificationItemTab[], curr: SpecificationItemTab, index: number) => {
                    const next = {...curr}
                    if (index === selectedTab) {
                        next.forms = nextForms;
                        // next.error = errors;
                        // next.filled = !errors;
                    }
                    acc[index] = next;
                    return acc;
                }, []);
                setSpecificationItem({tabs: nextSpecTabs});

                if (currentSpec?.id) {
                    dispatch(setSpecificationFormData({id: currentSpec.id, data: {tabs: nextSpecTabs}}));
                }

            }

        }
    }


    const hasForms = (): boolean => {
        return !!specificationItem.tabs[selectedTab]?.forms?.length;
    };

    const isReadOnly = (): boolean => {
        return currentSpec?.status !== SpecItemStatus.draft;
    };

    const handleSelectTab = (tab: number): void => {
        checkTabFormsRequiredFields(specificationItem.tabs[selectedTab], selectedTab);
        setSelectedTab(tab);
    };

    const checkRequiredFieldsFilled = (form: SpecificationItemTabForm): boolean => {
        if (form) {
            if (Array.isArray(form.schema?.required) && form.schema.required.length) {
                if (!form.data) {
                    return false;
                } else {
                    for (const field of form.schema.required) {
                        if (!form.data[field]) {
                            return false;
                        }
                    }
                }

            }
        }
        return true;
    };

    const checkTabFormsRequiredFields = (tab: SpecificationItemTab, index: number) => {
        let filled = true;
        if (tab.forms) {
            for (const form of tab.forms) {
                filled = filled && checkRequiredFieldsFilled(form);
            }
        }
        const nextSpecTabs = specificationItem.tabs.reduce((acc: SpecificationItemTab[], curr: SpecificationItemTab, index: number) => {
            const next = {...curr}
            if (index === selectedTab) {
                next.error = !filled;
                next.filled = filled;
            }
            acc[index] = next;
            return acc;
        }, []);
        setSpecificationItem({tabs: nextSpecTabs});

        // specificationItem.tabs[index].error = !filled;
    };

    const handleScroll = () => {
        if (tabsRef) {
            setSticky(tabsRef?.current?.getBoundingClientRect()?.top === 0);
        }
    };


    const updateStatus = (): void => {
        if (Array.isArray(currentSpec?.doc.validation.messages)) {
            const errors = currentSpec?.doc.validation.messages.filter((m) => m.type === SpecItemValidationItemType.error);
            if (Array.isArray(errors) && errors.length) {
                setStatus(SpecificationStatus.error);
                return;
            }
        }
        if (!!currentSpec?.doc?.invoice?.isEmpty) {
            setStatus(SpecificationStatus.empty);
            return;
        }
        setStatus(SpecificationStatus.calculated);

    };

    const isError = () => {
        return status === SpecificationStatus.error;
    };

    const isEmpty = () => {
        return status === SpecificationStatus.empty;
    };

    const isOk = () => {
        return status === SpecificationStatus.calculated;
    };

    const renderHelperText = (): ReactElement => {
        if (isEmpty()) {
            return <div className="info__status__helper empty">Добавьте все обязательные параметры (отмечены
                звездочкой
                *) для
                расчета</div>;
        }
        if (isError()) {
            const errors = currentSpec?.doc.validation.messages.filter((m) => m.type === SpecItemValidationItemType.error);
            if (Array.isArray(errors) && errors.length) {
                return <div className="info__status__helper error">
                    <ul>
                        {errors.map((e, i) => (
                            <li key={i}>{e.text}</li>
                        ))}
                    </ul>
                </div>;
            }
        }
        return <SpecificationItemPrice/>;
    };

    const handleRecalculate = (): void => {
        if (!currentSpec) {
            return;
        }
        setIsLoading(true);
        const data: SpecFormsRequest = {
            id: currentSpec.id,
            forms: tabForms
        };
        dispatch(updateSpecificationForms(data)).unwrap().then(() => {
            if (urlParams.id) {
                dispatch(getSpecification(urlParams.id)).unwrap()
                    .then(() => setIsLoading(false))
                    .catch(() => setIsLoading(false));
            }
        }).catch(() => setIsLoading(false));
    };

    const renderMessages = (): ReactElement | ReactElement[] => {
        const messages = currentSpec?.doc?.validation?.messages;
        if (Array.isArray(messages)) {
            const notError = messages.filter((m) => m.type !== SpecItemValidationItemType.error);
            if (Array.isArray(notError) && notError.length) {
                return notError.map(message => {
                    return <StatusMessage key={message.id} {...message}/>;
                });
            }
        }
        return <></>;
    };

    useEffect(() => {
        if (urlParams?.id && !!currentSpec?.id && urlParams.id === currentSpec?.id) {
            buildTabs();
            updateStatus();
            setIsReady(true);
        }
    }, [currentSpec]);


    useEffect(() => {
        dispatch(getLastCurrencyRate())
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        setFormDataError(!!Object.values(errorForms).find(v => v));
    }, [errorForms, currentSpec]);

    return (
        <SpecificationTabWrapper>
            {!!currentSpec && (

                <AppSuspense condition={isReady}>
                    {!formsError ? (
                        <div className="target">
                            <div className="forms">
                                <Card style={{overflow: 'unset'}}>
                                    <CardBody>
                                        <div className={`tabs ${sticky ? 'sticky' : ''}`} ref={tabsRef}>
                                            <SpecificationItemTabs tabs={specificationItem.tabs}
                                                                   selected={selectedTab}
                                                                   onSelect={handleSelectTab}
                                            />
                                        </div>

                                        {
                                            hasForms()
                                                ? specificationItem.tabs[selectedTab].forms?.map((form, index) => (
                                                    <div className="form-wrapper" key={index}>
                                                        <JsonForms
                                                            readonly={isReadOnly()}
                                                            key={form.key}
                                                            schema={form.schema}
                                                            data={form.data}
                                                            uischema={form.uiSchema}
                                                            renderers={renderers}
                                                            cells={materialCells}
                                                            validationMode={'ValidateAndShow'}
                                                            onChange={(data) => handleFormChange(form.key, data)}
                                                        />
                                                    </div>

                                                ))
                                                :
                                                <div className={'empty-result'}>параметры спецификации
                                                    недоступны</div>
                                        }

                                    </CardBody>
                                </Card>
                            </div>
                            <div className={'info'}>
                                <div className={'info__status'}>
                                    <div className={`title ${status}`}>
                                        {SpecificationStatusTitle[status]}
                                    </div>
                                    {renderHelperText()}
                                    <div className="info__status__recalculate">
                                        <LoadingButton className=""
                                                       variant={'contained'}
                                                       disableElevation={true}
                                                       onClick={handleRecalculate}
                                                       loading={isLoading}
                                                       disabled={formDataError || isLoading || currentSpec.status !== SpecItemStatus.draft}>
                                            Пересчитать
                                        </LoadingButton>
                                    </div>

                                </div>

                                {renderMessages()}

                            </div>

                        </div>
                    ) : (
                        <Card style={{marginBottom: '1rem', padding: '1rem'}}>
                            <CardBody>
                                <div style={{textAlign: 'center'}}>Неверный формат спецификации.</div>
                            </CardBody>
                        </Card>
                    )}
                </AppSuspense>
            )
            }
            <ScrollToTop/>
        </SpecificationTabWrapper>
    );
};

export {SpecificationDataInput};
