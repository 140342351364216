import {createSlice} from '@reduxjs/toolkit';
import {ToastInterface, ToastTypeInterface} from '../../interface';

interface ToastSliceInitialState {
    toasts: ToastInterface[];
}

const initialState: ToastSliceInitialState = {
    toasts: []
};


const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        showSuccess(state, action) {
            state.toasts.push({
                type: ToastTypeInterface.success,
                message: action.payload,
                id: (new Date()).getTime()
            });
        },
        showError(state, action) {
            state.toasts.push({
                type: ToastTypeInterface.error,
                message: action.payload,
                id: (new Date()).getTime()
            });
        },
        showWarning(state, action) {
            state.toasts.push({
                type: ToastTypeInterface.warning,
                message: action.payload || 'Ошибка сервера',
                id: (new Date()).getTime()
            });
        },
        showInfo(state, action) {
            state.toasts.push({
                type: ToastTypeInterface.info,
                message: action.payload,
                id: (new Date()).getTime()
            });
        },
        removeToast(state, action) {
            state.toasts = state.toasts.filter(t => t.id !== action.payload);
        },
        clearAll(state) {
            state = initialState;
        }
    }
});

export const {showSuccess, showWarning, showError, showInfo, removeToast, clearAll} = toastSlice.actions;

export default toastSlice.reducer;
