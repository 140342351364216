import {TreeListCellProps} from '@progress/kendo-react-treelist';
import {useEffect, useState} from 'react';
import {ReactComponent as InfoIcon} from '../../../../../assets/img/icon/info.svg';
import {ApiNodeTypeSchemaProperty} from '../../../../../interface';
import {Popover} from '@mui/material';
import './calculation-custom-cell.scss';

interface DataItemProps {
    name: string;
    value: string | boolean;
}

const CalculationCustomCell = (props: TreeListCellProps) => {
    const {dataItem, hasChildren, level, expanded, onExpandChange} = props;
    const [cellData, setCellData] = useState<DataItemProps[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const collectProps = () => {
        const items: DataItemProps[] = [];
        const typeData = dataItem?.type?.dataSchema?.properties;
        const modelSchema = dataItem?.type?.modelSchema?.properties;
        if (typeData) {
            for (const [key, value] of Object.entries(typeData)) {
                items.push({
                    name: (value as ApiNodeTypeSchemaProperty).title || '-',
                    value: dataItem?.item?.node ? dataItem?.item?.node[key] : '-'
                });
            }
        }
        if (modelSchema) {
            for (const [key, value] of Object.entries(modelSchema)) {
                items.push({
                    name: (value as ApiNodeTypeSchemaProperty).title || '-',
                    value: dataItem?.item?.node ? dataItem.item.node[key] || '-' : '-'
                });
            }
        }

        setCellData(items);
    };

    useEffect(() => {
        collectProps();
    }, []);

    return <>
        <td className="info-cell">
            {hasChildren &&
                <div className={`k-icon ${expanded ? 'k-i-arrow-chevron-down' : 'k-i-arrow-chevron-up'}`}
                     onClick={(e) => onExpandChange(e, dataItem, level)}
                ></div>
            }
            <div>
                <div className="info-cell__wrap">
                    <span style={{paddingLeft: (level.length-1) * 30}}>{String(dataItem[props.field || ''])}</span>
                    <div className="info-cell__icon">
                        <div className="info" onClick={handleClick}>
                            <InfoIcon className={'icon'}/>
                        </div>
                    </div>
                </div>
            </div>


            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className={'info-cell-popover-content'}>
                    <div className="title">{dataItem.componentName}</div>
                    <div className="wrapper">
                        <table>
                            <tbody>
                            {cellData.map((item, i) => (
                                <tr key={i}>
                                    <td width={'50%'}>{item.name}</td>
                                    <td width={'50%'}>
                                        {item.value === true ? (
                                            <i className="pi pi-check"></i>
                                        ) : (
                                            item.value || '-'
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Popover>

        </td>

    </>;
};

export {CalculationCustomCell};
