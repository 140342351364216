import React from 'react';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {StatusBadge} from '../../../../components/status-badge/status-badge';
import {ApiNodeItem} from '../../../../interface';
import {ComponentTypeActions} from '../../components/component-type-actions/component-actions';
import './component-item-header.scss';

interface ComponentItemHeaderProps {
    item: ApiNodeItem | null
}


const ComponentItemHeader = ({item}: ComponentItemHeaderProps) => {

    return <>
        <Card orientation={'horizontal'}>

            <CardBody className={'component-item-header'}>
                <StatusBadge status={item?.status}/>
                <div className="title" title={item?.name || ''}>
                    {item?.name || ''}
                </div>
                <div className="spacer"></div>
                <div className="actions">
                    {item && (
                        <ComponentTypeActions nodeItem={item} hideEdit={true}/>
                    )}
                </div>
            </CardBody>
        </Card>
    </>;
};

export {ComponentItemHeader};
