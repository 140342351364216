export const colors = {
  primary500: "#0c2258",
  primary400: "#2f69fb",
  primary300: "#6893ff",
  primary200: "#d7e7ff",
  primary100: "#f7f9fd",

  gray600: "#657293",
  gray550: "#9E9E9E",
  gray500: "#9B9CA1",
  gray400: "#ADB3C3",
  gray300: "#D8DADD",
  gray200: "rgba(17, 18, 18, 0.06)",

  black: '#121826',
  white: "#fefeff",


  headerWhite: '#FFFFFF',
  headerBlack: '#0D111C',
  borderColor: '#EDEEEE',
};
