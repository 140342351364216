import {GridHeaderCellProps} from '@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps';
import styles from '../filters.module.scss';
import {ReactComponent as IconFilterOutlined} from '../../../../../assets/img/icon/filter-outlined.svg';
import React from 'react';

interface FilterHeaderProps extends GridHeaderCellProps {
    handleClick: (event: React.MouseEvent<any>) => any;
}

const FilterHeader = (props: FilterHeaderProps) => {

    const titleStyle = {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    };

    return (
        <>
            <div title={props.title?.toString()}
                 style={titleStyle}>
                {props.title}
            </div>
            {props.children}
            <div onClick={props.handleClick}
                 className={styles.filter}>
                <IconFilterOutlined/>
            </div>
        </>
    );
};

export {FilterHeader};
