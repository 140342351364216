import {ReactComponent as DownloadIcon} from '../../../../../../assets/img/icon/download.svg';
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Popover,
} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {SpecificationItemTab, SpecItem, SpecMultipleDownloadSection} from '../../../../../../interface';
import './documents-multiple-download.scss';

interface DocumentsMultipleDownloadProps {
    tabs: SpecificationItemTab[];
    currentSpec: SpecItem;
    currentTab?: string;
}

const DocumentsMultipleDownload = ({currentSpec, tabs, currentTab}: DocumentsMultipleDownloadProps) => {

        const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
        const [selectedReports, setSelectedReports] = useState<{ [key: string]: boolean }>({});
        const selectedSections = useRef<SpecMultipleDownloadSection[] | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            selectedSections.current = [];
            setAnchorEl(null);
        };

        useEffect(() => {
                const data = tabs.reduce((acc, cur) => {
                    acc[cur.id] = cur.id === currentTab;
                    return acc;
                }, {} as { [key: string]: boolean });
                setSelectedReports(data);
            }, [currentTab]
        );

        const getSelectedSections = () => {
            if (!!selectedReports && !Object.values(selectedReports).filter(v => !!v).length) {
                selectedSections.current = [];
                return;
            }
            const sections: SpecMultipleDownloadSection[] = [];
            for (const option in selectedReports) {
                if (selectedReports[option]) {
                    const tab = tabs.find(t => t.id === option);
                    if (tab) {
                        sections.push({
                            schema: false,
                            section: option,
                            sectionName: tab.title
                        });
                    }
                }
            }

            selectedSections.current = [...sections];
            return sections;
        };

        const handleCheckboxChange = (id: string, e: any) => {
            setSelectedReports({
                ...selectedReports,
                [id]: e.target.checked
            });
        };

        const hasItems = (): boolean => {
            return !!Object.values(selectedReports).filter(v => v).length;
        };

        const getLink = (): string => {
            const sections = getSelectedSections()

            let sectionsParam = '';
            if(sections){
                sectionsParam = sections.map((s) => `${s.section},${s.sectionName},${s.schema}`).join(';');
            }
            return `api/spec/${currentSpec.id}/command/multipleExport/excel?sections=${sectionsParam}`;
        };

        return (
            <>
                <Button variant="outlined"
                        startIcon={<DownloadIcon/>}
                        onClick={handleClick}
                >
                    Скачать...
                </Button>

                {anchorEl && (
                    <Popover
                        className="multiple-download-dialog"
                        id={'download-popover'}
                        open={true}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >

                        <div className="dialog-title">
                            Выбрать для скачивания:
                        </div>
                        <div className="dialog-content">
                            <FormGroup>
                                {tabs.map((t) => (
                                    <FormControlLabel key={t.id}
                                                      control={<Checkbox size={'small'}
                                                                         defaultChecked={t.id === currentTab}/>}
                                                      label={t.title}
                                                      onChange={(e) => handleCheckboxChange(t.id, e)}
                                    />
                                ))}

                            </FormGroup>

                        </div>
                        <div className="dialog-actions">
                            {hasItems() ? (
                                <a href={getLink()} download>
                                    <Button
                                        variant={'contained'}
                                    >
                                        Скачать
                                    </Button>
                                </a>
                            ) : (
                                <Button
                                    variant={'contained'}
                                    disabled={true}
                                >
                                    Скачать
                                </Button>
                            )}

                        </div>
                    </Popover>
                )}
            </>
        );
    }
;

export {DocumentsMultipleDownload};
