import React from 'react';

const locale = 'ru';

export const createTranslator = (locale: string) => (key: string, defaultMessage: string | undefined): any => {
    if(key.includes('error.required')) {
        return 'Обязательное поле';
    }
    return defaultMessage;
};

export const translation = createTranslator(locale);
