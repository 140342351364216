import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ApiTypeUpdate, SpecificationType, SpecificationTypeItem, SearchParams} from '../../interface';
import baseRequest from '../../utils/base-request';

export interface SpecTypeState {
    allSpecTypes: SpecificationType | null;
    specTypes: SpecificationType | null;
    currentSpecTypeHelp: string;
    currentType: SpecificationTypeItem | null;
    total: number;
}

const initialState: SpecTypeState = {
    allSpecTypes: null,
    specTypes: null,
    currentSpecTypeHelp: '',
    currentType: null,
    total: 0,
};

export const getSpecTypeHelp = createAsyncThunk(
    'specType/getSpecTypeHelp',
    async function (id: string, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: `/api/specType/${id}/help`
        }, dispatch);
        return response.data;
    }
);


export const getAllSpecTypes = createAsyncThunk(
    'specType/getAllSpecType',
    async function (_, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: '/api/specType'
        }, dispatch);
        return response.data;
    }
);

export const getSpecTypes = createAsyncThunk(
    'specType/getType',
    async function (params: SearchParams, {dispatch}) {

        const response = await baseRequest({
            method: 'get',
            url: '/api/specType',
            params
        }, dispatch);
        return response.data;

    }
);


export const getSpecType = createAsyncThunk(
    'specType/getSpecType',
    async function (id: string, {dispatch}) {
        if(!id){
            return;
        }
        const response = await baseRequest({
            method: 'get',
            url: `/api/specType/${id}`
        }, dispatch);
        return response.data;
    }
);


export const updateSpecType = createAsyncThunk(
    'specType/updateSpecType',
    async function ({id, data}: ApiTypeUpdate, {dispatch}) {
        if(!id){
            return;
        }
        const response = await baseRequest({
            method: 'put',
            url: `/api/specType/${id}`,
            data
        }, dispatch);
        return response.data;
    }
);

const specTypeSlice = createSlice({
    name: 'specType',
    initialState,
    reducers: {
        clearCurrentType(state: SpecTypeState) {
            state.currentType = null;
        },
        clearCurrentSpecTypeHelp(state: SpecTypeState) {
            state.currentSpecTypeHelp = '';
        }
    },
    extraReducers: {
        [getAllSpecTypes.fulfilled as any]: (state: SpecTypeState, action: PayloadAction<SpecificationType>) => {
            state.allSpecTypes = action.payload;
            state.total = action.payload.total;
        },
        [getSpecTypes.fulfilled as any]: (state: SpecTypeState, action: PayloadAction<SpecificationType>) => {
            state.specTypes = action.payload;
        },
        [getSpecTypeHelp.fulfilled as any]: (state: SpecTypeState, action: PayloadAction<string>) => {
            state.currentSpecTypeHelp = action.payload;
        },
        [getSpecType.fulfilled as any]: (state: SpecTypeState, action: PayloadAction<SpecificationTypeItem>) => {
            state.currentType = action.payload;
        }
    }
});

export const {clearCurrentType, clearCurrentSpecTypeHelp} = specTypeSlice.actions;
export default specTypeSlice.reducer;
