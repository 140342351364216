import {useAppSelector} from '../../../../../hook/store';
import {SpecificationState} from '../../../../../store/slice/spec-slice';
import React, {useEffect, useRef, useState} from 'react';
import {SpecificationTabWrapper} from '../tab-header/specification-tab-wrapper';
import {AppSuspense} from '../../../../../components/app-suspense/app-suspense';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {SpecificationItemCalculation} from '../../components/calculation/specification-item-calculation';
import {SpecificationItemTabs} from '../../components/tabs/specification-item-tabs';
import {SpecificationItemTab} from '../../../../../interface';
import {ScrollToTop} from '../../../../../ui/scroll-to-top/scroll-to-top';
import {DocumentsMultipleDownload} from './download-button/documents-multiple-download';

const SpecificationDocuments = () => {

    const {currentSpec} = useAppSelector<SpecificationState>(store => store.spec);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [sticky, setSticky] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState<string>('');
    const [tabs, setTabs] = useState<SpecificationItemTab[]>([]);

    const tabsRef = useRef<HTMLDivElement | null>(null);

    const handleScroll = () => {
        if (tabsRef) {
            setSticky(tabsRef?.current?.getBoundingClientRect()?.top === 0);
        }
    };

    const buildTabs = (): void => {
        if (!!currentSpec?.doc?.model?.sections) {
            const tabs: SpecificationItemTab[] = Object.entries(currentSpec?.doc?.model?.sections).map(([k, v]) => {
                return {
                    id: k,
                    type: 'form',
                    title: v.name
                };
            });
            setTabs(tabs);
            if (tabs.length) {
                setCurrentTab(tabs[0].id);
            }
        } else {
            setTabs([]);
        }
    };


    const handleSelectTab = (tab: number): void => {
        setSelectedTab(tab);
        setCurrentTab(tabs[tab]?.id);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    useEffect(() => {
        if (!!currentSpec?.id) {
            setIsReady(true);
            buildTabs();
        }
    }, [currentSpec]);


    return (
        <SpecificationTabWrapper>
            {!!currentSpec && (
                <AppSuspense condition={isReady}>
                    <Card style={{overflow: 'unset'}}>
                        <CardBody>
                            {!!tabs.length && (
                                <div
                                    className={`tabs k-d-flex k-d-flex-row k-justify-content-between ${sticky ? 'sticky' : ''}`}
                                    ref={tabsRef}>
                                    <SpecificationItemTabs tabs={tabs}
                                                           selected={selectedTab}
                                                           onSelect={handleSelectTab}
                                    />
                                    <DocumentsMultipleDownload
                                        currentTab={currentTab}
                                        tabs={tabs}
                                        currentSpec={currentSpec}/>

                                </div>
                            )}
                            <SpecificationItemCalculation
                                specId={currentSpec?.id}
                                model={currentSpec?.doc?.model}
                                type={currentTab}
                                isDocuments={true}/>
                        </CardBody>
                    </Card>
                </AppSuspense>
            )}
            <ScrollToTop/>
        </SpecificationTabWrapper>
    );
};

export {SpecificationDocuments};
