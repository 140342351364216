import {ApiLinkedNodeItem} from '../../../../interface';


export interface ItemComponentOptionData {
    id: string;
    name: string;
    value: {
        value: number | null;
        formula: string | null;
    };
    links: { [key: string]: ApiLinkedNodeItem };
}

export enum ItemComponentOptionActions {
    edit = 'Редактировать опцию',
    delete = 'Удалить',
    properties = 'Свойства опции'
}


export interface LinkToMove {
    moveTo: string;
    moveFrom: string;
    item: ApiLinkedNodeItem & {nodeTypes?: string[], nodeIDs?: string[]};
}
