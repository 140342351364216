import {ReactElement, ReactNode, useEffect, useState} from 'react';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {Grid, GridCellProps, GridColumn, GridDetailRowProps, GridExpandChangeEvent, GridNoRecords as NoRecords} from '@progress/kendo-react-grid';
import {format, parseISO} from 'date-fns';
import {GridHeaderCellProps} from '@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps';
import {AppSuspense} from '../../../../components/app-suspense/app-suspense';
import {AppPagination} from '../../../../components/app-pagination/app-pagination';
import {useAppSelector} from '../../../../hook/store';
import {ApiNodeTypeSchemaProperties, SearchParams} from '../../../../interface';
import {DATE_TIME} from '../../../../constants';
import ObjectHelper from '../../../../helpers/object.helper';
import {DictionaryHistoryItem, DictionaryHistory} from '../../../../interface/dictionary-item-history.interface';
import {
    DictionaryState,
} from '../../../../store/slice/dictionary-slice';
import {
    DictionaryTypeState,
} from '../../../../store/slice/dictionary-type-slice';
import {DictionaryTypeNameCell} from '../../dictionary-type/grid/dictionary-type-name-cell';
import styles from './dictionary-item-history.module.scss';

const DictionaryItemHistory = () => {
    const {currentDictionary} = useAppSelector<DictionaryState>(store => store.dictionary);
    const {allDictionaryTypes} = useAppSelector<DictionaryTypeState>(store => store.dictionaryType);
    const [data, setData] = useState<DictionaryHistoryItem[]>();
    const [allExpanded, setAllExpanded] = useState<boolean>(false);
    const [pagerParams, setPagerParams] = useState<SearchParams>({offset: '0', limit: '10'});
    const [total, setTotal] = useState<number>(0);
    const [typeSchema, setTypeSchema] = useState<ApiNodeTypeSchemaProperties>({});

    const dateCell = (props: GridCellProps): ReactElement => {
        const dataItem: any = props.dataItem;
        return <td>
            {format(parseISO(dataItem.versionDate), DATE_TIME)}
        </td>;
    };

    const authorCell = ({dataItem}: GridCellProps): ReactElement => {
        return <td>
            <DictionaryTypeNameCell dataItem={{...dataItem, name: dataItem.userName}} />
        </td>;
    };

    const expandChange = (event: GridExpandChangeEvent) => {
        if (Array.isArray(data)) {
            const newData = data.map((item: DictionaryHistoryItem) => {
                if (item.version === event.dataItem.version) {
                    item.expanded = !event.dataItem.expanded;
                }
                return item;
            });
            setData(newData);
        }
    };

    const getValue = (value: any): string | ReactElement => {
        if(value === null){
            return <span style={{fontStyle: 'italic', letterSpacing: '1px'}}>""</span>;
        }
        if(!value.toString().trim()){
            return <span style={{fontStyle: 'italic', letterSpacing: '1px'}}>""</span>;
        }
        return value.toString();
    };


    const DetailComponent = (props: GridDetailRowProps): ReactElement => {
        const dataItem: DictionaryHistoryItem = props.dataItem;
        const propTitle = (prop: string): string => {
            if(prop === 'name'){
                return 'Название';
            }
            return typeSchema[prop] ? typeSchema[prop].title : prop;
        };

        return (
            <div className={styles.historyDetails}>
                <div className={styles.message}>
                    {dataItem.diffReport.map((item, index) => (
                        <div key={index} className={styles.messageItem}>
                            <div className={styles.messageItemTitle}>
                                <div className={styles.titleWrapper}>
                                    <span className={styles.typeName}>{propTitle(item.property)}</span>
                                    <span className={styles.dotted}></span>
                                    <span className={styles.prevValue}>{getValue(item.originalValue)}</span>
                                </div>
                            </div>
                            <div className={styles.arrow}>=&gt;</div>
                            <div className={styles.newValue}>{getValue(item.newValue)}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const nextPage = (params: SearchParams) => {
        if (currentDictionary && currentDictionary.id) {
            const offset = params.offset;
            const limit = params.limit;
            const getHistory = async () => {
                const data = await fetch(`/api/dictionary/${currentDictionary.id}/history?offset=${offset}&limit=${limit}`, {
                    method: 'GET'
                });
                const jsonData: DictionaryHistory = await data.json();
                return jsonData;

            };
            getHistory().then((res) => {
                setTotal(res.total);
                setData(res.result);
            });
        }
    };

    const toggleExpandAll = () => {
        if (Array.isArray(data)) {
            setAllExpanded(!allExpanded);
            const newData = data.map((item: DictionaryHistoryItem) => {
                item.expanded = !allExpanded;
                return item;
            });
            setData(newData);
        }
    };


    const headerRender = (defaultRendering: ReactNode, props: GridHeaderCellProps): ReactNode => {
        if (props.field === 'expanded') {
            return (
                <>
                    <div className={styles.expandAll} onClick={toggleExpandAll}>
                        {allExpanded ? (
                            <span className="k-icon k-i-minus" style={{padding: '0'}}></span>
                        ) : (
                            <span className="k-icon k-i-plus" style={{padding: '0'}}></span>
                        )}

                    </div>
                </>
            );
        }
        return defaultRendering;
    };

    const handlePageChange = (params: SearchParams) => {
        setPagerParams(params);
        nextPage(params);
    };

    const hasRecords = (): boolean => {
        return (Array.isArray(data) && !!data.length);
    };


    useEffect(() => {
        if (currentDictionary && currentDictionary.id) {
            nextPage(pagerParams);
        }
    }, [currentDictionary]);

    useEffect(() => {
        if(Array.isArray(allDictionaryTypes) && currentDictionary?.type) {
            const nodeType = allDictionaryTypes.find(n => n.id === currentDictionary.type);
            const props = nodeType?.typeSchema?.properties;
            if(props && ObjectHelper.isObject(props)) {
                setTypeSchema(props);
            }
        }
    }, [allDictionaryTypes, currentDictionary]);

    return (
        <Card style={{marginBottom: '32px'}}>
            <CardBody>
                    <AppSuspense condition={!!data}>
                    {hasRecords() ? (
                        <Grid
                            style={{height: '100%', width: '100%'}}
                            data={data}
                            detail={DetailComponent}
                            scrollable={'none'}
                            sortable={false}
                            className={styles.historyGrid}
                            expandField="expanded"
                            onExpandChange={expandChange}
                            headerCellRender={headerRender}
                        >
                            <GridColumn field="versionDate" title="Дата" cell={dateCell} width={250}/>
                            <GridColumn field="userName" title="Автор" cell={authorCell} width={'auto'}/>
                            <GridColumn field="comment" title="Изменение" width={400}/>

                        <NoRecords>
                            Список пуст.
                        </NoRecords>
                        </Grid>
                    ) : (
                        <div style={{textAlign: 'center'}}> Нет изменений </div>
                    )}

                </AppSuspense>
            </CardBody>
            <AppPagination total={total} onPageChange={handlePageChange} params={pagerParams}/>
        </Card>
    );
};

export {DictionaryItemHistory as default};