import {useEffect} from 'react';
import {SettingsAbout} from './sections/settings-about';
import {SettingsCommon} from './sections/settings-common';
import {SettingsSystem} from './sections/system/settings-system';
import {ProtectedLayout} from '../../hoc/protected-layout/protected-layout';
import {useAppDispatch} from '../../hook/store';
import {cleanBreadcrumbs, setBreadcrumbs} from '../../store/slice/utils.slice';


const Settings = () => {
    const dispatch = useAppDispatch();

    useEffect(()=> {
        dispatch(setBreadcrumbs([{title: 'Настройки'}]));
        return () => {
            dispatch(cleanBreadcrumbs());
        };
    },[dispatch])

    return <>
        <SettingsCommon/>
        <SettingsAbout/>
        <ProtectedLayout>
            <SettingsSystem/>
        </ProtectedLayout>
    </>;
};

export {Settings};
