import { ThemeOptions } from "@mui/material";

export const muiTheme: ThemeOptions = {
  typography: {
    fontSize: 14,
    fontFamily: "Roboto, sans-serif",
    fontWeightRegular: 400,
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {},
      },
    },
  },
  palette: {
    primary: {
      main: "#2f69fb",
    },
    // secondary: {
    //   main: "#0c2258",
    // },
  },
};
