import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../../hook/store";
import {
    NodeState,
    updateCurrentNodeItem,
} from "../../../../store/slice/node-slice";
import {ComponentItemDialog} from "./new-component-item-dialog/component-item-dialog";
import {
    ItemComponentActions,
    ItemLinkSet,
    ItemLinkSetType,
} from "./component-item-links.interface";
import {
    ApiLinkedNodeItem,
    ApiNodeItem,
    ApiNodeItemOptions,
    NodeItemStatus,
} from "../../../../interface";
import {useNavigate} from "react-router-dom";
import {AppPrompt} from "../../../../components/app-prompt/app-prompt";
import {ComponentItemLinksGrid} from "./grid/component-item-links-grid";
import AddIcon from "@mui/icons-material/Add";
import {ROUTE_PATH} from "../../../../constants/routes";
import {MenuSelectEvent} from "@progress/kendo-react-layout/dist/npm/menu/events";
import {
    ItemComponentOptionActions,
    LinkToMove,
} from "../component-item-options/component-item-options.interface";
import {ComponentOptionActions} from "../component-item-options/component-option-actions/component-option-actions";
import {DropDownButtonItemClickEvent} from "@progress/kendo-react-buttons/dist/npm/ListButton/models/events";
import {showAlert} from "../../../../store/slice/alert-slice";
import {NodeTypeState} from "../../../../store/slice/node-type-slice";

interface ComponentItemLinksProps {
    linksSet: ApiNodeItemOptions;
    type: ItemLinkSetType;
    title: string;
    onEdit?: (id: string) => void;
}

const ComponentItemLinks = ({
                                linksSet,
                                type,
                                title,
                                onEdit,
                            }: ComponentItemLinksProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [linkedItemDialog, setLinkedItemDialog] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showOptionDelete, setShowOptionDelete] = useState(false);
    const [navigateConfirm, setNavigateConfirm] = useState(false);
    const [data, setData] = useState<any>([]);
    const [updateData, setUpdateData] = useState<ApiNodeItem | null>(null);
    const [selectedItem, setSelectedItem] = useState<ApiLinkedNodeItem | null>(
        null
    );
    const {currentNodeItem} = useAppSelector<NodeState>((store) => store.node);
    const {allNodeTypes} = useAppSelector<NodeTypeState>(
        (store) => store.nodeType
    );

    const onAction = (
        action: MenuSelectEvent,
        data: ApiLinkedNodeItem,
        optionId?: string
    ): void => {
        const ctrlKey = action.nativeEvent?.ctrlKey || action.nativeEvent?.metaKey;
        switch (action?.item?.text) {
            case ItemComponentActions.delete:
                deleteLinkedComponent(data);
                break;
            case ItemComponentActions.edit:
                setSelectedItem(data);
                setLinkedItemDialog(true);
                break;
            case ItemComponentActions.goto:
                setSelectedItem(data);
                if (isReadOnly() || ctrlKey) {
                    navigateToComponent(data, ctrlKey);
                } else {
                    setNavigateConfirm(true);
                }
                break;
            case ItemComponentActions.move:
                break;
            case ItemComponentActions.clone:
                break;
            default:
                if (!!action?.item?.text) {
                    const isMoving = action.item.data.includes("Переместить");
                    let moveTo = "Компоненты";
                    if (optionId) {
                        moveTo = optionId;
                    }
                    confirmComponentMove(
                        {
                            item: data,
                            moveFrom: isOption() ? linksSet.id : "Компоненты",
                            moveTo,
                        },
                        isMoving
                    );
                }
                break;
        }
    };

    const isLinkVariant = (data: any): boolean => {
        return Array.isArray(data) && !!data.length;
    };

    const confirmComponentMove = (
        linkToMove: LinkToMove,
        isMoving: boolean
    ): void => {
        if (isOption()) {
            moveFromOption(linkToMove, isMoving);
            return;
        }
        const isVariant = isLinkVariant(linkToMove.item.nodeTypes);

        if (linkToMove && currentNodeItem) {
            const links = {...currentNodeItem?.links};
            const options = {...currentNodeItem.options};
            const linkVariants = {...currentNodeItem.linkVariants};
            const moveToOptionLinks = {...options[linkToMove.moveTo].links};
            const moveToOptionVariants = {
                ...options[linkToMove.moveTo].linkVariants,
            };
            if (
                !!moveToOptionLinks[linkToMove.item.name] ||
                !!moveToOptionVariants[linkToMove.item.name]
            ) {
                //уже есть компонент с таким же ID
                // показываем сообщение и не даем перемещать
                dispatch(
                    showAlert({
                        message: "Компонент с таким же ID уже существует в опции",
                        header: isMoving
                            ? "Перемещение компонента"
                            : "Клонирование компонента",
                    })
                );
                return;
            }
            if (isVariant) {
                if (isMoving) {
                    delete linkVariants[linkToMove.item.name];
                }
                moveToOptionVariants[linkToMove.item.name] = linkToMove.item as any;
                options[linkToMove.moveTo] = {
                    ...options[linkToMove.moveTo],
                    linkVariants: moveToOptionVariants,
                };
            } else {
                if (isMoving) {
                    delete links[linkToMove.item.name];
                }
                moveToOptionLinks[linkToMove.item.name] = linkToMove.item;
                options[linkToMove.moveTo] = {
                    ...options[linkToMove.moveTo],
                    links: moveToOptionLinks,
                };
            }

            dispatch(
                updateCurrentNodeItem({
                    ...currentNodeItem,
                    links,
                    options,
                    linkVariants,
                })
            );
        }
    };

    const moveFromOption = (linkToMove: LinkToMove, isMoving: boolean) => {
        const isVariant = isLinkVariant(linkToMove.item.nodeTypes);

        if (linkToMove && currentNodeItem) {
            const links = {...currentNodeItem?.links};
            const options = {...currentNodeItem.options};
            const linkVariants = {...currentNodeItem.linkVariants};

            if (
                (linkToMove.moveTo !== "Компоненты" &&
                    !!currentNodeItem.options[linkToMove.moveTo].linkVariants?.[linkToMove.item.name]) ||
                (linkToMove.moveTo === "Компоненты" &&
                    !!linkVariants[linkToMove.item.name])
            ) {
                //уже есть компонент с таким же ID
                // показываем сообщение и не даем перемещать
                dispatch(
                    showAlert({
                        message: "Компонент с таким же ID уже существует",
                        header: isMoving
                            ? "Перемещение компонента"
                            : "Клонирование компонента",
                    })
                );
                return;
            }
            if (linkToMove.moveTo === "Компоненты") {
                if (isVariant) {
                    linkVariants[linkToMove.item.name] = linkToMove.item as any;
                } else {
                    if (!links[linkToMove.item.name]) {
                        links[linkToMove.item.name] = linkToMove.item;
                    } else if (!!links[linkToMove.item.name]) {
                        dispatch(
                            showAlert({
                                message: "Компонент с таким же ID уже существует",
                                header: isMoving
                                    ? "Перемещение компонента"
                                    : "Клонирование компонента",
                            })
                        );
                        return;
                    }
                }
            } else {
                const moveToOptionLinks = {...options[linkToMove.moveTo].links};
                const moveToOptionVariants = {
                    ...options[linkToMove.moveTo].linkVariants,
                };
                if (
                    !!moveToOptionLinks[linkToMove.item.name] ||
                    !!moveToOptionVariants[linkToMove.item.name]
                ) {
                    //уже есть компонент с таким же ID
                    // показываем сообщение и не даем перемещать
                    dispatch(
                        showAlert({
                            message: "Компонент с таким же ID уже существует в опции",
                            header: isMoving
                                ? "Перемещение компонента"
                                : "Клонирование компонента",
                        })
                    );
                    return;
                }
                if (isVariant) {
                    moveToOptionVariants[linkToMove.item.name] = linkToMove.item as any;
                    options[linkToMove.moveTo] = {
                        ...options[linkToMove.moveTo],
                        linkVariants: moveToOptionVariants,
                    };
                } else {
                    moveToOptionLinks[linkToMove.item.name] = linkToMove.item;
                    options[linkToMove.moveTo] = {
                        ...options[linkToMove.moveTo],
                        links: moveToOptionLinks,
                    };
                }
            }

            const option =
                currentNodeItem?.options &&
                currentNodeItem.options[linkToMove.moveFrom];
            const optionLinks = {...option?.links};
            const optionVariants = {...option?.linkVariants};
            if (isVariant && isMoving) {
                delete optionVariants[linkToMove.item.name];
            } else if (isMoving) {
                delete optionLinks[linkToMove.item.name];
            }
            options[linkToMove.moveFrom] = {
                ...option,
                links: optionLinks,
                linkVariants: optionVariants,
            };

            dispatch(
                updateCurrentNodeItem({
                    ...currentNodeItem,
                    links,
                    options,
                    linkVariants,
                })
            );
        }
    };

    const isReadOnly = (): boolean => {
        return currentNodeItem?.status !== NodeItemStatus.draft;
    };

    const deleteLinkedComponent = (data: any): void => {
        const isVariant = isLinkVariant(data.nodeTypes);

        if (currentNodeItem) {
            if (isOption()) {
                const options = {...currentNodeItem.options};
                const option = options[linksSet.id];
                if (isVariant) {
                    const linkVariants = {...option.linkVariants};
                    delete linkVariants[data.name];
                    options[linksSet.id] = {...option, linkVariants};
                } else {
                    const links = {...option.links};
                    delete links[data.name];
                    options[linksSet.id] = {...option, links};
                }
                setUpdateData({
                    ...currentNodeItem,
                    options,
                });
            } else {
                if (isVariant) {
                    const linkVariants = {...currentNodeItem.linkVariants};
                    delete linkVariants[data.name];
                    setUpdateData({
                        ...currentNodeItem,
                        linkVariants,
                    });
                } else {
                    const links = {...currentNodeItem.links};
                    delete links[data.name];
                    setUpdateData({
                        ...currentNodeItem,
                        links,
                    });
                }
            }
            setShowConfirm(true);
        }
    };

    const confirmLinkDelete = (): void => {
        if (updateData) {
            updateLinks(updateData);
        }
    };

    const handleConfirmClose = (): void => {
        setShowConfirm(false);
        setUpdateData(null);
    };

    const updateLinks = (data: ApiNodeItem) => {
        dispatch(updateCurrentNodeItem(data));
        setLinkedItemDialog(false);
        setShowConfirm(false);
        setUpdateData(null);
    };

    const createNewLink = () => {
        setSelectedItem(null);
        setLinkedItemDialog(true);
    };

    const isOption = (): boolean => {
        return type === ItemLinkSetType.option;
    };

    const onOptionAction = (action: DropDownButtonItemClickEvent) => {
        switch (action?.item?.text) {
            case ItemComponentOptionActions.edit:
            case ItemComponentOptionActions.properties:
                if (onEdit) {
                    onEdit(linksSet.id);
                }
                break;
            case ItemComponentOptionActions.delete:
                setShowOptionDelete(true);
                break;
        }
    };

    const confirmOptionDelete = () => {
        if (currentNodeItem && linksSet.id) {
            const options = {...currentNodeItem?.options};
            delete options[linksSet.id];
            dispatch(updateCurrentNodeItem({...currentNodeItem, options}));
            setShowOptionDelete(false);
        }
    };

    useEffect(() => {
        if ((linksSet?.links || linksSet?.linkVariants) && allNodeTypes) {
            const links = Object.entries(linksSet?.links || {}).map(([k, v]) => v);
            const variants = Object.entries(linksSet?.linkVariants || {}).map(
                ([k, v]) => v
            );
            const variantsPrepared = variants.map((item: any) => {
                return {
                    ...item,
                    variantName: item.name,
                    name: item.id,
                    linkedNode: {
                        type: item.nodeTypes,
                        name: `${item.name} (вариант)`,
                    },
                };
            });
            setData([...links, ...variantsPrepared]);
        }
    }, [linksSet, allNodeTypes]);

    const navigateToComponent = (
        data?: ApiLinkedNodeItem,
        openInNewTab?: boolean
    ) => {
        const type = data?.linkedNode?.type ?? selectedItem?.linkedNode?.type;
        const id = data?.linkedNode?.id ?? selectedItem?.linkedNode?.id;
        const url = `/${ROUTE_PATH.newComponents}/${type}/${id}`;
        setNavigateConfirm(false);
        if (openInNewTab) {
            window.open(url, "_blank");
        } else {
            navigate(url);
        }
    };

    return (
        <>
            <div
                className={
                    isOption() ? "component-options-header" : "linked-components-header"
                }
            >
                <div className="title">
                    {title}
                    {type === ItemLinkSetType.option && <span>опция</span>}
                </div>
                <div className="actions">
                    {!isReadOnly() && (
                        <Button variant={"outlined"} onClick={createNewLink}>
                            <AddIcon/> Добавить компонент
                        </Button>
                    )}
                    {isOption() && <ComponentOptionActions onAction={onOptionAction}/>}
                </div>
            </div>

            <div className={"component-links-grid"}>
                <ComponentItemLinksGrid
                    gridData={data}
                    readonly={isReadOnly()}
                    onAction={onAction}
                    optionId={linksSet.id}
                />
            </div>

            {linkedItemDialog && (
                <ComponentItemDialog
                    handleClose={() => setLinkedItemDialog(false)}
                    handleUpdate={updateLinks}
                    itemData={selectedItem}
                    readonly={isReadOnly()}
                    optionId={linksSet.id || undefined}
                />
            )}

            {showConfirm && (
                <AppPrompt
                    data={{
                        title: "Подтвердите действие",
                        message: ["Подтвердите удаление ссылки на компонент"],
                        confirmButton: "Удалить",
                        buttonError: true,
                    }}
                    onClose={handleConfirmClose}
                    onConfirm={confirmLinkDelete}
                />
            )}

            {showOptionDelete && (
                <AppPrompt
                    data={{
                        title: "Подтвердите действие",
                        message: ["Подтвердите удаление опции компонента"],
                        confirmButton: "Удалить",
                        buttonError: true,
                    }}
                    onClose={() => setShowOptionDelete(false)}
                    onConfirm={confirmOptionDelete}
                />
            )}

            {navigateConfirm && (
                <AppPrompt
                    data={{
                        title: "Подтвердите действие",
                        message: [
                            "Подтвердите переход на карточку компонента,",
                            "несохраненные данные могут быть потеряны",
                        ],
                        confirmButton: "Перейти",
                    }}
                    onClose={() => setNavigateConfirm(false)}
                    onConfirm={navigateToComponent}
                />
            )}
        </>
    );
};

export {ComponentItemLinks};
