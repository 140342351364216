import React from 'react';
import {NavLink} from 'react-router-dom';
import {ReactComponent as IconHome} from '../../assets/img/icon/home.svg';
import {ReactComponent as IconSlash} from '../../assets/img/icon/slash.svg';
import {useAppSelector} from '../../hook/store';
import {UtilsState} from '../../store/slice/utils.slice';

const versionStyle = {
    fontSize: '11px',
    lineHeight: '11px',
    background: '#EAEBF0',
    display: 'flex',
    padding: '4px 6px',
    borderRadius: '4px',
    marginLeft: '6px'
};

const AppBreadcrumbs = () => {

    const {breadcrumbs} = useAppSelector<UtilsState>(store => store.utils);

    return (
        <div className="breadcrumbs">
            <NavLink to={'/'}><IconHome></IconHome></NavLink>
            {!!breadcrumbs && breadcrumbs.map((item, i, {length}) => {
                if (length - 1 === i) {
                    return (
                        <React.Fragment key={i}>
                            <IconSlash/>
                            <div className="item">
                                {item?.title || '-'}
                                {item.version && (<span style={versionStyle}>{item.version}</span>)}
                            </div>
                        </React.Fragment>
                    );
                } else {
                    return (
                        <React.Fragment key={i}>
                            <IconSlash/>
                            <div className="item">
                                <NavLink
                                    to={item?.path || '/'}>{item?.title || '-'}</NavLink>
                            </div>
                        </React.Fragment>
                    );
                }
            })}
        </div>
    );
};

export {AppBreadcrumbs};
