import {FormControl, MenuItem, Pagination, Select} from '@mui/material';
import React, {ReactElement, useEffect, useState} from 'react';
import {SearchParams} from '../../interface';
import styles from './app-pagination.module.scss';

interface AppPaginationProps {
    params: SearchParams;
    total: number;
    onPageChange: (params: SearchParams) => void
    pageSizes?: number[],
    showButtons?: boolean,
    leftSideElement?: ReactElement<any>,
}

const AppPagination = ({params, total, onPageChange, showButtons, pageSizes}: AppPaginationProps) => {
    const [page, setPage] = useState<number>(1);
    const [pageTotal, setPageTotal] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(8);

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        const params: SearchParams = {
            offset: (pageSize * (page - 1)).toString(),
            limit: pageSize.toString()
        };
        onPageChange(params);
    };

    const handleSizeChange = (e: any) => {
        const params: SearchParams = {
            offset: '0',
            limit: e.target.value
        };
        onPageChange(params);
    };

    const currentRecord = (): string => {
        if (params.offset && params.limit) {
            const first = parseInt(params.offset) + 1;
            let items = parseInt(params.offset) + parseInt(params.limit);
            items = items < total ? items : total;
            return `${first}-${items} из ${total}`;
        }
        return '';
    };

    useEffect(() => {
        if (!isNaN(Number(total))) {
            const pageSize = params?.limit ? parseInt(params?.limit) : 0;

            if (Number.isInteger(pageSize)) {
                setPageSize(pageSize);

                const page = params?.offset ? Math.ceil(parseInt(params?.offset) / pageSize) + 1 : 1;
                if (Number.isInteger(page)) {
                    setPage(page);
                } else {
                    setPage(1);
                }
                const pages = Math.ceil(total / pageSize);
                if (Number.isInteger(pages)) {
                    setPageTotal(pages);
                }
            }

        }

    }, [params, total]);

    return <>
        {showButtons ? (
            <div className={styles.with_buttons_wrapper}>
                <div className={styles.current_record}>
                    {currentRecord()}
                </div>
                <div className={styles.with_page_size}>
                    <Pagination count={pageTotal}
                                className={styles.app_pagination}
                                page={page}
                                shape="rounded"
                                showFirstButton={true}
                                showLastButton={true}
                                onChange={handlePageChange}
                    />
                    {pageSizes && (
                        <FormControl sx={{margin: 0}}>
                            <Select
                                variant={'standard'}
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={pageSize}
                                onChange={handleSizeChange}
                            >
                                {pageSizes.map((s) => (
                                    <MenuItem value={s} key={s}>{s}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </div>
                <div className=""></div>
            </div>
        ) : (
            <Pagination count={pageTotal}
                        className={styles.app_pagination}
                        page={page}
                        shape="rounded"
                        hidePrevButton
                        hideNextButton
                        onChange={handlePageChange}
            />
        )}

    </>;
};

export {AppPagination};
