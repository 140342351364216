import styled from "@emotion/styled";
import { Tabs as MuiTab, TabsProps } from "@mui/material";
import { colors } from "../../themes/colors";

export const Tabs = styled(MuiTab)<TabsProps>({
  "& .MuiTabs-indicator": {
    backgroundColor: colors.black,
    // borderTopLeftRadius: "8px !important",
    // borderTopRightRadius: "8px",
    height: "2px !important",
  },
  "& .MuiTabs-flexContainer": {
    minHeight: "50px",
    gap: "32px",

    "& .MuiButtonBase-root": {
      minHeight: "50px !important",
    }
  },

  "&.MuiTabs-root": {
    minHeight: "50px",
    margin: "0"
  },

});
