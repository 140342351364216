import {CircularProgress} from '@mui/material';
import './app-loader.scss';

const AppLoader = () => {
    return <>
        <div className="app-loader">
            <CircularProgress color="primary" />
        </div>
    </>;
};

export {AppLoader};
