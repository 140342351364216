import {SpecItemStatus} from './spec.interface';

export interface ApiNode {
    result: ApiNodeItem[];
    total: number
}

export interface ApiNodeItem {
    id: number;
    created: string;
    lastModified: string;
    data: string;
      type: string;
    name: string;
    calculatedData?: any;
    links: { [key: string]: ApiLinkedNodeItem };
    options: { [key: string]: ApiNodeItemOptions };
    referencingNodes: ApiNodeItemReferencingNode[];
    linkVariants: { [key: string]: ApiNodeItemVariant };
    constraints: { [key: string]: ApiNodeItemConstraint };
    status: NodeItemStatus;
}

export interface ApiNodeItemVariant {
    id: string;
    name: string;
    nodeTypes: string[];
    nodeIDs: number[];
    nodeIdFormula: string;
    quantityValue: number | null;
    quantityFormula: string | null;
}


export interface ApiNodeItemConstraint {
    id: string
    type: string
    name: string
    activationPredicate: string
    consistency: ApiNodeItemConstraintConsistency
}

export interface ApiNodeItemConstraintConsistency {
    type: string
    predicate: string
    message: string
}

export interface ApiNodeCloneItem {
    id: number,
    cloneName: string;
    dryRun: boolean;
}

export interface ApiNodeItemReferencingNode {
    id: number;
    name: string;
    status: NodeItemStatus;
    type: string;
}

export interface ApiNodeItemOptions {
    id: string;
    name: string;
    quantityValue: number | null;
    quantityFormula: string | null;
    links: {[key: string]: ApiLinkedNodeItem};
    linkVariants?: { [key: string]: ApiNodeItemVariant };
}

export interface ApiLinkedNodeItem {
    id?: string;
    linkedNode?: any;
    linkedNodeId: number;
    masterNodeId: number;
    name: string;
    quantityFormula: string | null;
    quantityValue: number | null;
    type?: string;
    status?: SpecItemStatus;
}

export interface ApiNodeCreateElement {
    name: string;
    typeId: string;
    data?: any;
}


export interface ApiUpdateNodeRequest {
    id: number;
    formData: {
        [key: string]: any;
    }
}

export interface ApiCloneNodeRequest {
    id: number;
    items: ApiNodeItemCloneDryRunResultReportItem[];
    nodeName: string;
    nodeType: string;
}

export interface ApiNodeItemCloneDryRunResultReportItem {
    errorText: string;
    newValue: any;
    property: string;
    customProperty: string;
}


export interface ApiNodeItemDryRunResult {
    updateReport: ApiNodeItemDryRunResultReport;
}

export interface ApiNodeItemDryRunResultReport {
    items: ApiNodeItemDryRunResultReportItem[];
    success: boolean;
}

export interface ApiNodeItemDryRunResultReportItem {
    errorText: string;
    newValue: any;
    nodeId: number;
    nodeName: string;
    nodeType: string;
    originalValue: any;
    property: string;
    customProperty: string;
}

export enum NodeItemStatus {
    draft = 'draft',
    published = 'published',
    archive = 'archive'
}

export enum NodeAction {
    manage = 'Управлять',
    edit = 'Редактировать',
    delete = 'Удалить',
    archive = 'Архивировать',
    unarchive = 'Вернуть из архива',
    publish = 'Опубликовать',
    unpublish = 'Отменить Публикацию',
    clone = 'Клонировать'
}

export enum NodeOptionAction {
    edit = 'Редактировать опцию',
    delete = 'Удалить',
    properties = 'Свойства опции'
}

export interface NodeOptionActionListItem {
    action: NodeOptionAction;
    active: boolean;
}

export interface NodeActionListItem {
    action: NodeAction;
    active: boolean;
}

export enum NodeStatusCommand {
    publish = 'publish',
    unpublish = 'unpublish',
    archive = 'archive',
    unarchive = 'unarchive'
}

export interface NodeChangeStatusRequest {
    id: number;
    command: NodeStatusCommand;
    dryRun?: boolean
}

export interface NodeDryRunResponseItem {
    id: number;
    name: string;
    type?: string;
}

export interface ApiNodeFilter{
    searchQuery?: string;
    types: string;
    created?: string;
    modified?: string;
    filters?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
    sortDirection?: 'asc' | 'desc';
    showArchive?: boolean,
    showDraft?: boolean;
    showDeleted?: boolean;
}

export interface TestingParamsProps {
  id: number;
  parameter: string;
  type: string;
  value: string | PolicyParamProps;
  errors: string[];
}

interface PolicyParamProps {
    id: string;
    name: string;
}

export interface ApiRecalculationResultProps {
    id: string;
    links: { [key: string]: ApiRecalculationResultProps } | {};
    name: string;
    node: { [key: string]: any };
    nodeTypeId: string;
    quantity: number;
    sourceNodeId: number;
    typeId: string;
}
