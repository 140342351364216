export const componentItemDialogUiSchema ={
    type: 'VerticalLayout',
    elements: [
        {
            type: 'Control',
            label: 'ID',
            scope: '#/properties/id'
        },
        {
            type: 'Control',
            label: 'Название',
            scope: '#/properties/name',
        },
        {
            type: 'Control',
            label: 'Количество',
            scope: '#/properties/value',
        }
    ]
};
