import styled from "@emotion/styled";
import { font } from "../../themes/fonts";
import { colors } from "../../themes/colors";

export type HeaderPrimaryTabProps = {
  isActive?: boolean;
};

export const HeaderPrimaryTab = styled.div((props: HeaderPrimaryTabProps) => ({
  backgroundColor: props.isActive ? "rgba(255, 255, 255, 0.06)" : "inherit",
  backgroundOpacity: props.isActive ? .06 : 0,
  color: props.isActive ? colors.headerWhite : colors.gray550,
  padding: "10px",
  cursor: "pointer",
  borderRadius: "6px",
  ...font.body5Medium,
}));
