import {
    and,
    ControlProps, EnumOption,
    isDescriptionHidden, JsonSchema7, optionIs,
    OwnPropsOfEnum,
    RankedTester,
    rankWith,
    WithClassname
} from '@jsonforms/core';
import {useJsonForms, withJsonFormsControlProps, withJsonFormsMultiEnumProps} from '@jsonforms/react';
import {Autocomplete, CircularProgress, FormHelperText, Grid, Hidden, TextField} from '@mui/material';
import {WithOptionLabel} from '@jsonforms/material-renderers/lib/mui-controls/MuiAutocomplete';
import {useFocus} from '@jsonforms/material-renderers';
import React, {useEffect, useState} from "react";
import styles from "../dependent-autocomplete-renderer/dependent-autocomplete-renderer.module.scss";
import {useAppDispatch} from "../../../../../../hook/store";
import {getFilteredNodes} from "../../../../../../store/slice/node-slice";


const AutocompleteMultipleAsyncRenderer = (props: ControlProps & OwnPropsOfEnum & WithClassname & WithOptionLabel) => {

    const {
        description,
        className,
        config,
        id,
        label,
        required,
        errors,
        data,
        visible,
        handleChange,
        path,
        enabled,
        rootSchema
    } = props;

    const ctx = useJsonForms();
    const dispatch = useAppDispatch();

    const [focused, onFocus, onBlur] = useFocus();
    const [variantTypes, setVariantTypes] = useState<any[]>();
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const isValid = errors.length === 0;
    const appliedUiSchemaOptions = {...config, ...props.uischema.options};

    const showDescription = !isDescriptionHidden(
        visible,
        description,
        focused,
        appliedUiSchemaOptions.showUnfocusedDescription
    );

    const firstFormHelperText = showDescription
        ? description
        : !isValid
            ? errors
            : null;
    const secondFormHelperText = showDescription && !isValid ? errors : null;

    const onChange = (_ev: any, newValues: EnumOption[]) => {
        // convert from an EnumOption to its value
        const values = newValues.map((o) => (o.value ? o.value : o));
        handleChange(path, values);
    };

    if (ctx.core?.data?.variantTypes && JSON.stringify(ctx.core.data.variantTypes) !== JSON.stringify(variantTypes)) {
        setVariantTypes(ctx.core.data.variantTypes);
    }

    useEffect(() => {
        if (!!variantTypes) {
            const types = variantTypes.map((v: any) => v.const).join(';');
            if (types) {
                setIsLoading(true);
                dispatch(getFilteredNodes({types: types})).unwrap()
                  .then( (data) => {
                    setOptions (
                            data.result.map ((i: any) => {
                                return {
                                    const: i.id,
                                    title: i.name
                                };
                            })
                        )
                  })
                  .catch((e) => {
                    console.log(e)
                  })
                  .finally ( () => {
                    setIsLoading(false);
                  })

            }
        }
    }, [variantTypes])

    return (

        <Grid container>
            <Grid item xs={12} className={styles.loadContainer}>
                {isLoading && (
                    <CircularProgress color="inherit" className={styles.loading} size={20}/>
                )}
                <Hidden xsUp={!visible}>
                    <Autocomplete
                        style={!enabled ? {pointerEvents: 'none'} : {}}
                        multiple
                        filterSelectedOptions
                        id={id}
                        className={className}
                        disabled={!enabled || !!(rootSchema as any)?.properties?.variantTypes?.readOnly}
                        autoHighlight
                        autoSelect
                        autoComplete
                        fullWidth
                        freeSolo={false}
                        options={options ? options : []}
                        getOptionLabel={(option: any) => (option?.value?.title ? option.value.title : option.title)}
                        value={data}
                        onChange={onChange}
                        isOptionEqualToValue={(o: any, v: any) => {
                            return o.const === v.const;
                        }}
                        renderInput={(params) => (
                            <TextField
                                label={label}
                                variant={'standard'}
                                inputRef={params.InputProps.ref}
                                autoFocus={appliedUiSchemaOptions.focus}
                                {...params}
                                id={id + '-input'}
                                required={required && !appliedUiSchemaOptions.hideRequiredAsterisk}
                                error={!isValid}
                                fullWidth={!appliedUiSchemaOptions.trim}
                                InputLabelProps={data ? {shrink: true} : undefined}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                disabled={!enabled || !!(rootSchema as any)?.properties?.variantTypes?.readOnly}
                                focused={focused}
                            />
                        )}
                    />
                    <FormHelperText error={!isValid && !showDescription}>
                        {firstFormHelperText}
                    </FormHelperText>
                    <FormHelperText error={!isValid}>{secondFormHelperText}</FormHelperText>
                </Hidden>
            </Grid>
        </Grid>

    );
};


export const AsyncAutocompleteMultipleControl = (props: ControlProps & OwnPropsOfEnum) => {
    return <AutocompleteMultipleAsyncRenderer {...props} />;
};


export const autocompleteMultipleAsyncTester: RankedTester = rankWith(
    21,
    and(
        optionIs('autocompleteMultiple', true),
        optionIs('async', true)
    )
);

export default withJsonFormsMultiEnumProps(AsyncAutocompleteMultipleControl);
