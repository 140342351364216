import {ApiLinkedNodeItem} from '../../../../interface';

export interface ItemComponentFormData {
    name: string;
    type: string;
    variant: string;
    linkedNodeId: number;
    value: ItemComponentFormDataValue;
}

export interface ItemComponentFormDataValue {
    formula?: string | null;
    value?: number | null;
}

export enum ItemComponentActions {
    move = 'Переместить в ...',
    clone = 'Клонировать в ...',
    edit = 'Редактировать',
    goto = 'Перейти к компоненту',
    delete = 'Удалить'
}

export interface ItemLinkSet {
    id?: string;
    links: { [key: string]: ApiLinkedNodeItem };
    quantityFormula?: string;
    quantityValue?: number;
}

export enum ItemLinkSetType{
    links = 'links',
    option = 'option'
}
