import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import baseRequest from '../../utils/base-request';
import {
    ApiNode,
    ApiNodeCreateElement,
    ApiNodeItem,
    ApiUpdateNodeRequest,
    ApiNodeCloneItem,
    NodeChangeStatusRequest,
    NodeItemStatus,
    NodeStatusCommand,
    SearchParams,
    ApiRecalculationResultProps,
} from '../../interface';

export interface NodeState {
  nodeList: ApiNodeItem[] | null;
  currentNodeItem: ApiNodeItem | null;
  filteredNodes: ApiNodeItem[] | null;
  total: number;
  recalculationResult: ApiRecalculationResultProps | null;
}

export const getNode = createAsyncThunk(
    'node/getNode'  ,
    async function (request: { id: string, params: SearchParams }, {dispatch}) {
        dispatch(clearNodeList());
        const response = await baseRequest({
            method: 'get',
            url: `/api/node?type=${request.id}`,
            params: request.params
        }, dispatch);
        return response.data;
    }
);

export const getNodeItem = createAsyncThunk(
    'node/getNodeItem',
    async function (id: string, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: `/api/node/${id}`,
        }, dispatch);
        return response.data;
    }
);

export const deleteNodeItem = createAsyncThunk(
    'node/deleteNodeItem',
    async function (data: { id: number, dryRun?: boolean }, {dispatch}) {
        let url = `/api/node/${data.id}`;
        if (data.dryRun) {
            url += '?dryRun=true';
        }
        const response = await baseRequest({
            method: 'delete',
            url: url,
        }, dispatch);
        return response.data;
    }
);

export const changeNodeItemStatus = createAsyncThunk(
    'node/changeNodeItemStatus',
    async function (data: NodeChangeStatusRequest, {dispatch}) {
        let url = `/api/node/${data.id}/command/${data.command}`;
        if (data.dryRun) {
            url += '?dryRun=true';
        }
        const response = await baseRequest({
            method: 'put',
            url: url,
        }, dispatch);
        return {
            response: response.data,
            request: data
        };
    }
);

export const updateNodeItem = createAsyncThunk(
    'node/updateNodeItem',
    async function (data: ApiUpdateNodeRequest, {dispatch}) {
        const response = await baseRequest({
            method: 'put',
            url: `/api/node/${data.id}`,
            data: data.formData
        }, dispatch);
        return response.data;
    }
);

export const createNodeItem = createAsyncThunk(
    'node/createNodeItem',
    async function (data: ApiNodeCreateElement, {dispatch}) {
        if (!data.data) {
            data.data = {};
        }
        const response = await baseRequest({
            method: 'post',
            url: '/api/node',
            data
        }, dispatch);
        return response.data;
    }
);

export const cloneNodeItem = createAsyncThunk(
    'node/cloneNodeItem',
    async function (data: ApiNodeCloneItem, {dispatch}) {
        const response = await baseRequest({
            method: 'put',
            url: `/api/node/${data.id}/command/clone`,
            params: data,
        }, dispatch);
        return response.data;
    }
);

export const getFilteredNodes = createAsyncThunk(
  "node/getFilteredNodes",
  async function (params: { [k: string]: string }, { dispatch }) {
    const response = await baseRequest(
      {
        method: "get",
        url: "/api/node2",
        params,
      },
      dispatch
    );
    return response.data;
  }
);

export const recalculateNodes = createAsyncThunk(
  "node/recalculateNodes",
  async function (data: ApiUpdateNodeRequest, { dispatch, rejectWithValue }) {
    try {
        const response = await baseRequest(
            {
                method: "post",
                url: `/api/node/${data.id}/recalculate`,
                data: data.formData,
            },
            dispatch
        );

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
  }
);

const initialState: NodeState = {
  nodeList: null,
  currentNodeItem: null,
  filteredNodes: null,
  total: 0,
  recalculationResult: null,
};

const nodeSlice = createSlice({
    name: 'node',
    initialState,
    reducers: {
        clearNodeList(state: NodeState) {
            state.nodeList = null;
        },
        clearNodeItem(state: NodeState) {
            state.currentNodeItem = null;
        },
        updateCurrentNodeItem(state: NodeState, action: PayloadAction<ApiNodeItem>) {
            state.currentNodeItem = action.payload;
        },
        resetNodeState() {
            return initialState;
        }
    },
    extraReducers: {
        [changeNodeItemStatus.fulfilled as any]: (state: NodeState, action: PayloadAction<{ response: any, request: NodeChangeStatusRequest }>) => {
            if (action.payload.request.dryRun) {
                return;
            }
            let status;
            switch (action.payload.request.command) {
                case NodeStatusCommand.publish:
                    status = NodeItemStatus.published;
                    break;
                case NodeStatusCommand.unpublish:
                    status = NodeItemStatus.draft;
                    break;
                case NodeStatusCommand.archive:
                    status = NodeItemStatus.archive;
                    break;
                case NodeStatusCommand.unarchive:
                    status = NodeItemStatus.published;
                    break;
            }
            if (status) {
                if (state.currentNodeItem) {
                    state.currentNodeItem = {
                        ...state.currentNodeItem,
                        status: status
                    };
                }
                const nodeItem = state.nodeList?.find(item => item.id === action.payload.request.id);
                if (nodeItem) {
                    nodeItem.status = status;
                }
            }
        },
        [getNode.fulfilled as any]: (state: NodeState, action: PayloadAction<ApiNode>) => {
            state.nodeList = action.payload.result;
            state.total = action.payload.total;
        },
        [getNodeItem.fulfilled as any]: (state: NodeState, action: PayloadAction<ApiNodeItem>) => {
            state.currentNodeItem = action.payload;
        },
        [getFilteredNodes.fulfilled as any]: (state: NodeState, action: PayloadAction<ApiNode>) => {
          state.filteredNodes = action.payload.result;
        },
        [recalculateNodes.fulfilled as any]: (state: NodeState, action: PayloadAction<ApiRecalculationResultProps>) => {
            state.recalculationResult = action.payload;
        },
    }
});

export const {clearNodeList, clearNodeItem, resetNodeState, updateCurrentNodeItem} = nodeSlice.actions;
export default nodeSlice.reducer;
