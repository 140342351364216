import {ComponentItemTab, ComponentItemTabType} from './component-item.interface';

export const componentItemTabs: ComponentItemTab[] = [
    {
        title: 'Редактирование',
        path: '',
        position: 'left',
        type: ComponentItemTabType.edit
    },
    {
        title: 'Свойства',
        position: 'left',
        path: 'properties',
        type: ComponentItemTabType.properties
    },
    {
        title: 'Ссылки',
        position: 'right',
        path: 'references',
        type: ComponentItemTabType.references
    },
    {
        title: 'История изменений',
        position: 'right',
        path: 'history',
        type: ComponentItemTabType.history
    }
];
