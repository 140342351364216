import {DataViewType} from '../../../store/slice/data-view-slice';
import {Button, ButtonGroup} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import GridViewIcon from '@mui/icons-material/GridView';


interface ViewTypeButtonsProps {
    view: DataViewType
    setView: (view: DataViewType) => void
}

const ViewTypeButtons = ({view, setView}: ViewTypeButtonsProps) => {
    return <>
        <ButtonGroup>
            <Button onClick={() => setView(DataViewType.grid)}
                    variant={view === DataViewType.grid ? 'contained' : 'outlined'}
            >
                <MenuIcon/>
            </Button>
            <Button onClick={() => setView(DataViewType.tile)}
                    variant={view === DataViewType.tile ? 'contained' : 'outlined'}
            >
                <GridViewIcon/>
            </Button>
        </ButtonGroup>
    </>;
};

export {ViewTypeButtons};
