import {URLSearchParamsInit} from 'react-router-dom/dist/dom';

export const INITIAL_SEARCH_PARAMS: URLSearchParamsInit = {
    offset: '0',
    limit: '7',
};


export const SEARCH_PARAMS = [
    'offset',
    'limit',
    'sortBy',
    'sortDirection',
    'searchQuery'
];

export const SEARCH_PARAMS_WITH_ARCHIVE = [
    'offset',
    'limit',
    'sortBy',
    'sortDirection',
    'searchQuery',
    'showArchive'
];

export const PAGE_SIZE = [10, 50, 100, 200];
export const DEFAULT_PAGE_SIZE = 50;
