import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HeaderView } from "./header.view";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../constants/routes";
import { Section, SectionViewData } from "./header.types";

export interface HeaderContainerProps {}

export const HeaderContainer: FC<HeaderContainerProps> = ({}) => {
  const navigate = useNavigate();

  const sections = useRef<Section[]>([
    {
      id: "0",
      label: "Спецификации",
      defaultSubSectionId: "Список",
      subSections: [
        {
          id: "Список",
          label: "Список",
          path: ROUTE_PATH.specifications,
          route: `/${ROUTE_PATH.specifications}`,
        },
      ],
    },
    {
      id: "Библиотека",
      label: "Библиотека",
      defaultSubSectionId: "Компоненты",
      subSections: [
        {
          id: "Компоненты",
          label: "Компоненты",
          path: ROUTE_PATH.newComponents,
          route: `/${ROUTE_PATH.newComponents}`,
        },
        {
          id: "Справочники",
          label: "Справочники",
          path: ROUTE_PATH.dictionary,
          route: `/${ROUTE_PATH.dictionary}`,
        },
        {
          id: "Конфигурации",
          label: "Конфигурации",
          path: ROUTE_PATH.configurations,
          route: `/${ROUTE_PATH.configurations}`,
        },
      ],
    },
    {
      id: "2",
      label: "Безопасность",
      defaultSubSectionId: "Пользователи",
      subSections: [
        {
          id: "Пользователи",
          label: "Пользователи",
          path: ROUTE_PATH.users,
          route: `/${ROUTE_PATH.users}?offset=0&limit=10`,
        },
      ],
    },
    {
      id: "3",
      label: "Настройки",
      defaultSubSectionId: "Общее",
      subSections: [
        {
          id: "Общее",
          path: ROUTE_PATH.settings,
          label: "Общее",
          route: `/${ROUTE_PATH.settings}`,
        },
      ],
    },
  ]);

  const [currentSection, setCurrentSection] = useState<Section>();

  const location = useLocation();

  const sectionsViewData = useMemo<SectionViewData[]>(() => {
    return sections.current.map((section) => ({
      id: section.id,
      text: section.label,
      isActive: currentSection ? section.id === currentSection.id : false,
    }));
  }, [currentSection]);

  const subSectionsViewData = useMemo<SectionViewData[]>(() => {
    if (currentSection) {
      return currentSection.subSections.map((subSection) => ({
        id: subSection.id,
        text: subSection.label,
        isActive: location.pathname.includes(subSection.path),
      }));
    } else {
      return [];
    }
  }, [currentSection, location.pathname]);

  const onSectionChange = useCallback(
    (sectionId: string) => {
      const section = sections.current.find(
        (section) => section.id === sectionId
      );

      if (section) {
        const subSection = section.subSections.find(
          (subSection) => subSection.id === section.defaultSubSectionId
        );
        if (subSection) {
          navigate(subSection.route);
        }
      }
    },
    [navigate]
  );

  const onSubSectionChange = useCallback(
    (subSectionId: string) => {
      if (currentSection) {
        const subSection = currentSection.subSections.find(
          (subSection) => subSection.id === subSectionId
        );

        if (subSection) {
          navigate(subSection.route);
        }
      }
    },
    [currentSection, navigate]
  );

  useEffect(() => {
    if (location.pathname) {
      const section = sections.current.find((section) => {
        return section.subSections.find((subSection) =>
          location.pathname.includes(subSection.path)
        );
      });

      section && setCurrentSection(section);
    }
  }, [location.pathname]);

  return (
    <HeaderView
      sections={sectionsViewData}
      subSections={subSectionsViewData}
      onSectionChange={onSectionChange}
      onSubSectionChange={onSubSectionChange}
    />
  );
};
