export const  DocumentTitleTail = 'Конфигуратор';

export interface DocumentTitleItems {
    [key: string]: DocumentTitleItem;
}

export enum DocumentTitlePageType {
    specification,
    component,
    dictionary,
    configuration,
    user
}


export interface DocumentTitleItem {
    title: string;
    type: DocumentTitlePageType
    params?: string[];
}


export const AppTitleMeta: DocumentTitleItems = {
    '/specifications': {
        title: 'Спецификации',
        type: DocumentTitlePageType.specification
    },
    '/specifications/:id/data-input': {
        title: '',
        type: DocumentTitlePageType.specification,
        params: ['typeId', 'name']
    },
    '/specifications/:id/specification': {
        title: '',
        type: DocumentTitlePageType.specification,
        params: ['typeId', 'name']
    },
    '/specifications/:id/documents': {
        title: '',
        type: DocumentTitlePageType.specification,
        params: ['typeId', 'name']
    },
    '/specifications/:id/structure': {
        title: '',
        type: DocumentTitlePageType.specification,
        params: ['typeId', 'name']
    },
    '/components': {
        title: 'Компоненты',
        type: DocumentTitlePageType.component
    },
    'components/type': {
        title: 'Компоненты',
        type: DocumentTitlePageType.component
    },
    '/components/:type/:id': {
        title: '',
        type: DocumentTitlePageType.component,
        params: ['name']
    },
    '/dictionary': {
        title: 'Справочники',
        type: DocumentTitlePageType.dictionary
    },
    '/dictionary/type': {
        title: '',
        type: DocumentTitlePageType.dictionary,
        params: ['typeName']
    },
    '/dictionary/:type/:id': {
        title: '',
        type: DocumentTitlePageType.dictionary,
        params: ['typeName', 'name']
    },
    '/configurations': {
        title: 'Конфигурации',
        type: DocumentTitlePageType.configuration
    },
    '/configurations/:id': {
        title: '',
        type: DocumentTitlePageType.configuration,
        params: ['name']
    },
    '/users': {
        title: 'Пользователи',
        type: DocumentTitlePageType.user
    },
    '/users/:id': {
        title: '',
        type: DocumentTitlePageType.user,
        params: ['fullName']
    }

};
