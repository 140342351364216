import {
    and,
    ControlProps, EnumOption,
    isDescriptionHidden, optionIs,
    OwnPropsOfEnum,
    RankedTester,
    rankWith,
    WithClassname
} from '@jsonforms/core';
import {withJsonFormsMultiEnumProps} from '@jsonforms/react';
import {Autocomplete, FormHelperText, Hidden, TextField} from '@mui/material';
import {WithOptionLabel} from '@jsonforms/material-renderers/lib/mui-controls/MuiAutocomplete';
import {useFocus} from '@jsonforms/material-renderers';


const AutocompleteMultipleRenderer = (props: ControlProps & OwnPropsOfEnum & WithClassname & WithOptionLabel) => {

    const {
        description,
        className,
        config,
        id,
        label,
        required,
        errors,
        data,
        visible,
        options,
        handleChange,
        path,
        enabled,
    } = props;

    const [focused] = useFocus();
    const isValid = errors.length === 0;
    const appliedUiSchemaOptions = {...config, ...props.uischema.options};

    const showDescription = !isDescriptionHidden(
        visible,
        description,
        focused,
        appliedUiSchemaOptions.showUnfocusedDescription
    );

    const firstFormHelperText = showDescription
        ? description
        : !isValid
            ? errors
            : null;
    const secondFormHelperText = showDescription && !isValid ? errors : null;

    const onChange = (_ev: any, newValues: EnumOption[]) => {
        // convert from an EnumOption to its value
        const values = newValues.map((o) => (o.value ? o.value : o));
        handleChange(path, values);
    };



    return (
        <Hidden xsUp={!visible} >
            <Autocomplete
                style={!enabled ? {pointerEvents: 'none'} : {}}
                multiple
                filterSelectedOptions
                id={id}
                className={className}
                disabled={!enabled}
                autoHighlight
                autoSelect
                autoComplete
                fullWidth
                freeSolo={false}
                defaultValue={data}
                options={options ? options : []}
                getOptionLabel={(option: any) => (option?.value?.title ? option.value.title : option.title)}
                onChange={onChange}
                isOptionEqualToValue={(o: any, v: any) => {
                    return o.label === v.label;
                }}
                renderInput={(params) => (
                    <TextField
                        label={label}
                        variant={'standard'}
                        inputRef={params.InputProps.ref}
                        autoFocus={appliedUiSchemaOptions.focus}
                        {...params}
                        id={id + '-input'}
                        required={required && !appliedUiSchemaOptions.hideRequiredAsterisk}
                        error={!isValid}
                        fullWidth={!appliedUiSchemaOptions.trim}
                        InputLabelProps={data ? {shrink: true} : undefined}
                        disabled={!enabled}
                        focused={focused}
                    />
                )}
            />
            <FormHelperText error={!isValid && !showDescription}>
                {firstFormHelperText}
            </FormHelperText>
            <FormHelperText error={!isValid}>{secondFormHelperText}</FormHelperText>
        </Hidden>
    );
};


export const MultiSelectEnumArrayControl = (props: ControlProps & OwnPropsOfEnum) => {
    return <AutocompleteMultipleRenderer {...props} />;
};


export const autocompleteMultipleTester: RankedTester = rankWith(
    20,
    and(
        optionIs('autocompleteMultiple', true)
    )
);

export default withJsonFormsMultiEnumProps(MultiSelectEnumArrayControl);
