import {ReactComponent as IconWarning} from '../../assets/img/icon/warning-small.svg';
import {ReactComponent as IconInfo} from '../../assets/img/icon/info-small.svg';
import {ReactComponent as IconDanger} from '../../assets/img/icon/danger.svg';
import './status-message.scss';
import {SpecItemValidationItem, SpecItemValidationItemType} from '../../interface/spec.interface';

const StatusMessage = ({id, text, type}: SpecItemValidationItem) => {

    const getIcon = () => {
        switch (type){
            case SpecItemValidationItemType.information:
                return <IconInfo/>;
            case SpecItemValidationItemType.warning:
                return <IconWarning/>;
            case SpecItemValidationItemType.error:
                return <IconDanger/>;
        }
    };


    return <>
        <div className={'status-message'}>
            {getIcon()} <span>{text}</span>
        </div>
    </>;
};

export {StatusMessage};
