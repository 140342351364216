import {ApiNodeItemReferencingNode} from '../../../../interface';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {Grid, GridCellProps, GridColumn, GridSortChangeEvent, GridNoRecords as NoRecords} from '@progress/kendo-react-grid';
import {orderBy, SortDescriptor} from '@progress/kendo-data-query';
import {AppSuspense} from '../../../../components/app-suspense/app-suspense';
import {ReactElement, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {MenuActions} from '../../../../components/menu-actions/menu-actions';
import {DropDownButtonItem} from '@progress/kendo-react-buttons';
import {useAppSelector} from '../../../../hook/store';
import {NodeState} from '../../../../store/slice/node-slice';
import {GridRowClickEvent} from '@progress/kendo-react-grid/dist/npm/interfaces/events';
import {DropDownButtonItemClickEvent} from '@progress/kendo-react-buttons/dist/npm/ListButton/models/events';
import {ComponentItemNameCell} from '../component-item-links/component-item-name-cell/component-item-name-cell';
import '../component-item.scss';
import {ROUTE_PATH} from '../../../../constants/routes';

const ComponentItemRefs = () => {
    const navigate = useNavigate();
    const {currentNodeItem} = useAppSelector<NodeState>(store => store.node);
    const [sort, setSort] = useState<SortDescriptor[]>([]);

    const handleAction = (action: DropDownButtonItemClickEvent, item: ApiNodeItemReferencingNode) => {
        if (action.nativeEvent.ctrlKey || action.nativeEvent.metaKey) {
            window.open(`/${ROUTE_PATH.newComponents}/${item.type}/${item.id}`, '_blank');
        } else {
            navigate(`/${ROUTE_PATH.newComponents}/${item.type}/${item.id}`);
        }
    };

    const gridActions = (props: GridCellProps): ReactElement => {
        const item = props.dataItem as ApiNodeItemReferencingNode;
        return <td style={{textAlign: 'right'}}>
            <MenuActions onAction={(action) => handleAction(action, item)}>
                <DropDownButtonItem key={'Перейти к компоненту'} text={'Перейти к компоненту'}/>
            </MenuActions>
        </td>;
    };
    const gridTitle = (props: GridCellProps): ReactElement => {
        const item = props.dataItem as any;
        return <td onClick={(event) => handleAction( event as any, item)} className="component-ref-title">
            <ComponentItemNameCell showStatus={true} data={{...item, linkedNode: {...item, calculatedData: {...item.calculatedNodeData}}, name: item.name} as any} />
        </td>;
    };

    const handleRowClick = (event: GridRowClickEvent) => {
        if (event.nativeEvent.ctrlKey || event.nativeEvent.metaKey) {
            window.open(`/${ROUTE_PATH.newComponents}/${event.dataItem.type}/${event.dataItem.id}`, '_blank');
        } else {
            navigate(`/${ROUTE_PATH.newComponents}/${event.dataItem.type}/${event.dataItem.id}`);
        }
    };

    return (

        <AppSuspense condition={!!currentNodeItem}>
            <Card style={{marginBottom: '32px'}}>
                <CardBody>
                    <Grid
                        style={{height: '100%', width: '100%'}}
                        scrollable={'none'}
                        data={orderBy(currentNodeItem?.referencingNodes || [], sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e: GridSortChangeEvent) => {
                            setSort(e.sort);
                        }}
                        className={'component-item-props'}
                        onRowClick={handleRowClick}
                    >
                        <GridColumn field="type" title="Тип"/>
                        <GridColumn field="name" title="Название" cell={gridTitle}/>
                        <GridColumn field="" title="" width={100} cell={gridActions}/>

                        <NoRecords>
                            Список пуст.
                        </NoRecords>
                    </Grid>

                </CardBody>
            </Card>
        </AppSuspense>

    );
};

export {ComponentItemRefs as default};
