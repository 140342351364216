import React, {PropsWithChildren, useEffect, useState} from 'react';
import {useAppSelector} from '../../hook/store';
import {UserState} from '../../store/slice/user-slice';
import {RoleState} from '../../store/slice/role-slice';
import {Role} from '../../interface';

interface ProtectedLayoutProps extends PropsWithChildren {
}

const ProtectedLayout = ({children}: ProtectedLayoutProps): any => {

    const {currentUser} = useAppSelector<UserState>(state => state.user);
    const {roleList} = useAppSelector<RoleState>(state => state.role);
    const [role, setRole] = useState<Role>();

    useEffect(() => {
        if (!!roleList && !!currentUser) {
            const role = roleList.find(r => r.id === currentUser.role);
            if (role) {
                setRole(role.name);
            }
        }
    }, [roleList, currentUser]);

    if (role) {
        if (role !== Role.ROLE_ADMIN) {
            return <></>;
        }
        return children;
    }
    return null;
};

export {ProtectedLayout};
