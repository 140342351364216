import {createSlice} from '@reduxjs/toolkit';

export interface DataViewState {
    componentsView: DataViewType;
    dictionaryView: DataViewType;
    configurationsView: DataViewType;
}

export enum DataViewType {
    grid = 'grid',
    tile = 'tile'
}


const initialState: DataViewState = {
    componentsView: DataViewType.tile,
    dictionaryView: DataViewType.tile,
    configurationsView: DataViewType.tile
};

const dataViewSlice = createSlice({
    name: 'dataViewType',
    initialState,
    reducers: {
        setComponentsView(state, action) {
            state.componentsView = action.payload;
            localStorage.setItem('componentsView', action.payload);
        },
        setDictionaryView(state, action) {
            state.dictionaryView = action.payload;
            localStorage.setItem('dictionaryView', action.payload);
        },
        setConfigurationsView(state, action) {
            state.configurationsView = action.payload;
            localStorage.setItem('configurationsView', action.payload);
        }
    },
});

export const {setComponentsView, setDictionaryView, setConfigurationsView} = dataViewSlice.actions;

export default dataViewSlice.reducer;
