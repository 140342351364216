import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppBreadcrumb} from "../../interface/breadcrumbs.interface";

export interface UtilsState {
    searchFocus: boolean;
    breadcrumbs: AppBreadcrumb[];
}


const initialState: UtilsState = {
    searchFocus: false,
    breadcrumbs: []
};

const utilsSlice = createSlice({
    name: 'utils',
    initialState,
    reducers: {
        setSearchFocus(state: UtilsState, action: PayloadAction<boolean>) {
            return {
                ...state,
                searchFocus: action.payload
            };
        },
        setBreadcrumbs(state: UtilsState, action: PayloadAction<AppBreadcrumb[]>){
            return {
                ...state,
                breadcrumbs: action.payload
            };
        },
        cleanBreadcrumbs(state: UtilsState){
            return {
                ...state,
                breadcrumbs: []
            };
        },
        resetUtils() {
            return initialState;
        }
    }
});

export const {setSearchFocus, setBreadcrumbs, cleanBreadcrumbs,resetUtils} = utilsSlice.actions;
export default utilsSlice.reducer;
