import React, {CSSProperties, PropsWithChildren, ReactNode, useEffect, useRef, useState} from 'react';
import './sticky-download-button.scss';

interface StickyDownloadButtonProps extends PropsWithChildren {
    children?: ReactNode | undefined;
    style?: CSSProperties | undefined;
    hideNotStickyWrapper?: boolean
}

const StickyDownloadButton = ({children, style, hideNotStickyWrapper}: StickyDownloadButtonProps) => {

    const buttonRef = useRef<HTMLDivElement | null>(null);
    const [sticky, setSticky] = useState<boolean>(false);


    const handleScroll = () => {
        if (buttonRef) {
            setSticky(buttonRef?.current?.getBoundingClientRect()?.top === 0);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <div className={'sticky-download'} style={style} ref={buttonRef}>
            <div className={`wrapper ${sticky ? 'sticky' : ''} ${hideNotStickyWrapper ? 'no-style' : ''}`}>
                {children}
            </div>
        </div>
    );
};

export {StickyDownloadButton};
