import {
    ApiNodeItem,
    NodeItemStatus,
    NodeOptionAction,
    NodeOptionActionListItem
} from '../../../../../interface';

export const getComponentOptionActionItems = (nodeItem: ApiNodeItem): NodeOptionActionListItem[] => {
    return [
        {
            action: NodeOptionAction.edit,
            active: nodeItem.status === NodeItemStatus.draft
        },
        {
            action: NodeOptionAction.delete,
            active: nodeItem.status === NodeItemStatus.draft
        },
        {
            action: NodeOptionAction.properties,
            active: nodeItem.status !== NodeItemStatus.draft
        }
    ];
};
