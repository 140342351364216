import axios, {AxiosRequestConfig} from 'axios';
import Cookies from 'js-cookie';
import {showError} from '../store/slice/toast-slice';
import {userLogout} from '../store/slice/auth-slice';

const unauthorisedStatus = [401, 403, 0];


export default async function baseRequest(params: AxiosRequestConfig, dispatch: any): Promise<any> {
    const headers = {...params.headers};

    if (!Object.keys(headers).find(header => header.toLowerCase() === 'content-type')) {
        headers.Accept = '/';
        headers['Content-Type'] = 'application/json-patch+json';
    }

    try {
        return await axios({
            ...params,
            withCredentials: true,
        });
    } catch (error: any) {
        if (error.response && unauthorisedStatus.includes(error.response.status)) {
            Cookies.remove('isAuthorized');
            if (window.location.pathname !== '/login') {
                dispatch(userLogout()).unwrap()
                    .then(() => {
                        window.location.href = '/login';
                    })
                    .catch(() => {
                        window.location.href = '/login';
                    });

            }
        } else {
            if (error.response?.data?.errors && Object.keys(error.response?.data?.errors).length) {
                for (const [key, value] of Object.entries(error.response?.data?.errors)) {
                    if (Array.isArray(value)) {
                        dispatch(showError(value.join(', ') || `'${params.url}' ${error.response.statusText}`));
                    }
                }
            } else if (error?.response?.data?.Error) {
                dispatch(showError(error.response.data.Error));
            } else if (error?.response.status > 0) {
                dispatch(showError(`'${params.url}' ${error.response.statusText}`));
            }
        }
        throw error;
    }
};
