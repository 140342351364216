import {Invoice} from '../../components/invoice/invoice';
import React, {useEffect, useState} from 'react';
import {useAppSelector} from '../../../../../hook/store';
import {SpecificationState} from '../../../../../store/slice/spec-slice';
import {AppSuspense} from '../../../../../components/app-suspense/app-suspense';
import {SpecificationTabWrapper} from '../tab-header/specification-tab-wrapper';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {ScrollToTop} from '../../../../../ui/scroll-to-top/scroll-to-top';

const SpecificationCalculation = () => {
    const {currentSpec} = useAppSelector<SpecificationState>(store => store.spec);
    const [isReady, setIsReady] = useState<boolean>(false);

    const isValidInvoice = (): boolean => {
        return !!currentSpec?.doc?.invoice && !currentSpec.doc.invoice.isEmpty;
    };

    useEffect(() => {
        if (!!currentSpec?.id) {
            setIsReady(true);
        }
    }, [currentSpec]);


    return (
        <>
            <SpecificationTabWrapper>
                {!!currentSpec && (
                    <AppSuspense condition={isReady}>
                        <Card style={{overflow: 'unset'}}>
                            <CardBody>
                                {isValidInvoice() ? (

                                    <Invoice invoice={currentSpec.doc?.invoice}
                                             specId={currentSpec.id}></Invoice>
                                ) : (
                                    <div className={'empty-result'}>параметры спецификации
                                        недоступны
                                    </div>
                                )}

                            </CardBody>
                        </Card>
                    </AppSuspense>
                )}
                <ScrollToTop/>
            </SpecificationTabWrapper>
        </>
    );
};

export {SpecificationCalculation};
