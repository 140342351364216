import {useEffect, useState} from 'react';

export default function useDebounce(value: string | undefined, delay = 500): string | undefined {
    const [debouncedValue, setDebouncedValue] = useState<string | undefined>(value);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timeout);
        };
    }, [value, delay]);

    return debouncedValue;
}
