import styles from '../settings.module.scss';
import {Card, CardBody, CardTitle} from '@progress/kendo-react-layout';
import {AppSuspense} from '../../../components/app-suspense/app-suspense';
import {useEffect, useState} from 'react';
import {ApiSystemSettings} from '../../../interface/system-settings.interface';
import {useAppDispatch, useAppSelector} from '../../../hook/store';
import {getSettings, SettingsState} from '../../../store/slice/settings-slice';


const SettingsAbout = () => {

    const [version, setVersion] = useState<string>('');
    const {settings} = useAppSelector<SettingsState>(state => state.settings);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(!settings?.serverVersion) {
            dispatch(getSettings());
        }else{
            setVersion(settings.serverVersion || '-');
        }
    }, [settings]);

    return (
        <Card className={styles.settingsCard}>
            <CardTitle className={styles.settingsTitle}>О программе</CardTitle>
            <CardBody className={styles.settingsBody}>
                <AppSuspense condition={!!version}>
                    <div className={styles.row}>
                        <div>Версия Конфигуратора</div>
                        <div>{version}</div>
                    </div>
                </AppSuspense>
            </CardBody>
        </Card>
    );
};

export {SettingsAbout};
