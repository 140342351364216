import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import baseRequest from '../../utils/base-request';
import {
    ApiDictionary,
    ApiDictionaryCreate,
    ApiDictionaryItem,
    ApiDictionaryUpdate,
    SearchParams
} from '../../interface';

export interface DictionaryState {
    dictionaryList: ApiDictionaryItem[] | null;
    currentDictionary: ApiDictionaryItem | null;
    total: number;
}


export const getDictionary = createAsyncThunk(
    'dictionary/getDictionary',
    async function (params: { [k: string]: string }, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: '/api/dictionary2',
            params
        }, dispatch);
        return response.data;
    }
);

export const getDictionaryItem = createAsyncThunk(
    'dictionary/getDictionaryItem',
    async function (id: string, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: `/api/dictionary/${id}`,
        }, dispatch);
        return response.data;
    }
);


export const createDictionary = createAsyncThunk(
    'dictionary/createDictionary',
    async function (data: ApiDictionaryCreate, {dispatch}) {
        data.data = {};
        const response = await baseRequest({
            method: 'post',
            url: '/api/dictionary',
            data
        }, dispatch);
        return response.data;
    }
);

export const updateDictionary = createAsyncThunk(
    'dictionary/updateDictionary',
    async function (data: ApiDictionaryUpdate, {dispatch}) {
        const response = await baseRequest({
            method: 'put',
            url: `/api/dictionary/${data.id}`,
            data: data.formData
        }, dispatch);
        return response.data;
    }
);

export const deleteDictionaryItem = createAsyncThunk(
    'dictionary/deleteDictionaryItem',
    async function (id: number, {dispatch}) {
        const response = await baseRequest({
            method: 'delete',
            url: `/api/dictionary/${id}`,
        }, dispatch);
        return response.data;
    }
);

const initialState: DictionaryState = {
    dictionaryList: null,
    currentDictionary: null,
    total: 0
};


const dictionarySlice = createSlice({
    name: 'dictionary',
    initialState,
    reducers: {
        clearDictionary(state: DictionaryState) {
            state.dictionaryList = null;
        },
        clearCurrentDictionary(state: DictionaryState) {
            state.currentDictionary = null;
        },
        resetDictionaryState() {
            return initialState;
        }
    },
    extraReducers: {
        [getDictionary.fulfilled as any]: (state: DictionaryState, action: PayloadAction<ApiDictionary>) => {
            state.dictionaryList = action.payload.result;
            state.total = action.payload.total;
        },
        [getDictionaryItem.fulfilled as any]: (state: DictionaryState, action: PayloadAction<ApiDictionaryItem>) => {
            state.currentDictionary = action.payload;
        }
    }
});

export const {clearDictionary, clearCurrentDictionary, resetDictionaryState} = dictionarySlice.actions;
export default dictionarySlice.reducer;
