import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hook/store';
import {useEffect, useState} from 'react';
import {resetAlert} from '../../store/slice/alert-slice';

const AppAlert = () => {
    const dispatch = useAppDispatch();
    const {message, header, errorColor} = useAppSelector(store => store.alert);
    const [alert, setAlert] = useState<string | null>(null);

    const closeAlert = () => {
        dispatch(resetAlert());
    };

    useEffect(() => {
        setAlert(message);
    }, [message]);

    return <>
        {!!alert && <Dialog
            className="app-alert-dialog"
            open={true}
            fullWidth={true}
            maxWidth={'xs'}
            onClose={closeAlert}
        >
            {!!header && (
                <DialogTitle>{header}</DialogTitle>
            )}
            <DialogContent dividers>
                <p style={{color: errorColor || ''}}>{message}</p>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'flex-end' }}>
                <Button
                    variant={'contained'}
                    onClick={closeAlert}
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
        }
    </>;
};

export {AppAlert};
