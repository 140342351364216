import {Card, CardBody} from '@progress/kendo-react-layout';
import {ComponentItemTab, ComponentItemTabType} from '../component-item.interface';


interface ComponentItemTabsProps {
    tabs: ComponentItemTab[],
    selected: ComponentItemTab;
    onSelect: (tab: ComponentItemTab) => void;
}

const ComponentItemTabs = ({tabs, selected, onSelect}: ComponentItemTabsProps) => {
    return (
        <Card>
            <CardBody className={'item-tabs'}>
                <div className="form-tabs">
                    {
                        tabs.filter(t => t.position === 'left').map((tab, index) => {
                            return <div
                                key={index}
                                className={selected.type === tab.type ? 'tab active' : 'tab'}
                                onClick={() => onSelect(tab)}
                            >
                                {tab.title}
                            </div>;
                        })
                    }
                </div>
                <div className="action-tabs">
                    {
                        tabs.filter(t => t.position === 'right').map((tab, index) => {
                            return <div
                                key={index}
                                className={selected.type === tab.type ? 'tab active' : 'tab'}
                                onClick={() => onSelect(tab)}
                            >
                                {tab.title}
                            </div>;
                        })
                    }
                </div>
            </CardBody>
        </Card>
    );

};

export {ComponentItemTabs};
