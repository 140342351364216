import {useEffect, useState} from 'react';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {useAppSelector} from '../../../../hook/store';
import {NodeState} from '../../../../store/slice/node-slice';
import {Grid, GridColumn, GridSortChangeEvent, GridNoRecords as NoRecords} from '@progress/kendo-react-grid';
import {orderBy, SortDescriptor} from '@progress/kendo-data-query';
import {AppSuspense} from '../../../../components/app-suspense/app-suspense';
import './component-item-props.scss';
import {NodeTypeState} from '../../../../store/slice/node-type-slice';


interface PropsDataItem {
    title: string;
    value: any;
}

const ComponentItemProps = () => {

    const {currentNodeItem} = useAppSelector<NodeState>(store => store.node);
    const {allNodeTypes} = useAppSelector<NodeTypeState>(store => store.nodeType);
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const [data, setData] = useState<PropsDataItem[]>([]);

    useEffect(() => {
        if(Array.isArray(allNodeTypes) && !!currentNodeItem){
            const nodeType = allNodeTypes.find(n => n.id === currentNodeItem.type);

            if (!!nodeType?.typeSchema?.properties && !!currentNodeItem) {
                const props = Object.entries(nodeType.typeSchema.properties);
                const data: PropsDataItem[] = props.map(([k, v]) => (
                    {
                        title: v?.title || '-',
                        value: currentNodeItem.calculatedData[k] || '-'
                    }
                ));
                setData(data);
            }
        }
    }, [currentNodeItem, allNodeTypes]);

    return (
        <AppSuspense condition={!!data?.length}>
            <Card style={{marginBottom: '32px'}}>
                <CardBody>
                    <Grid
                        style={{height: '100%', width: '100%'}}
                        scrollable={'none'}
                        data={orderBy(data, sort)}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e: GridSortChangeEvent) => {
                            setSort(e.sort);
                        }}
                        className={'component-item-props non-clickable'}
                    >
                        <GridColumn field="title" title="Свойство"/>
                        <GridColumn field="value" title="Вычисленное значение" className="component-item-props__value"/>

                        <NoRecords>
                            Список пуст.
                        </NoRecords>
                    </Grid>

                </CardBody>
            </Card>
        </AppSuspense>
    );
};

export {ComponentItemProps as default};
