import './specification-comment.scss';
import {
    Button,
    FormControl,
    FormGroup, IconButton, Popover,
    TextField
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useForm} from 'react-hook-form';
import React, {useEffect, useState} from 'react';
import {SpecCommentRequest, SpecItem} from '../../../../../interface';
import {useAppDispatch} from '../../../../../hook/store';
import {updateComment} from '../../../../../store/slice/spec-slice';
import {ReactComponent as CommentIcon} from '../../../../../assets/img/icon/comment.svg';
import {Close} from '@mui/icons-material';

interface EditCommentDialogProps {
    specification: SpecItem;
}

const MAX_LENGTH = 1000;

const SpecificationComment = ({specification}: EditCommentDialogProps) => {
    const dispatch = useAppDispatch();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [remaining, setRemaining] = useState<number>(MAX_LENGTH);
    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
        reset
    } = useForm({
        values: {
            comment: specification.comment,
        },
        reValidateMode: 'onChange',
        mode: 'all'
    });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        reset();
        resetRemaining();
    };

    const resetRemaining = (): void => {
        if(!!specification.comment ){
            setRemaining(MAX_LENGTH - specification.comment.length);
            return;
        }
        setRemaining (MAX_LENGTH);
    };

    const onSubmit = async (formData: any) => {
        setInProgress(true);
        const data: SpecCommentRequest = {
            id: specification.id,
            data: formData
        };
        await dispatch(updateComment(data)).unwrap()
            .then(() => {
                handleClose();
            })
            .catch((error) => {
                // onClose();
                throw new Error(error);
            })
            .finally(() => {
                setInProgress(false);
            });
    };

    const handleRemaining = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
            setRemaining(MAX_LENGTH - e.target.value.length);
    };


    const open = Boolean(anchorEl);
    const id = open ? 'comment-popover' : undefined;


    useEffect(() => {
        resetRemaining();
    }, [specification]);

    useEffect(() => {
        reset();
    }, []);

    return <>

        <Button
            aria-describedby={id}
            className={`icon-button ${!!specification.comment ? 'active' : ''}`}
            disableElevation={true}
            variant={'outlined'}
            onClick={handleClick}
        >
            <CommentIcon/>
        </Button>

        <Popover
            className="edit-comment-dialog"
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >

            <div className="dialog-title">
                Комментарии к спецификации
                <IconButton aria-label="close" size="medium" onClick={handleClose}>
                    <Close fontSize="inherit"/>
                </IconButton>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={'dialog-content'}>
                    <FormGroup className={'form-field'}>
                        <FormControl>
                            <TextField
                                className="dialog-input"
                                {...register('comment', {
                                    maxLength: {value: MAX_LENGTH, message: 'превышен лимит символов'},
                                    onChange: (e) => handleRemaining(e)
                                })}
                                multiline
                                type={'text'}
                                variant="outlined"
                            />
                            <div className={`count ${remaining < 0 ? 'error' : ''}`}>
                                {remaining}
                            </div>
                        </FormControl>
                    </FormGroup>

                </div>

                <div className="form-buttons">
                    <Button
                        variant={'outlined'}
                        onClick={handleClose}
                        disabled={inProgress}
                        disableElevation={true}
                    >
                        Отменить
                    </Button>
                    <LoadingButton
                        loading={inProgress}
                        type={'submit'}
                        variant={'contained'}
                        disabled={!isValid || inProgress}
                        disableElevation={true}
                    >
                        Сохранить
                    </LoadingButton>
                </div>
            </form>

        </Popover>
    </>;

};

export {SpecificationComment};
