import {
    Role,
    SpecificationItemAction,
    SpecificationItemActionListItem,
    SpecItem,
    SpecItemStatus
} from '../../../../interface';


export const getActionItems = (itemPage: boolean, itemData: SpecItem, userRole: Role): SpecificationItemActionListItem[] => {

    return [
        {
            action: SpecificationItemAction.open,
            active: !itemPage
        },
        {
            action: SpecificationItemAction.archive,
            active: itemData.status === SpecItemStatus.published
        },
        {
            action: SpecificationItemAction.publish,
            active: itemData.status === SpecItemStatus.draft && !itemPage
        },
        {
            action: SpecificationItemAction.unarchive,
            active: itemData.status === SpecItemStatus.archive
        },
        {
            action: SpecificationItemAction.rename,
            active: true
        },
        {
            action: SpecificationItemAction.clone,
            active: itemData.status === SpecItemStatus.archive || itemData.status === SpecItemStatus.published
        },
        {
            action: SpecificationItemAction.delete,
            active: true
        },
        {
            action: SpecificationItemAction.changeOwner,
            active: userRole === Role.ROLE_ADMIN
        }
    ];

};
