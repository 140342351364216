import {JsonSchema} from '@jsonforms/core';
import ObjectHelper from './object.helper';

class LogHelper {

    static validateJsonRequiredSchema = (jsonSchema: JsonSchema) => {
        if (Array.isArray(jsonSchema.required) && jsonSchema.required.length) {
            if (jsonSchema.properties && ObjectHelper.isObject(jsonSchema.properties)) {
                const props = Object.keys(jsonSchema.properties);
                const invalidrequired = jsonSchema.required.filter( (i) => !props.includes(i));
                if(invalidrequired.length){
                    console.warn(`Invalid form required field(s): ${invalidrequired.join(', ')}`);
                }
            } else {
                console.warn('Invalid form json: schema.properties');
            }
        }
    };
}

export default LogHelper;
