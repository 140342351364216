import {
    Autocomplete,
    Box,
    Button,
    createFilterOptions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@mui/material';
import {ApiUser, SpecificationItemActionData} from '../../../../interface';
import {useAppSelector} from '../../../../hook/store';
import {UserState} from '../../../../store/slice/user-slice';
import {useEffect, useState} from 'react';
import {LoadingButton} from '@mui/lab';

interface ChangeUserProps {
    actionData: SpecificationItemActionData,
    onConfirm: (user: ApiUser) => void;
    onClose: () => void;
    author: string;
    isLoading: boolean;
}

const ChangeUser = ({actionData: {action, item}, onConfirm, onClose, author,isLoading}: ChangeUserProps) => {
    const {userList} = useAppSelector<UserState>(store => store.user);
    const [currentUser, setCurrentUser] = useState<ApiUser | null>(null);

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option: ApiUser) => option.fullName + option.username,
    });

    const handleConfirm = () => {
        if (currentUser) {
            onConfirm(currentUser);
        }
    };

    useEffect(() => {
        if (Array.isArray(userList)) {
            const user = userList.find((u) => u.fullName === author);
            if (user) {
                setCurrentUser(user);
            }
        }
    }, [userList]);

    return (
        <Dialog
            className="action-prompt-dialog"
            open={true}
            maxWidth={'sm'}
            onClose={onClose}
        >
            <DialogTitle className="dialog-title">Изменить владельца</DialogTitle>
            <DialogContent>
                <div className={'k-py-4'}>Спецификация доступна пользователю:</div>
                <div className={'k-py-2'}>
                    <Autocomplete
                        id="user"
                        options={userList || []}
                        getOptionLabel={(res) => res.fullName}
                        filterOptions={filterOptions}
                        isOptionEqualToValue={(o, v) => o.id == v.id}
                        noOptionsText={'пользователи не найдены'}
                        value={currentUser}
                        onChange={(event, newValue) => {
                            setCurrentUser(newValue);
                        }}
                        renderOption={(props, res) => (
                            <Box component={'li'} key={res.id} {...props}>
                                {res.fullName}
                            </Box>
                        )}
                        renderInput={(params) => <TextField {...params} label={'Пользователь'}/>}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={'outlined'}
                    onClick={onClose}
                >
                    Отмена
                </Button>
                <LoadingButton
                    variant={'contained'}
                    color={'primary'}
                    onClick={handleConfirm}
                    loading={isLoading}
                    disabled={!currentUser || currentUser.fullName === author}
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export {ChangeUser};
