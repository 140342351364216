import { FormControl, MenuItem, Select } from '@mui/material';
import { TestParamsType } from './component-item-testing-dialog';
import './component-item-testing-dialog.scss';
import { ReactNode, useEffect, useState } from 'react';


interface ComponentItemTestParamRowProps {
  row: any;
  getParameterValueField: (row: any, id: number) => ReactNode;
  handleChangeTestParam: (id: number, e: any) => void;
  handleChangeParamType: (id: number, e: any) => void;
  handleDeleteParamsRow: (id: number) => void;
}

const ComponentItemTestParamRow = ({
  row,
  getParameterValueField,
  handleChangeTestParam,
  handleChangeParamType,
  handleDeleteParamsRow,
}: ComponentItemTestParamRowProps) => {
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        if(!!row.errors.length) {
            setErrorMessage('Обязательное поле для заполнения');
        }
    }, [row.errors]);

    return (
        <div className="inputsRow" key={row.id}>
            <div className="inputContainer">
                <input
                    name={`parameter${row.id}`}
                    value={row.parameter}
                    onChange={(e) => handleChangeTestParam(row.id, e)}
                    style={{border: row.errors.includes('parameter') ? '1px solid #EF4444' : '1px solid var(--color-divider-nested)'}}
                />
                {row.errors.includes('parameter') && <p className="errorMessage">{errorMessage}</p>}
            </div>
            <div className="inputContainer">
                <FormControl size="small">
                <Select
                    className="testDialogSelect"
                    name={`type${row.id}`}
                    defaultValue={row.type}
                    onChange={(e) => handleChangeParamType(row.id, e)}
                    style={{border: row.errors.includes('type') ? '1px solid #EF4444' : '1px solid var(--color-divider-nested)',}}
                >
                    <MenuItem value={TestParamsType.number}>Число</MenuItem>
                    <MenuItem value={TestParamsType.string}>Строка</MenuItem>
                    <MenuItem value={TestParamsType.boolean}>Да/нет</MenuItem>
                    <MenuItem value={TestParamsType.policy}>Политика</MenuItem>
                </Select>
                </FormControl>
                {row.errors.includes('type') && <p className="errorMessage">{errorMessage}</p>}
            </div>
            <div className="inputContainer">
                {getParameterValueField(row, row.id)}
            </div>
            <div className="deleteIcon" onClick={() => handleDeleteParamsRow(row.id)}>
                <i className="pi pi-trash" style={{ color: 'var(--text-secondary)' }} />
            </div>
        </div>
    );
};

export { ComponentItemTestParamRow };
