import {useEffect} from 'react';
import {matchRoutes, useLocation, useParams, useSearchParams} from 'react-router-dom';
import {useAppSelector} from '../../hook/store';
import {UserState} from '../../store/slice/user-slice';
import {AppTitleMeta, DocumentTitleItem, DocumentTitlePageType, DocumentTitleTail} from './app-title.meta';
import {SpecificationState} from '../../store/slice/spec-slice';
import {NodeState} from '../../store/slice/node-slice';
import {DictionaryTypeState} from '../../store/slice/dictionary-type-slice';
import {DictionaryState} from '../../store/slice/dictionary-slice';
import {SpecTypeState} from '../../store/slice/spec-type-slice';


const AppTitle = () => {

    const params = useParams();
    const location = useLocation();
    const [searchPrams, setSearchParams] = useSearchParams();

    const {currentSpec} = useAppSelector<SpecificationState>(store => store.spec);
    const {currentNodeItem} = useAppSelector<NodeState>(store => store.node);
    const {allDictionaryTypes} = useAppSelector<DictionaryTypeState>(store => store.dictionaryType);
    const {currentDictionary} = useAppSelector<DictionaryState>(store => store.dictionary);
    const {currentType} = useAppSelector<SpecTypeState>(state => state.specType);

    const {selectedUser} = useAppSelector<UserState>(state => state.user);


    const updateTitle = () => {
        const routes: any[] = Object.keys(AppTitleMeta).map(r => ({path: r}));
        const match = matchRoutes(routes, location);
        if (Array.isArray(match) && match.length) {
            const routePath = match[0].route.path
            const routeData = AppTitleMeta[routePath]
            if (!!routeData && routeData.params) {
                buildTitle(routeData);
            } else if (!!routeData) {
                const title = !!routeData && !!routeData.title ? `${routeData.title} - ${DocumentTitleTail}` : DocumentTitleTail;
                setTitle(title);
            } else {
                setTitle(DocumentTitleTail);
            }
        } else {
            setTitle(DocumentTitleTail);
        }
    };

    const buildSingleObjectTitle = (data: DocumentTitleItem, object: any): void => {
        if (!!object && !!data.params) {
            let title = '';
            for (const param of data.params) {
                if(object[param]){
                    title += `${object[param]} ` || '';
                }
            }
            title += data.title || '';
            title = !!title ?  `${title} - ${DocumentTitleTail}` : DocumentTitleTail;
            setTitle(title);
        }
    };

    const buildDictionaryTitle = (data: DocumentTitleItem):void => {
        if (!!allDictionaryTypes  && !!data.params) {
            const typeId = searchPrams.get('type') || params.type;

            if(!!typeId) {
                const type = allDictionaryTypes.find((t) => t.id === typeId);
                let title = type?.typeName || ''
                if(!!currentDictionary){
                    for (const param of data.params) {
                        if(param !== 'typeName'){
                            if((currentDictionary as any)[param]){
                                title += ` (${(currentDictionary as any)[param]}) ` || '';
                            }
                        }
                    }
                }
                title += data.title || '';
                title = !!title ?  `${title} - ${DocumentTitleTail}` : DocumentTitleTail;
                setTitle(title);
            }
        }
    };


    const buildTitle = (data: DocumentTitleItem) => {
        switch (data.type) {
            case DocumentTitlePageType.specification:
                buildSingleObjectTitle(data, currentSpec)
                break;
            case DocumentTitlePageType.component:
                buildSingleObjectTitle(data, currentNodeItem);
                break;
            case DocumentTitlePageType.dictionary:
                buildDictionaryTitle(data);
                break;
            case DocumentTitlePageType.configuration:
                buildSingleObjectTitle(data, currentType);
                break;
            case DocumentTitlePageType.user:
                buildSingleObjectTitle(data, selectedUser);
                break;

        }
    };

    const setTitle = (title: string): void => {
        document.title = title;
    };

    useEffect(() => {
        updateTitle()
    }, [
        params,
        location,
        searchPrams,
        currentSpec,
        currentNodeItem,
        allDictionaryTypes,
        currentDictionary,
        currentType,
        selectedUser
    ]);

    return (
        <></>
    );
};

export {AppTitle};
