import './component-item.scss';
import {ComponentItemHeader} from './component-item-header/component-item-header';
import {Outlet, useLocation, useMatch, useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {clearNodeItem, getNodeItem, NodeState} from '../../../store/slice/node-slice';
import {useAppDispatch, useAppSelector} from '../../../hook/store';
import {ApiNodeTypeSchema} from '../../../interface';
import {getAllNodeTypes, NodeTypeState} from '../../../store/slice/node-type-slice';
import {UISchemaElement} from '@jsonforms/core';
import {ComponentItemTabs} from './component-item-tabs/component-item-tabs';
import {ComponentItemTab, ComponentItemTabType} from './component-item.interface';
import LogHelper from '../../../helpers/log.helper';
import {componentItemTabs} from './component-item.meta';
import {ROUTE_PATH} from '../../../constants/routes';
import {cleanBreadcrumbs, setBreadcrumbs} from '../../../store/slice/utils.slice';


const ComponentItem = () => {
    const urlParams = useParams();
    const location = useLocation();
    const match = useMatch(`${ROUTE_PATH.newComponents}/:type/:id/:lastPart`);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {currentNodeItem} = useAppSelector<NodeState>(store => store.node);
    const {allNodeTypes} = useAppSelector<NodeTypeState>(store => store.nodeType);
    const [jsonSchema, setJsonSchema] = useState<ApiNodeTypeSchema>();
    const [uiSchema, setUiSchema] = useState<UISchemaElement | undefined>(undefined);
    const [nodeTypeData, setNodeTypeData] = useState<{ id: string, name: string }>();
    const [linkedAvailable, setLinkedAvailable] = useState(false);
    const [tabs, setTabs] = useState<ComponentItemTab[]>(componentItemTabs);
    const [selectedTab, setSelectedTab] = useState<ComponentItemTab>(tabs[0]);


    const handleSelectTab = (tab: ComponentItemTab) => {
        navigate(tab.path);
        // setSelectedTab(tab);
    };


    const buildTabs = (): void => {
        if (!currentNodeItem) {
            return;
        }
        const linksCount = Array.isArray(currentNodeItem.referencingNodes) ? currentNodeItem.referencingNodes.length : 0;
        const tabs = componentItemTabs.map((t) => {
            if (t.type === ComponentItemTabType.references) {
                return {
                    ...t,
                    title: `${t.title} (${linksCount})`
                };
            }
            return t;
        });
        setTabs(tabs);

    };

    useEffect(() => {
        if (urlParams?.id && urlParams.id !== currentNodeItem?.id.toString()) {
            setJsonSchema({} as ApiNodeTypeSchema);
            dispatch(clearNodeItem());
            dispatch(getNodeItem(urlParams.id));
        }
    }, [urlParams, dispatch]);

    useEffect(() => {
        if (!allNodeTypes) {
            dispatch(getAllNodeTypes());
        } else if (!!currentNodeItem) {
            const nodeType = allNodeTypes.find(n => n.id === currentNodeItem.type);
            if (nodeType) {
                LogHelper.validateJsonRequiredSchema(nodeType.typeSchema);
                setNodeTypeData({
                    id: nodeType.id,
                    name: nodeType.typeName
                });
                setJsonSchema(nodeType.typeSchema);
                // const generatedSchema = JsonFormsGenerateUISchema(nodeType.typeSchema, nodeType.typeUISchema);
                setUiSchema(nodeType.typeUISchema || undefined);
            }
            const linkedAvailable = Array.isArray(nodeType?.allowChildren) && !!nodeType?.allowChildren.length;
            setLinkedAvailable(linkedAvailable);
        }
        buildTabs();
    }, [currentNodeItem, allNodeTypes]);

    useEffect(() => {

        return () => {
            // clear node data on component unmount
            dispatch(clearNodeItem());
            dispatch(cleanBreadcrumbs());
        };
    }, [dispatch]);

    useEffect(() => {
        if(match?.params?.lastPart){
            const tab = tabs.find( t => t.path === match.params.lastPart) || tabs[0];
            setSelectedTab(tab);
        }else{
            setSelectedTab(tabs[0]);
        }
    }, [location]);

    useEffect(() => {
        if(nodeTypeData){
            dispatch(setBreadcrumbs([
                {
                    title: 'Компоненты',
                    path: `/${ROUTE_PATH.newComponents}`
                },
                {
                    title: nodeTypeData?.name || '',
                    path: `/${ROUTE_PATH.newComponents}/type?types=${nodeTypeData?.id || ''}`
                },
                {
                    title: currentNodeItem?.name || '',
                }

            ]));
        }
    }, [dispatch, nodeTypeData]);

    return <>
        <div className="component-item">

            <div className="component-item__header">
                <ComponentItemHeader item={currentNodeItem}></ComponentItemHeader>
            </div>
            <div className="component-item__tabs">
                <ComponentItemTabs tabs={tabs}
                                   selected={selectedTab}
                                   onSelect={handleSelectTab}/>
            </div>

            <div className="component-item__content">
                {!!currentNodeItem && <Outlet/>}
            </div>
        </div>
    </>;
};

export {ComponentItem as default};
