import {SortDescriptor} from '@progress/kendo-data-query';
import './specification-list.scss';
import {SpecificationListGrid} from './components/grid/specification-list-grid';
import {useEffect, useState} from 'react';
import {NewSpecificationDialog} from './components/new-specification-dialog/new-specification-dialog';
import {useAppDispatch, useAppSelector} from '../../hook/store';
import {getAllSpecTypes, SpecTypeState} from '../../store/slice/spec-type-slice';
import {SectionHeader} from '../../components/section-header/section-header';
import {SECTION_HEADER} from '../../constants';
import {useSearchParams} from 'react-router-dom';
import {Role, SearchParams} from '../../interface';
import {getSpecifications, SpecificationState} from '../../store/slice/spec-slice';
import {setSearchParamsTerm} from '../../helpers';
import {ColumnInterface} from '../../components';
import {specificationColumns} from './components/grid/specification-list.meta';
import {SpecInterface, SpecResultInterface} from '../../interface';
import {getUsers, UserState} from '../../store/slice/user-slice';
import {RoleState} from '../../store/slice/role-slice';
import {cleanBreadcrumbs, setBreadcrumbs} from '../../store/slice/utils.slice';

const SpecificationList = () => {
    const dispatch = useAppDispatch();
    const [newSpecDialog, setNewSpecDialog] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const {allSpecTypes} = useAppSelector<SpecTypeState>(state => state.specType);
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const [columns, setColumns] = useState<ColumnInterface[]>(specificationColumns);
    const {specList, total} = useAppSelector<SpecificationState>(store => store.spec);
    const [totalSpec, setTotalSpec] = useState<number>(total);
    const [data, setData] = useState<SpecInterface[]>(specList ?? []);
    const {currentUser} = useAppSelector<UserState>((store) => store.user);
    const {roleList} = useAppSelector<RoleState>((store) => store.role);

    useEffect(() => {
        dispatch(setBreadcrumbs([{title: 'Спецификации'}]))
        return () => {
            // clear node data on component unmount
            dispatch(cleanBreadcrumbs());
        };
    }, [dispatch]);

    useEffect(() => {
        if(currentUser && roleList){
            const role = roleList.find((r) => r.id === currentUser.role)
            if(role && role.name === Role.ROLE_ADMIN){
                dispatch(getUsers({}));
            }
        }
    }, [dispatch, currentUser, roleList]);

    useEffect(() => {
        if (!searchParams.get('limit')) {
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                offset: '0',
                limit: '50',
                showDraft: 'true'
            }, {replace: true});
        } else {
            dispatch(getSpecifications(Object.fromEntries(searchParams.entries())))
                .unwrap().then((d: SpecResultInterface) => {
                setData(d.result);
                setTotalSpec(d.total);
            });
        }

        const sortBy = searchParams.get('sortBy');
        const sortDir = searchParams.get('sortDirection') || undefined;
        if (!!sortBy && !!sortDir) {
            const sortField = sortBy === 'specTypeName' ? 'typeId' : sortBy;
            setSort([
                {
                    field: sortField,
                    dir: sortDir  as any
                }
            ]);
        } else {
            setSort([]);
        }

        const columnsParam = searchParams.get('columns');
        if (!columnsParam) {
            const selectedColumns = columns.filter(column => column.show).map(column => column.field).join(';');
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                'columns': selectedColumns,
            }, {replace: true});
        } else if (columnsParam) {
            const columnsParamsArr = columnsParam.split(';');
            const newColumns = columns.map(column => column.field && columnsParamsArr.includes(column.field) ? {
                ...column,
                show: true,
            } : {
                ...column,
                show: false,
            }).sort((a, b) => columnsParamsArr.indexOf(a.field!) - columnsParamsArr.indexOf(b.field!));
            setColumns(newColumns);
        }
    }, [searchParams]);

    useEffect(() => {
        if (!allSpecTypes) {
            dispatch(getAllSpecTypes());
        }
    }, [searchParams]);

    const toggleNewSpecDialog = () => {
        setNewSpecDialog(!newSpecDialog);
    };

    const handleSearchChange = (params: SearchParams): void => {
        setSearchParams({...params} as unknown as URLSearchParams, {replace: true});
    };

    const onSearch = (searchTerm: string): void => {
        handleSearchChange(setSearchParamsTerm(searchTerm, {...searchParams, offset: '0'}));
    };

    const resetFilter = (field: string) => {
        searchParams.delete(field);
        setSearchParams(searchParams);
    };



    const onColumnsChange = (newColumns: ColumnInterface[]) => {
        const columnsParam = searchParams.get('columns');
        let sortedColumns: ColumnInterface[] = [];
        let defaultCols: ColumnInterface[] = [];
        if(columnsParam) {
            const columnsParamsArr = columnsParam.split(';');
            const idCol = newColumns.find(col => col.field === 'id');
            if(idCol && !columnsParamsArr.includes('id')) {
                sortedColumns[0] = idCol;
                defaultCols = newColumns.filter(col => col.field !== 'id' && columnsParamsArr.includes(col.field!)).sort((a,b) => columnsParamsArr.indexOf(a.field!) - columnsParamsArr.indexOf(b.field!));
            } else if(idCol) {
                defaultCols = newColumns.filter(col => columnsParamsArr.includes(col.field!)).sort((a,b) => columnsParamsArr.indexOf(a.field!) - columnsParamsArr.indexOf(b.field!));
            }
            const others: ColumnInterface[] = newColumns.filter(col => defaultCols.every(c => c.field !== col.field)) || [];

            sortedColumns = [...sortedColumns, ...defaultCols, ...others];
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                'columns': sortedColumns.filter(col => col?.show).map(col => col?.field).join(';'),
            }, {replace: true});
        }
    };

    return (
        <div className="newConfigurator">
            <div className="newConfigurator__header">
                <SectionHeader
                    onCreate={toggleNewSpecDialog}
                    type={SECTION_HEADER.specifications}
                    onSearch={onSearch}
                    style={'inline'}
                />
            </div>
            <div className="newConfigurator__grid">
                {searchParams && (
                    <SpecificationListGrid 
                        searchParams={searchParams}
                        sort={sort}
                        columns={columns}
                        data={data}
                        totalSpec={totalSpec}
                        onChanges={handleSearchChange}
                        onColumnsChange={onColumnsChange}
                        resetFilter={resetFilter}
                    />
                )}
            </div>
            {newSpecDialog &&
                <NewSpecificationDialog handleClose={toggleNewSpecDialog}/>
            }
        </div>
    );
};

export {SpecificationList};
