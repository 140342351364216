import React, {ReactElement, useEffect, useState} from 'react';
import {GridHeaderCellProps} from '@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps';
import styles from './filters.module.scss';
import {FilterHeader} from './components/filter-header';
import {Popover} from '@mui/material';
import {GridColumnMenuItemContent, GridColumnMenuItemGroup} from '@progress/kendo-react-grid';
import {ReactComponent as IconFilter} from '../../../../assets/img/icon/filter.svg';
import {NODE_FILTER_TYPE} from '../../node-item/node-item.interface';
import {NodeFilterString} from './components/node-filter-string';
import {NodeFilterNumber} from './components/node-filter-number';
import {useSearchParams} from 'react-router-dom';
import {NodeFilterBoolean} from './components/node-filter-boolean';
import {ColumnInterface} from '../../../../components';

interface NodeFilterProps extends GridHeaderCellProps {
    column: ColumnInterface;
    onFilter: (field: string, filter: string) => void;
    onReset: (field: string) => void;
    ignoreSearchType?: boolean
}


const NodeFilter = (props: NodeFilterProps) => {
    const [searchParams] = useSearchParams();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [search, setSearch] = useState<string | null>(null);

    const handleClick = (event: React.MouseEvent<any>): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const getField = ():string => {
        if(props.column.columnType){
            return props.ignoreSearchType ?
                `d.${props.column.searchField}[${props.column.columnType?.join(';')}]` :
                `d.${props.column.searchField}.${getFilterMode()}[${props.column.columnType?.join(';')}]`;
        }
        return props.ignoreSearchType ?
            `${props.column.searchField}` :
            `${props.column.searchField}.${getFilterMode()}`;
    };

    const applyFilter = (e: React.MouseEvent<HTMLButtonElement>): void => {
        if(search){
            props.onFilter(getField(), search);
            handleClose();
        }
    };

    const resetFilter = (e: React.MouseEvent<HTMLButtonElement>): void => {
        props.onReset(getField());
        handleClose();
    };

    const filterForm = (): ReactElement<any> => {
        switch (props.column.type) {
            case NODE_FILTER_TYPE.string:
                return <NodeFilterString selected={search} onSearch={setSearch}/>;
            case NODE_FILTER_TYPE.number:
                return <NodeFilterNumber selected={search} onSearch={setSearch} isFloat={true}/>;
            case NODE_FILTER_TYPE.integer:
                return <NodeFilterNumber selected={search} onSearch={setSearch} isFloat={false}/>;
            case NODE_FILTER_TYPE.boolean:
                return <NodeFilterBoolean selected={search} onSearch={setSearch}/>;
        }

        return <></>;
    };

    const getFilterMode = () => {
        switch (props.column.type) {
            case NODE_FILTER_TYPE.string:
                return 'search';
            case NODE_FILTER_TYPE.number:
                return 'range';
            case NODE_FILTER_TYPE.integer:
                return 'range';
            case NODE_FILTER_TYPE.boolean:
                return 'search';
        }
    };

    useEffect(()=>{
        setSearch(searchParams.get(getField())  || '');
    }, [searchParams]);


    const open = Boolean(anchorEl);

    return (
        <div onClick={props.onClick}
             className={!!search ? `${styles.header} ${styles.filtered}` : styles.header}>
            <FilterHeader {...props} handleClick={handleClick}></FilterHeader>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={(e) => e.stopPropagation()}
            >
                <GridColumnMenuItemGroup>
                    <GridColumnMenuItemContent show={true}>
                        <div className="k-columnmenu-item">
                                    <span className="k-icon">
                                        <IconFilter/>
                                    </span>
                            Фильтр
                        </div>
                        {filterForm()}
                        <div className={styles.actions}>
                            <button
                                className={'k-button k-button-sm k-rounded-sm k-button-solid k-button-solid-primary'}
                                disabled={!search}
                                onClick={applyFilter}
                            >
                                Применить
                            </button>
                            <button
                                className={'k-button k-button-sm k-rounded-sm k-button-outline k-button-outline-primary'}
                                onClick={resetFilter}
                            >
                                Сбросить
                            </button>
                        </div>
                    </GridColumnMenuItemContent>
                </GridColumnMenuItemGroup>
            </Popover>

        </div>
    );
};

export {NodeFilter};
