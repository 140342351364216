import {UISchemaElement} from '@jsonforms/core';
import {SpecItem} from './spec.interface';

export interface SpecificationItemTabForm {
    key: string;
    schema: SpecificationItemTabFormSchema;
    uiSchema?: UISchemaElement;
    data?: { [key: string]: any };
}

export interface SpecificationItemTabFormSchema {
    properties: { [key: string]: any }
    required?: string[];
    type: string;
}

export interface SpecificationItemTab {
    type: 'form' | 'invoice';
    id: string;
    title: string;
    forms?: SpecificationItemTabForm[],
    required?: boolean;
    error?: boolean;
    filled?: boolean;
}

export interface SpecificationItemInterface {
    tabs: SpecificationItemTab[];
}

export interface SpecificationItemTabFormData {
    [key: string]: { [key: string]: any };
}

export enum SpecificationItemAction {
    'open' = 'Открыть',
    'rename' = 'Переименовать',
    'publish' = 'Опубликовать',
    'clone' = 'Дублировать',
    'delete' = 'Удалить',
    'archive' = 'Архивировать',
    'unarchive' = 'Вернуть из архива',
    'changeOwner' = 'Переназначить другому пользователю'
}

export type SpecificationItemActionListItem = {
    action: SpecificationItemAction,
    active: boolean
}

export interface SpecificationItemActionData {
    action?: SpecificationItemAction;
    item?: SpecItem
}


