import React, {PropsWithChildren, ReactNode, useEffect, useState} from 'react';
import {SpecificationItemHeader} from '../../components/header/specification-item-header';
import {useAppDispatch, useAppSelector} from '../../../../../hook/store';
import {
    clearCurrentSpec,
    clearSpecificationFormData,
    getSpecification,
    SpecificationState
} from '../../../../../store/slice/spec-slice';
import {useParams} from 'react-router-dom';
import {clearCurrentSpecTypeHelp, getSpecTypeHelp} from '../../../../../store/slice/spec-type-slice';
import {SpecItemValidationItemType} from '../../../../../interface';
import {SpecificationStatus} from '../data-input/specification-data-input.interface';
import {cleanBreadcrumbs, setBreadcrumbs} from '../../../../../store/slice/utils.slice';
import {ROUTE_PATH} from '../../../../../constants/routes';
import {AppBreadcrumb} from '../../../../../interface/breadcrumbs.interface';

interface SpecificationTabHeaderProps extends PropsWithChildren {
    children?: ReactNode | undefined
}

const SpecificationTabWrapper = ({children}: SpecificationTabHeaderProps) => {
    const urlParams = useParams();
    const dispatch = useAppDispatch();

    const [status, setStatus] = useState<SpecificationStatus>(SpecificationStatus.empty);

    const {currentSpec} = useAppSelector<SpecificationState>(store => store.spec);

    const handleActionUpdate = () => {
        if (urlParams.id) {
            dispatch(getSpecification(urlParams.id));
        }
    };

    const updateStatus = (): void => {
        if (Array.isArray(currentSpec?.doc.validation.messages)) {
            const errors = currentSpec?.doc.validation.messages.filter((m) => m.type === SpecItemValidationItemType.error);
            if (Array.isArray(errors) && errors.length) {
                setStatus(SpecificationStatus.error);
                return;
            }
        }
        if (!!currentSpec?.doc?.invoice?.isEmpty) {
            setStatus(SpecificationStatus.empty);
            return;
        }
        setStatus(SpecificationStatus.calculated);

    };


    useEffect(() => {

        if (urlParams?.id && urlParams.id !== currentSpec?.id) {
            dispatch(clearCurrentSpec());
            dispatch(clearSpecificationFormData());
            dispatch(clearCurrentSpecTypeHelp());
            dispatch(getSpecification(urlParams.id));
        }
    }, [dispatch, urlParams?.id]);


    useEffect(() => {
        const breadcrumbs: AppBreadcrumb[] = [{title: 'Спецификации', path: `/${ROUTE_PATH.specifications}`}]
        if (urlParams?.id && urlParams.id === currentSpec?.id) {
            breadcrumbs.push(
                {title: currentSpec.name, version: currentSpec.id}
            )
            dispatch(getSpecTypeHelp(currentSpec.typeId));
            updateStatus();
        }
        dispatch(setBreadcrumbs(breadcrumbs));
    }, [currentSpec]);

    useEffect(() => {
        return () => {
            // clear node data on component unmount
            dispatch(cleanBreadcrumbs());
        };
    }, [dispatch]);

    return (
        <>
            {!!currentSpec && (
                <>
                    <div className="specification-item">
                        <div className="header">
                            <SpecificationItemHeader
                                specificationItem={currentSpec}
                                onAuthorUpdate={handleActionUpdate}
                                status={status}
                            />
                        </div>
                        {children}
                    </div>
                </>
            )}
        </>
    );
};

export {SpecificationTabWrapper};
