import {FormControl, FormHelperText, TextField} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface NodeFilterNumberProps {
    selected?: string | null,
    onSearch: (value: string | null) => void
    isFloat: boolean
}

const NodeFilterNumber = (props: NodeFilterNumberProps) => {

    const [search, setSearch] = useState<{ rangeStart: string, rangeEnd: string }>({rangeStart: '', rangeEnd: ''});
    const [showForm, setShowForm] = useState<boolean>(false);

    const fieldValidateSchema = props.isFloat
        ? yup.number()
            .required('Обязательное поле')
            .min(0, 'Введите 0 или положительное число')
            .typeError('Обязательное поле')
        : yup.number().required('Обязательное поле')
            .min(0, 'Введите 0 или положительное число')
            .integer('Введите целое число')
            .typeError('Обязательное поле');

    const validateSchema = yup.object({
        from: fieldValidateSchema,
        to: fieldValidateSchema.moreThan(yup.ref('from'), 'Значение должно быть больше \'OT\''),
    }).required('Обязательное поле');

    const {
        register,
        trigger,
        formState: {errors},
    } = useForm({
        mode: 'all',
        resolver: yupResolver(validateSchema)
    });


    const onRangeStartChange = async(e: any) => {
        const valid = await trigger();
        setSearch({...search, rangeStart: e.target.value || ''});
        if (!!e.target.value && !!search.rangeEnd && valid) {
            props.onSearch(`${e.target.value};${search.rangeEnd}`);
        } else {
            props.onSearch(null);
        }
    };

    const onRangeEndChange = async(e: any) => {
        const valid = await trigger();
        setSearch({...search, rangeEnd: e.target.value || ''});
        if (!!e.target.value && !!search.rangeStart && valid) {
            props.onSearch(`${search.rangeStart};${e.target.value}`);
        } else {
            props.onSearch(null);
        }
    };

    const validate = async () => {
      const valid = await trigger();
      if(!valid){
          props.onSearch(null);
      }
    };

    useEffect(() => {
        if (!!props.selected) {
            const parts = props.selected.split(';');
            setSearch({
                rangeStart: parts[0] || '',
                rangeEnd: parts[1] || ''
            });
        }
        setShowForm(true);
    }, [props.selected]);

    return (
        <>
            {showForm && (
                <form>
                    <div style={{padding: '1em'}}>
                        <div>
                            <FormControl error={!!errors?.from} sx={{width: '100%'}}>
                                <TextField
                                    type={'number'}
                                    inputProps={{
                                        step: props.isFloat ? '.01' : '1'
                                    }}
                                    style={{width: '100%'}}
                                    className={'search-field'}
                                    label="от"
                                    variant="outlined"
                                    defaultValue={search?.rangeStart || ''}
                                    size={'small'}
                                    required
                                    error={!!errors?.from}
                                    {...register('from', {
                                        required: 'Обязательное поле',
                                        ...search?.rangeStart && {'value': search?.rangeStart},
                                        onChange: (e) =>  onRangeStartChange(e),
                                        onBlur: (e) => validate()
                                    })}

                                />
                            </FormControl>
                            <FormHelperText>{errors?.from ? errors.from.message as string : ''}</FormHelperText>
                        </div>
                        <div style={{paddingTop: '1em'}}>
                            <FormControl error={!!errors?.to} sx={{width: '100%'}}>
                                <TextField
                                    type={'number'}
                                    inputMode={'numeric'}
                                    inputProps={{
                                        step: props.isFloat ? '.01' : '1'
                                    }}
                                    style={{width: '100%'}}
                                    className={'search-field'}
                                    label="до"
                                    variant="outlined"
                                    defaultValue={search?.rangeEnd || ''}
                                    size={'small'}
                                    required
                                    error={!!errors?.to}
                                    {...register('to', {
                                        required: 'Обязательное поле',
                                        ...search?.rangeEnd && {'value': search?.rangeEnd},
                                        onChange: (e) =>  onRangeEndChange(e),
                                        onBlur: (e) => validate()
                                    })}
                                />
                            </FormControl>
                            <FormHelperText>{errors?.to ? errors.to.message as string : ''}</FormHelperText>
                        </div>
                    </div>
                </form>
            )}
        </>
    );
};

export {NodeFilterNumber};
