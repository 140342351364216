import {RuleEffect} from '@jsonforms/core';

export const ComponentVariants = {
    library: 'Компонент из библиотеки',
    oneOf: 'Один из нескольких вариантов',
    any: 'Любой вариант',
}

export const componentItemDialogUiSchema = {

    type: 'VerticalLayout',
    elements: [
        {
            type: 'Control',
            label: 'ID',
            scope: '#/properties/name'
        },
        {
            type: 'Control',
            label: 'Способ выбора',
            scope: '#/properties/variant',
            options: {
                format: 'radio'
            }
        },
        {
            type: 'VerticalLayout',
            elements: [
                {
                    type: 'Control',
                    label: 'Тип*',
                    scope: '#/properties/type',
                },
                {
                    type: 'Control',
                    label: 'Компонент*',
                    scope: '#/properties/linkedNodeId',
                    options: {
                        autocomplete: true,
                        dependentAutocomplete: true,
                        path: '/api/node',
                        dataMap: {
                            title: 'name',
                            value: 'id'
                        },
                    },
                    rule: {
                        effect: RuleEffect.DISABLE,
                        condition: {
                            scope: '#/properties/type',
                            schema: {
                                not: {
                                    type: 'string'
                                }
                            }
                        }
                    }
                },
                {
                    type: 'Control',
                    label: 'Количество',
                    scope: '#/properties/value',
                    rule: {
                        effect: RuleEffect.DISABLE,
                        condition: {
                            scope: '#/properties/type',
                            schema: {
                                not: {
                                    type: 'string'
                                }
                            }

                        }
                    }
                }
            ],
            rule: {
                effect: RuleEffect.HIDE,
                condition: {
                    scope: '#/properties/variant',
                    schema: {
                        enum: [
                            ComponentVariants.oneOf,
                            ComponentVariants.any]
                    }
                }
            },
        },

        {
            type: 'VerticalLayout',
            elements: [
                {
                    type: 'Control',
                    label: 'Название*',
                    scope: '#/properties/variantName'
                },
                {
                    type: 'Control',
                    label: 'Тип(ы)*',
                    scope: '#/properties/variantTypes',
                    options: {
                        autocomplete: true,
                        autocompleteMultiple: true
                    }
                },
                {
                    type: 'Control',
                    label: 'Варианты компонентов*',
                    scope: '#/properties/variantNodeIds',
                    options: {
                        async: true,
                        autocompleteMultiple: true,
                    },
                },
                {
                    type: 'Control',
                    label: 'Количество',
                    scope: '#/properties/value',
                }
            ],
            rule: {
                effect: RuleEffect.HIDE,
                condition: {
                    scope: '#/properties/variant',
                    schema: {
                        enum: [ComponentVariants.library, ComponentVariants.any]
                    }
                }
            },
        },
        {
            type: 'VerticalLayout',
            elements: [
                {
                    type: 'Control',
                    label: 'Название*',
                    scope: '#/properties/variantName'
                },
                {
                    type: 'Control',
                    label: 'Тип(ы)*',
                    scope: '#/properties/variantTypes',
                    options: {
                        autocomplete: true,
                        autocompleteMultiple: true
                    }
                },
                {
                    type: 'Control',
                    label: 'Количество',
                    scope: '#/properties/value',
                    rule: {
                        effect: RuleEffect.DISABLE,
                        condition: {
                            scope: '#/properties/variantTypes',
                            schema: {
                                const: []
                            }

                        }
                    }
                }
            ],
            rule: {
                effect: RuleEffect.HIDE,
                condition: {
                    scope: '#/properties/variant',
                    schema: {
                        enum: [ComponentVariants.library, ComponentVariants.oneOf]
                    }
                }
            },
        }
    ]
};

