
export interface ApiUserList {
    result: ApiUser[],
    total: number
}

export interface ApiUser {
    id: number;
    username: string;
    fullName: string;
    role: number;
    roles: number[];
}

export interface ApiUserCreate{
    username: string;
    role: number;
    fullName: string;
    roles: number[];
}

export interface ApiUserUpdate extends ApiUserCreate{
    id: number;
}

export interface ApiUserPasswordUpdate {
    id: number;
    password: string;
}
export enum UserAction {
    edit = 'Редактировать',
    delete = 'Удалить',
}
