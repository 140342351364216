import {
    extendDataItem,
    mapTree,
    TreeList, TreeListCellProps,
    TreeListColumnProps,
    TreeListExpandChangeEvent
} from '@progress/kendo-react-treelist';
import React, {ReactElement, useEffect, useState} from 'react';

import {CalculationCustomCell} from './calculation-custom-cell';
import {
    ApiNodeType,
    SpecItemDocModel, SpecItemDocModelCalculated, SpecItemDocModelCalculatedLinks,
    SpecItemDocModelItem
} from '../../../../../interface';
import {useAppDispatch, useAppSelector} from '../../../../../hook/store';
import {getAllNodeTypes, NodeTypeState} from '../../../../../store/slice/node-type-slice';
import './specification-item-calculation.scss';
import {CardBody} from '@progress/kendo-react-layout';
import {Button} from '@mui/material';
import {
    SpecificationItemCalculationTable
} from './specification-item-calculation-table/specification-item-calculation-table';
import {ExpandLess} from '@mui/icons-material';
import {StickyDownloadButton} from '../../../../../ui/sticky-download-button/sticky-download-button';
import {ReactComponent as DownloadIcon} from '../../../../../assets/img/icon/download.svg';

interface TreeViewDataItem {
    id: string;
    componentName: string;
    quantity: number;
    type?: ApiNodeType;
    item?: SpecItemDocModelItem;
    expanded?: boolean;
    items?: TreeViewDataItem[];

}

interface SpecificationItemCalculationProps {
    specId?: string;
    model?: SpecItemDocModel;
    type: string;
    isDocuments?: boolean;
}

const SpecificationItemCalculation = ({specId, model, type, isDocuments}: SpecificationItemCalculationProps) => {
    const dispatch = useAppDispatch();
    const [expanded, setExpanded] = useState<number[]>([]);
    const [tree, setTree] = useState<TreeViewDataItem[]>([]);
    const {allNodeTypes} = useAppSelector<NodeTypeState>(store => store.nodeType);
    const expandField = 'expanded';
    const subItemsField: string = 'items';

    const updateTree = (): void => {
        if (!model || model.empty || !allNodeTypes || !allNodeTypes.length) {
            return;
        }
        const tree: TreeViewDataItem[] = buildTree(model.calculated);
        setTree(tree);
    };

    const buildTree = (node: SpecItemDocModelCalculated): TreeViewDataItem[] => {
        if (!node?.links || !allNodeTypes) {
            return [];
        }
        const tree: TreeViewDataItem[] = [];

        for (const item of Object.values(node.links)) {
            const specType = allNodeTypes.find(type => `${type.code}_${type.version}` === item.typeId);

            tree.push({
                id: item.id,
                componentName: item.node.name,
                type: specType,
                quantity: item.quantity || 0,
                item: item,
                items: buildTree(item)
            });
        }
        return tree;
    };

    const infoCell = (props: TreeListCellProps): ReactElement => {
        return <CalculationCustomCell {...props}/>;
    };

    const columns: TreeListColumnProps[] = [
        {
            field: 'componentName',
            title: 'Компонент',
            width: '60%',
            expandable: true,
            cell: infoCell
        },
        {
            field: 'type',
            title: 'Количество (шт)',
            width: '40%',
            cell: (props: TreeListCellProps) => {
                return <td>
                    <div className="type-cell" title={props.dataItem?.type?.typeName}>
                        <div className="type-cell__wrap">
                            <span>{props.dataItem?.quantity || 0}</span>
                        </div>
                    </div>
                </td>;
            }
        }
    ];


    const processData = (): any[] => {
        const exp: number[] = expanded;
        return mapTree(tree, subItemsField, (item) =>
            extendDataItem(item, subItemsField, {
                [expandField]: exp.includes(item.id),
            })
        );
    };

    const onExpandChange = (e: TreeListExpandChangeEvent): void => {
        setExpanded(e.value
            ? expanded.filter((id) => id !== e.dataItem.id)
            : [...expanded, e.dataItem.id]);
    };

    const isDefault = (): boolean => {
        return type === 'default' && !modelIsEmpty();
    };

    const modelIsEmpty = (): boolean => {
        return !model || model.empty;
    };

    const isSectionAvailable = (): boolean => {
        return type !== 'default' && !!model?.sections && !!model.sections[type];
    };

    useEffect(() => {
        if (!allNodeTypes) {
            dispatch(getAllNodeTypes());
        }
    }, [dispatch, allNodeTypes]);


    useEffect(() => {
            updateTree();
        }, [allNodeTypes, model]
    );

    return <>

        <div className={['specification-item-calculation', modelIsEmpty() ? 'empty' : ''].join(' ')}>
            {modelIsEmpty() ? (
                    <div className="empty-result">
                        {isDocuments ? (
                            <>Документация недоступна,<br/></>
                        ) : (
                            <>Схема расчета недоступна,<br/></>
                        )}
                        обновите параметры спецификации.
                    </div>
                ) :
                (
                    <>
                        {isDefault() && (
                            <>
                                <div style={{maxWidth: 1000}}>
                                    <TreeList
                                        columns={columns}
                                        data={processData()}
                                        expandField={expandField}
                                        subItemsField={subItemsField}
                                        onExpandChange={onExpandChange}
                                    />
                                </div>
                                <div style={{margin: '-12px -8px -12px 40px'}}>
                                    <StickyDownloadButton>
                                        <Button variant="outlined"
                                                startIcon={<DownloadIcon/>}
                                                href={`/api/spec/${specId}/command/export/excel?schema=true`}
                                                download>
                                            Скачать...
                                        </Button>
                                    </StickyDownloadButton>
                                </div>
                            </>
                        )}
                        {isSectionAvailable() && (
                            <div className="specification-item-calculation__content">
                                <SpecificationItemCalculationTable model={model!} type={type}/>
                            </div>
                        )}
                    </>
                )
            }
        </div>
    </>;
};

export {SpecificationItemCalculation};
