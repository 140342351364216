import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    ApiChangeCurrencyRateRequest,
    SearchParamsWithArchive, SpecChangeStatusRequest,
    SpecCommentRequest,
    SpecCreateInterface,
    SpecFormsRequest, SpecificationItemInterface, SpecificationItemTabFormData,
    SpecInterface,
    SpecItem, SpecItemDoc,
    SpecItemStatus,
    SpecRenameRequest,
    SpecResultInterface, SpecStatusCommand
} from '../../interface';
import baseRequest from '../../utils/base-request';
import {showSuccess} from './toast-slice';


export interface SpecificationStateFormData{
    id: string,
    data: SpecificationItemInterface
}


export interface SpecificationState {
    specList: SpecInterface[] | null;
    currentSpec: SpecItem | null,
    total: number;
    specificationFormData: SpecificationStateFormData | null;
}


export const getSpecifications = createAsyncThunk(
    'spec/getSpecifications',
    async function (params: SearchParamsWithArchive, {dispatch}) {

        const response = await baseRequest({
            method: 'get',
            url: '/api/spec2',
            params
        }, dispatch);
        return response.data;

    }
);

export const addSpecifications = createAsyncThunk(
    'spec/addSpecifications',
    async function (data: SpecCreateInterface, {dispatch}) {

        const response = await baseRequest({
            method: 'post',
            url: '/api/spec',
            data
        }, dispatch);
        if (response.status === 200) {
            dispatch(showSuccess('Спецификация создана.'));
            return response.data;
        }
    }
);


export const updateComment = createAsyncThunk(
    'spec/updateComment',
    async function (data: SpecCommentRequest, {dispatch}) {

        const response = await baseRequest({
            method: 'put',
            url: `/api/spec/${data.id}/comment`,
            data: data.data
        }, dispatch);
        if (response.status === 200) {
            dispatch(showSuccess('Комментарий обновлен.'));
            return data.data.comment;
        }
    }
);

export const getSpecification = createAsyncThunk(
    'spec/getSpecification',
    async function (id: string, {dispatch}) {

        const response = await baseRequest({
            method: 'get',
            url: `/api/spec/${id}`
        }, dispatch);
        return response.data;

    }
);

export const updateSpecificationForms = createAsyncThunk(
    'spec/updateSpecificationForms',
    async function (data: SpecFormsRequest, {dispatch}) {
        const response = await baseRequest({
            method: 'put',
            url: `/api/spec/${data.id}/forms`,
            data: {forms: data.forms}
        }, dispatch);
        if (response.status === 200) {
            dispatch(showSuccess('Данные обновлены.'));
        }
        return response.data;
    }
);


export const renameSpecification = createAsyncThunk(
    'spec/renameSpecification',
    async function (data: SpecRenameRequest, {dispatch}) {
        await baseRequest({
            method: 'put',
            url: `/api/spec/${data.id}/command/rename`,
            data: data.data
        }, dispatch);
        dispatch(showSuccess('Имя спецификации изменено.'));
        return data;
    }
);


export const changeSpecificationStatus = createAsyncThunk(
    'spec/changeSpecificationStatus',
    async function (data: SpecChangeStatusRequest, {dispatch}) {

        await baseRequest({
            method: 'put',
            url: `/api/spec/${data.id}/command/${data.command}`,
        }, dispatch);
        return data;
    }
);

export const deleteSpecification = createAsyncThunk(
    'spec/deleteSpecification',
    async function (id: string, {dispatch}) {

        await baseRequest({
            method: 'delete',
            url: `/api/spec/${id}/command/delete`,
        }, dispatch);
        return id;

    }
);

export const cloneSpecification = createAsyncThunk(
    'spec/cloneSpecification',
    async function (id: string, {dispatch}) {

        const response = await baseRequest({
            method: 'post',
            url: `/api/spec/${id}/command/clone`,
        }, dispatch);
        return response.data;

    }
);


export const updateCurrencyRate = createAsyncThunk(
    'spec/updateCurrencyRate',
    async function (data: ApiChangeCurrencyRateRequest, {dispatch}) {
        const response = await baseRequest({
            method: 'post',
            url: `/api/spec/${data.id}/primaryCurrencyExchangeRate`,
            data: data.data
        }, dispatch);
        return response.data;
    }
);

export const changeAuthor = createAsyncThunk(
    'spec/changeAuthor',
    async function (data:{id: string, authorId: number}, {dispatch}) {
        const response = await baseRequest({
            method: 'put',
            url: `/api/spec/${data.id}/author`,
            data: {
                authorId: data.authorId
            }
        }, dispatch);
        return response.data;
    }
);


const initialState: SpecificationState = {
    specList: null,
    currentSpec: null,
    specificationFormData: null,
    total: 0
};

const specSlice = createSlice({
    name: 'spec',
    initialState,
    reducers: {
        clearCurrentSpec(state: SpecificationState) {
            state.currentSpec = {} as SpecItem;
        },
        clearSpecList(state: SpecificationState) {
            state.specList = null;
        },
        setSpecificationFormData(state: SpecificationState, action: PayloadAction<SpecificationStateFormData>) {
            state.specificationFormData = action.payload;
        },
        clearSpecificationFormData(state: SpecificationState) {
            state.specificationFormData = null;
        },
        resetSpecState() {
            return initialState;
        },
        changeSpecAuthor( state, action: PayloadAction<{specId: string, authorName: string}>){
            if(Array.isArray(state.specList)){
                state.specList = state.specList?.map((spec) => {
                    if( spec.id === action.payload.specId){
                        return {
                            ...spec,
                            author: action.payload.authorName
                        };
                    }
                    return spec;
                });
            }

        }

    },
    extraReducers: {
        [getSpecifications.fulfilled as any]: (state: SpecificationState, action: PayloadAction<SpecResultInterface>) => {
            if (action.payload?.result) {
                state.specList = action.payload.result;
                state.total = action.payload.total;
            }
        },
        [getSpecification.fulfilled as any]: (state: SpecificationState, action: PayloadAction<SpecItem>) => {
            state.currentSpec = action.payload;
        },
        [updateComment.fulfilled as any]: (state: SpecificationState, action: PayloadAction<string>) => {
            if (state.currentSpec) {
                state.currentSpec = {
                    ...state.currentSpec,
                    comment: action.payload
                };
            }
        },
        [updateSpecificationForms.fulfilled as any]: (state: SpecificationState, action: PayloadAction<any>) => {
            state.currentSpec = {
                ...state.currentSpec,
                ...action.payload
            };
        },
        [renameSpecification.fulfilled as any]: (state: SpecificationState, action: PayloadAction<SpecRenameRequest>) => {
            if (state.currentSpec) {
                state.currentSpec = {
                    ...state.currentSpec,
                    name: action.payload.data.name
                };
            }
            const spec = state.specList?.find(item => item.id === action.payload.id);
            if (spec) {
                spec.name = action.payload.data.name;
            }
        },
        [changeSpecificationStatus.fulfilled as any]: (state: SpecificationState, action: PayloadAction<SpecChangeStatusRequest>) => {
            let status;
            switch (action.payload.command) {
                case SpecStatusCommand.publish:
                    status = SpecItemStatus.published;
                    break;
                case SpecStatusCommand.archive:
                    status = SpecItemStatus.archive;
                    break;
                case SpecStatusCommand.unarchive:
                    status = SpecItemStatus.published;
                    break;
            }
            if (status) {
                if (state.currentSpec) {
                    state.currentSpec = {
                        ...state.currentSpec,
                        status: status
                    };
                }
                const spec = state.specList?.find(item => item.id === action.payload.id);
                if (spec) {
                    spec.status = status;
                }
            }
        },
        [deleteSpecification.fulfilled as any]: (state: SpecificationState, action: PayloadAction<string>) => {
            if (state.specList) {
                state.specList = state.specList.filter(item => item.id !== action.payload);
            }
        },
        [updateCurrencyRate.fulfilled as any]: (state: SpecificationState, action: PayloadAction<Partial<SpecItem>>) => {
            if (state.currentSpec) {
                state.currentSpec.doc = action.payload.doc as SpecItemDoc;
            }
        },
    }
});

export const {clearCurrentSpec, clearSpecList, resetSpecState, changeSpecAuthor, setSpecificationFormData, clearSpecificationFormData} = specSlice.actions;
export default specSlice.reducer;
