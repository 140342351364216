const fontFamily = "Mulish, sans-serif";

export const font = {
    h1Medium: {
        fontFamily,
        fontWeight: 500,
        fontSize: "22px",
        lineHeight: "32px",
    },
    h2Medium: {
        fontFamily,
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "30px",
    },
    body1SemiBold: {
        fontFamily,
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "26px",
    },
    body2Medium: {
        fontFamily,
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "26px",
    },
    body3Regular: {
        fontFamily,
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
    },
    body4Medium: {
        fontFamily,
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
    },
    body5Medium: {
        fontFamily,
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "20px",
    },
    body6Medium: {
        fontFamily,
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "18px",
    },
};
