import {AppLoader} from '../app-loader/app-loader';
import {PropsWithChildren, useState} from 'react';
import {Fade} from '@progress/kendo-react-animation';
import styles from './app-suspense.module.scss';

interface AppSuspenseProps extends PropsWithChildren {
    condition: boolean;
}

const AppSuspense = ({condition, children}: AppSuspenseProps) => {
    const [entered, setEntered] = useState<boolean>(false);

    if (condition) {
        return <>
            <Fade appear
                  onEntered={() => setEntered(true)}
                  className={`${styles.app_fade}  ${entered ? styles.entered : ''}`} style={{height: '100%'}}>
                {children}
            </Fade>
        </>;
    }

    return <AppLoader/>;
};

export {AppSuspense};
