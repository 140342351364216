import { TreeList, TreeListCellProps, TreeListExpandChangeEvent, extendDataItem, mapTree } from '@progress/kendo-react-treelist';
import {ReactComponent as ReloadIcon} from '../../../../assets/img/icon/reload.svg';
import './component-item-testing-results.scss';
import { CalculationCustomCell } from '../../../specification/specification-item/components/calculation/calculation-custom-cell';
import {getAllNodeTypes, NodeTypeState} from '../../../../store/slice/node-type-slice';
import { ReactElement, useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { ApiNodeType, ApiRecalculationResultProps, TestingParamsProps } from '../../../../interface';
import { NodeState } from '../../../../store/slice/node-slice';
import { useAppSelector, useAppDispatch } from '../../../../hook/store';

interface ComponentItemTestingResultsProps {
  testParams: TestingParamsProps[];
  testingErrors: string[];
  hasTestingError: boolean;
  toggleTesting: () => void;
  handleEditParams: (val: boolean) => void;
}

interface TreeViewDataItem {
  id: string;
  componentName: string;
  quantity: number;
  type?: ApiNodeType;
  item?: any;
  expanded?: boolean;
  items?: TreeViewDataItem[];
}

const ComponentItemTestingResults = ({ testParams, testingErrors, hasTestingError, toggleTesting, handleEditParams }: ComponentItemTestingResultsProps) => {
    const dispatch = useAppDispatch();
    const subItemsField: string = 'items';
    const [expanded, setExpanded] = useState<number[]>([]);
    const [tree, setTree] = useState<TreeViewDataItem[]>([]);
    const expandField = 'expanded';
    const {recalculationResult} = useAppSelector<NodeState>(store => store.node);
    const {allNodeTypes} = useAppSelector<NodeTypeState>(store => store.nodeType);
    const [noParam, setNoParam] = useState<boolean>(false);

    useEffect(() => {
      const params = [...testParams];
      const isEmpty = params.every(param => !param.parameter.trim());
      setNoParam(isEmpty);
    }, [testParams])

    const infoCell = (props: TreeListCellProps): ReactElement => {
      return <CalculationCustomCell {...props} />;
    };

    const paramsColumns: string[] = ['Параметр', 'Тип', 'Значение'];

    const columns = [
      {
        field: 'componentName',
        title: 'Компонент',
        width: '70%',
        expandable: true,
        cell: infoCell,
      },
      {
        field: 'type',
        title: 'Количество (шт)',
        width: '30%',
        cell: (props: TreeListCellProps) => {
          return (
            <td>
              <div className="type-cell" title={props.dataItem?.type?.typeName}>
                <div className="type-cell__wrap">
                  <span>{props.dataItem.quantity || 0} шт.</span>
                </div>
              </div>
            </td>
          );
        },
      },
    ];

    const buildTree = (node: ApiRecalculationResultProps): TreeViewDataItem[] => {
        let tree: TreeViewDataItem[] = []
        if (!node.links || !allNodeTypes) return []
        for (const item of Object.values(node.links)) {
          const type = allNodeTypes?.find(type => type.id === item.typeId);
            
              tree.push({
                id: item.id,
                componentName: item.name,
                type: type,
                quantity: item.quantity,
                item: item,
                items: buildTree(item),
              });
        }

        return tree;
    };

    const updateTree = (): void => {
        if(!recalculationResult) return;
        const dataTree: TreeViewDataItem[] = [];
        const tree: TreeViewDataItem[] = buildTree(recalculationResult);
        const type = allNodeTypes?.find(type => type.id === recalculationResult.typeId);
        dataTree.push({
          id: recalculationResult.id,
          componentName: recalculationResult.name,
          type: type,
          quantity: recalculationResult.quantity,
          item: recalculationResult,
          items: [...tree],
        });
        setTree(dataTree || []);
    };

    useEffect(() => {
      if (!allNodeTypes) {
          dispatch(getAllNodeTypes());
      }
    }, [dispatch, allNodeTypes]);

    useEffect(() => {
      updateTree();
    }, [recalculationResult]);

    const processData = (): any => {
      const exp: number[] = expanded;
      return mapTree(tree, subItemsField, (item) =>
        extendDataItem(item, subItemsField, {
          [expandField]: exp.includes(item.id),
        })
      );
    };

     const onExpandChange = (e: TreeListExpandChangeEvent): void => {
       setExpanded(
         e.value
           ? expanded.filter((id) => id !== e.dataItem.id)
           : [...expanded, e.dataItem.id]
       );
     };
     

    return (
      <div className="testingResultsContainer">
        <div className="headerContaier">
            <h3>Результаты проверки</h3>
            <ReloadIcon onClick={toggleTesting} />
        </div>
        <div className="resultsContentWrapper">
            <div className="testAnswer">
                {!hasTestingError ? (
                    <TreeList
                        columns={columns}
                        data={processData()}
                        expandField={expandField}
                        subItemsField={subItemsField}
                        onExpandChange={onExpandChange}
                    />
                ) : (
                    <div className="errorWrapper">
                        <div className="errorLine">
                            <p>Проверка выполнена с ошибкой, проверьте параметры и исправьте их</p>
                        </div>
                        <div className="errorContent">
                            <ul>
                              {testingErrors.map(error => (
                                 <li>{error}</li>
                              ))}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
            <div className="testingParams">
                <h3>Тестовые параметры</h3>
                <div className="params">
                    <div className="paramsHeader">
                        {paramsColumns.map((paramsColumn, i) => (
                            <span key={i}>{paramsColumn}</span>
                        ))}
                    </div>
                    <div className="paramsValues">
                        {noParam && (
                          <div className="emptyParams">Список пуст.</div>
                        )}
                        {!noParam && testParams.map((testParam, i) => (
                            <div className="paramsRow" key={i}>
                                <span>{testParam.parameter}</span>
                                <span>{testParam.type}</span>
                                <span>{typeof testParam.value === 'object' ? testParam.value.name : testParam.value}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <Button
                    variant={'outlined'}
                    className="editButton"
                    onClick={() => handleEditParams(true)}
                >
                    Редактировать параметры
                </Button>
            </div>
        </div>
      </div>
    );
};

export { ComponentItemTestingResults };