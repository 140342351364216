import {Card, CardBody, CardTitle} from '@progress/kendo-react-layout';
import {Button} from '@mui/material';
import {AppPrompt} from '../../../../components/app-prompt/app-prompt';
import React, {useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {showSuccess} from '../../../../store/slice/toast-slice';
import {useAppDispatch} from '../../../../hook/store';
import styles from '../../settings.module.scss';
import {AppSuspense} from '../../../../components/app-suspense/app-suspense';
import {useNavigate} from 'react-router-dom';

const SettingsSystem = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [inProgress, setInProgress] = useState<boolean>(false);

    const handleConfirmClose = () => {
        setShowConfirm(false);
    };

    const handleConfirm = () => {
        setShowConfirm(false);
        setInProgress(true);
        const refreshSystem = async () => {
            await fetch('/api/system/refresh', {
                method: 'POST'
            });
        };

        refreshSystem()
            .then(() => {
                dispatch(showSuccess('Перезагрузка конфигурации завершена'));
            })
            .finally(() => setInProgress(false));

    };

    return (
        <>

            <Card className={styles.settingsCard}>
                <CardTitle className={styles.settingsTitle}>Система</CardTitle>
                <CardBody className={styles.settingsBody}>
                    <AppSuspense condition={true}>
                        <div className={styles.row}>
                            <div>Общие настройки</div>
                            <div>
                                <Button
                                    variant={'contained'}
                                    className={styles.settingsBtn}
                                    onClick={() => navigate('system')}
                                >Изменить</Button>
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div>Перезагрузить конфигурацию</div>
                            <div>
                                <LoadingButton
                                    variant={'contained'}
                                    className={styles.settingsBtn}
                                    onClick={() => setShowConfirm(true)}
                                    disabled={inProgress}
                                    loading={inProgress}
                                >Выполнить</LoadingButton>
                            </div>
                        </div>
                    </AppSuspense>
                </CardBody>
            </Card>
            {showConfirm && (
                <AppPrompt data={{
                    title: 'Подтвердите действие',
                    message: ['Подтвердите перезагрузку конфигурации библиотек.'],
                    confirmButton: 'Подтвердить',
                    declineButton: 'Отменить'
                }} onClose={handleConfirmClose} onConfirm={handleConfirm}/>
            )}
        </>
    );
};

export
{
    SettingsSystem
};
