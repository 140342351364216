import React, {lazy} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {Login} from './pages/login/login';
import {NotFound} from './pages/not-found';
import {Settings} from './pages/settings/settings';
import {CabinetLayout} from './components/layouts/cabinet/cabinet-layout';

import {ProtectedRoute} from './hoc/protected-route/protected-route';
import {SettingsSystemCommon} from './pages/settings/sections/system/settings-system-common';
import {SettingsUserFullname} from './pages/settings/sections/user/settings-user-fullname';
import {SettingsUserPassword} from './pages/settings/sections/user/settings-user-password';
import ComponentItem from './pages/component/component-item/component-item';
import ComponentItemsForm from './pages/component/component-item/component-item-form/component-items-form';
import ComponentItemProps from './pages/component/component-item/component-item-props/component-item-props';
import ComponentItemRefs from './pages/component/component-item/component-item-refs/component-item-refs';
import ComponentItemHistory from './pages/component/component-item/component-item-history/component-item-history';
import DictionaryItemHistory from './pages/dictionary/dictionary-item/dictionary-item-history/dictionary-item-history';
import DictionaryItemProps from './pages/dictionary/dictionary-item/dictionary-item-props/dictionary-item-props';
import {NodeItem} from './pages/component/node-item/node-item';
import {ROUTE_PATH} from './constants/routes';
import {createTheme, ThemeProvider} from '@mui/material';
import {muiTheme} from './App.meta';
import {
    SpecificationDataInput
} from './pages/specification/specification-item/tabs/data-input/specification-data-input';
import {
    SpecificationCalculation
} from './pages/specification/specification-item/tabs/calculation/specification-calculation';
import {SpecificationStructure} from './pages/specification/specification-item/tabs/structure/specification-structure';
import {SpecificationDocuments} from './pages/specification/specification-item/tabs/documents/specification-documents';
import {SpecificationList} from './pages/specification/specification-list';
import {LoginError} from './pages/login/login-error';

const theme = createTheme(muiTheme);

function App() {
    const Nodes = lazy(() => import('./pages/component/nodes'));
    const Dictionary = lazy(() => import('./pages/dictionary/dictionary'));
    const DictionaryType = lazy(() => import('./pages/dictionary/dictionary-type/dictionary-type'));
    const DictionaryItem = lazy(() => import('./pages/dictionary/dictionary-item/dictionary-item'));
    const Product = lazy(() => import('./pages/product/product-list'));
    const ProductItem = lazy(() => import('./pages/product/product-item/product-item'));
    const UserList = lazy(() => import('./pages/users/user-list'));
    const User = lazy(() => import('./pages/users/user-item/user-item'));
    const ConfigurationList = lazy(() => import('./pages/configuration/configuration-list'));
    const Configuration = lazy(() => import('./pages/configuration/configuration-item/configuration-item'));



    return (
        <>
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route path="/" element={<CabinetLayout/>}>
                        <Route index element={<Navigate replace to={`/${ROUTE_PATH.specifications}`}/>}/>
                        <Route path={`/${ROUTE_PATH.specifications}`} element={<SpecificationList/>}/>
                        <Route path={`/${ROUTE_PATH.specifications}/:id`}>
                            <Route index element={<Navigate replace to={'data-input'}/>}/>
                            <Route path={'data-input'} element={<SpecificationDataInput/>}/>
                            <Route path={'specification'} element={<SpecificationCalculation/>}/>
                            <Route path={'documents'} element={<SpecificationDocuments/>}/>
                            <Route path={'structure'} element={<SpecificationStructure/>}/>
                        </Route>

                        <Route path={`/${ROUTE_PATH.newComponents}`}
                               element={<ProtectedRoute><Nodes/></ProtectedRoute>}/>
                        <Route path={`/${ROUTE_PATH.newComponents}/type`}
                               element={<ProtectedRoute><NodeItem/></ProtectedRoute>}>
                        </Route>

                        <Route path={`/${ROUTE_PATH.newComponents}/:type/:id`}
                               element={<ProtectedRoute><ComponentItem/></ProtectedRoute>}>
                            <Route path={''} element={<ProtectedRoute><ComponentItemsForm/></ProtectedRoute>}/>
                            <Route path={'properties'}
                                   element={<ProtectedRoute><ComponentItemProps/></ProtectedRoute>}/>
                            <Route path={'references'}
                                   element={<ProtectedRoute><ComponentItemRefs/></ProtectedRoute>}/>
                            <Route path={'history'}
                                   element={<ProtectedRoute><ComponentItemHistory/></ProtectedRoute>}/>
                        </Route>
                        <Route path={`/${ROUTE_PATH.dictionary}`}
                               element={<ProtectedRoute><Dictionary/></ProtectedRoute>}/>
                        <Route path={`/${ROUTE_PATH.dictionary}/type`}
                               element={<ProtectedRoute><DictionaryType/></ProtectedRoute>}/>
                        <Route path={`/${ROUTE_PATH.dictionary}/:type/:id`}
                               element={<ProtectedRoute><DictionaryItem/></ProtectedRoute>}>
                            <Route path={''} element={<ProtectedRoute><DictionaryItemProps/></ProtectedRoute>}/>
                            <Route path={'properties'}
                                   element={<ProtectedRoute><DictionaryItemProps/></ProtectedRoute>}/>
                            <Route path={'history'}
                                   element={<ProtectedRoute><DictionaryItemHistory/></ProtectedRoute>}/>
                        </Route>
                        <Route path={`/${ROUTE_PATH.products}`}
                               element={<ProtectedRoute><Product/></ProtectedRoute>}/>
                        <Route path={`/${ROUTE_PATH.products}/:id`}
                               element={<ProtectedRoute><ProductItem/></ProtectedRoute>}/>
                        <Route path={`/${ROUTE_PATH.users}`}
                               element={<ProtectedRoute><UserList/></ProtectedRoute>}/>
                        <Route path={`/${ROUTE_PATH.users}/:id`}
                               element={<ProtectedRoute><User/></ProtectedRoute>}/>

                        <Route path={`/${ROUTE_PATH.configurations}`}
                               element={<ProtectedRoute><ConfigurationList/></ProtectedRoute>}/>
                        <Route path={`/${ROUTE_PATH.configurations}/:id`}
                               element={<ProtectedRoute><Configuration/></ProtectedRoute>}/>

                        <Route path={`/${ROUTE_PATH.settings}`} element={<Settings/>}/>
                        <Route path={`/${ROUTE_PATH.settings}/system`} element={<SettingsSystemCommon/>}/>
                        <Route path={`/${ROUTE_PATH.settings}/user/fullname`} element={<SettingsUserFullname/>}/>
                        <Route path={`/${ROUTE_PATH.settings}/user/password`} element={<SettingsUserPassword/>}/>

                    </Route>
                    <Route path="/sso/error" element={<LoginError/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </ThemeProvider>
        </>
    );
}

export default App;
