import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import baseRequest from '../../utils/base-request';
import {ApiSystemSettings} from '../../interface/system-settings.interface';
import {ApiCurrency} from "../../interface/api-currency";

export interface SettingsState {
    settings: ApiSystemSettings | null;
    currencyRate: ApiCurrency | null;
}
export const getSettings = createAsyncThunk(
    'settings/getSettings',
    async function (_, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: '/api/system/settings'
        }, dispatch);
        return response.data;
    }
);


export const getLastCurrencyRate = createAsyncThunk(
    'settings/getLastCurrencyRate',
    async function (_, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: '/api/currency/exchange/last'
        }, dispatch);
        return response.data;
    }
);




const initialState: SettingsState = {
    settings: null,
    currencyRate: null
};


const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        resetSettingsState() {
            return initialState;
        }
    },
    extraReducers: {
        [getSettings.fulfilled as any]: (state: SettingsState, action: PayloadAction<ApiSystemSettings>) => {
            state.settings = action.payload;
        },
        [getLastCurrencyRate.fulfilled as any]: (state: SettingsState, action: PayloadAction<ApiCurrency>) => {
            state.currencyRate = action.payload;
        },
    },

});

export const {resetSettingsState} = settingsSlice.actions;
export default settingsSlice.reducer;
