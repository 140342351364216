import {withJsonFormsControlProps} from '@jsonforms/react';
import {Autocomplete, CircularProgress, FormHelperText, Hidden, TextField} from '@mui/material';
import {
    and,
    ControlProps,
    isDescriptionHidden,
    JsonSchema,
    rankWith,
    schemaMatches, showAsRequired
} from '@jsonforms/core';
import React, {SyntheticEvent, useEffect, useState} from 'react';
import {useFocus} from '@jsonforms/material-renderers';


type CustomSchema = JsonSchema & { options: any };
interface ListOption { title:string, value: string, key: string}

const AsyncSelectControl = (props: ControlProps) => {

    const {
        id,
        data,
        visible,
        path,
        label,
        schema,
        handleChange,
        description,
        uischema,
        enabled,
        config,
        errors,
        required
    } = props;

    const appliedUiSchemaOptions = {...config, ...uischema?.options};
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [focused, onFocus, onBlur] = useFocus();
    const isValid = errors.length === 0;
    const controlSchema = schema as CustomSchema;
    const freeSolo = controlSchema?.options?.freeSolo || false;


    const showDescription = !isDescriptionHidden(
        visible,
        description,
        focused,
        appliedUiSchemaOptions.showUnfocusedDescription
    );

    const firstFormHelperText = showDescription
        ? description
        : !isValid
            ? errors
            : null;
    const secondFormHelperText = showDescription && !isValid ? errors : null;

    const findOption = options.find(o => o.value === data) ?? null;

    const findOptionByTitle = (title: string) => (
        options.find(o => o.title === title) ?? null
    );

    const handleInputChange = (event: SyntheticEvent, optionTitle: string): void => {
        const option = findOptionByTitle(optionTitle);
        const value = option ? option.value : optionTitle || undefined;
        handleChange(path, value);
    };

    useEffect(() => {

        const params = new URLSearchParams(controlSchema.options.search);
        const url = `${controlSchema.options.path}?${params}`;

        const dataFetch = async () => {
            setLoading(true);
            const data = await (
                await fetch(url)
            ).json();
            setLoading(false);
            const options: ListOption[] = data.result.map((i: any, idx: number) => {
                return {
                    title: controlSchema.options.dataMap.title.split('.').reduce((p: any, c: any) => (p && p[c]) || null, i),
                    value: controlSchema.options.dataMap.value.split('.').reduce((p: any, c: any) => (p && p[c]) || null, i),
                    key: `option-${idx}`
                };
            }).filter((o: ListOption) => !!o.value);

            if(!!props.data && freeSolo){
                const option = options.find(o => o.value === props.data);
                if(!option){
                        options.push({
                            title: props.data,
                            value: props.data,
                            key: `option-${options.length +1}`
                        });
                }
            }

            setOptions(options);
        };

        dataFetch();
    }, []);


    return <Hidden xsUp={!visible}>
        <Autocomplete
            freeSolo={freeSolo}
            options={options}
            open={open}
            value={findOption}
            disabled={!enabled}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            getOptionLabel={(option) => option.title || option}
            loading={loading}
            onInputChange={handleInputChange}
            renderOption={(props, option) => (
                <li {...props} key={option.key}>
                    {option.title}
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    variant={'standard'}
                    label={label}
                    inputRef={params.InputProps.ref}
                    autoFocus={appliedUiSchemaOptions.focus}
                    {...params}
                    id={id + '-input'}
                    required={showAsRequired( !!required , !appliedUiSchemaOptions.hideRequiredAsterisk)}
                    error={!isValid}
                    fullWidth={!appliedUiSchemaOptions.trim}
                    InputLabelProps={data ? {shrink: true} : undefined}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    focused={focused}

                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
        <FormHelperText error={!isValid && !showDescription}>
            {firstFormHelperText}
        </FormHelperText>
        <FormHelperText error={!isValid}>
            {secondFormHelperText}
        </FormHelperText>
    </Hidden>;
};

export const asyncSelectTester = rankWith(
    3,
    and(schemaMatches((schema) => schema.hasOwnProperty('custom')))
);


export default withJsonFormsControlProps(AsyncSelectControl);
