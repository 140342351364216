import {Dialog, IconButton} from '@mui/material';
import React from 'react';
import {useAppSelector} from '../../../../hook/store';
import {SpecTypeState} from '../../../../store/slice/spec-type-slice';
import './specification-help.scss';
import {Close} from '@mui/icons-material';

interface SpecificationHelpProps {
    onClose: () => void;
}

const SpecificationHelp = ({onClose}:SpecificationHelpProps) => {
    const {currentSpecTypeHelp} = useAppSelector<SpecTypeState>(store => store.specType);
    return (
        <Dialog
            className="help-dialog"
            open={true}
            fullWidth={true}
            maxWidth={'sm'}
            onClose={onClose}
        >
            <div className="close">
                <IconButton aria-label="close" size="medium" onClick={onClose}>
                    <Close fontSize="inherit"/>
                </IconButton>
            </div>
            <div className="content" dangerouslySetInnerHTML={{__html: currentSpecTypeHelp}}></div>
        </Dialog>
    );
};

export {SpecificationHelp};
