import {useAppDispatch, useAppSelector} from '../../../../hook/store';
import {useForm} from 'react-hook-form';
import {
    Button,
    Dialog, DialogActions, DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select, TextField
} from '@mui/material';
import {useEffect, useRef, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {addSpecifications} from '../../../../store/slice/spec-slice';
import {SpecCreateInterface} from '../../../../interface';
import {useNavigate} from 'react-router-dom';
import {ROUTE_PATH} from '../../../../constants/routes';

type NewSpecificationDialogProps = {
    handleClose: () => void;
}

interface NewSpecForm {
    typeId: string;
    version: string;
    name: string
}

interface FormSpecItem {
    code: string;
    name: string;
    version: number[];
}

const NewSpecificationDialog = (props: NewSpecificationDialogProps) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const {allSpecTypes} = useAppSelector(state => state.specType);
    const formItems = useRef<FormSpecItem[]>([]);
    const [versionOptions, setVersionOptions] = useState<number[]>();

    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
        watch
    } = useForm<NewSpecForm>({
        mode: 'onBlur'
    });


    const onSubmit = async (data: NewSpecForm) => {
        setInProgress(true);

        const spec = allSpecTypes?.result && allSpecTypes.result.find( (i) => i.code == data.typeId && i.version === parseInt(data.version))
        if(spec?.id){
            const specData: SpecCreateInterface = {
                typeId: spec.id,
                name: data.name,
                comment: ''
            };
            await dispatch(addSpecifications(specData)).unwrap()
                .then((res) => {
                    if (!!res) {
                        navigate(`/${ROUTE_PATH.specifications}/${res.id}`);
                    } else {
                        props.handleClose();
                    }
                })
                .catch((error) => {
                    setInProgress(false);
                    props.handleClose();
                    throw new Error(error);
                });
        }

    };

    useEffect(() => {
        if (allSpecTypes?.result) {
            const items: FormSpecItem[] = allSpecTypes.result.reduce((acc: FormSpecItem[], curr) => {
                const existingItem = acc.find(v => v.code === curr.code);
                if (existingItem) {
                    existingItem.version = [...existingItem.version, curr.version]
                } else {
                    acc.push({
                        code: curr.code,
                        name: curr.name,
                        version: [curr.version]
                    });
                }
                return acc;
            }, []);
            formItems.current = items;
            setVersionOptions([]);
        }
    }, [allSpecTypes])

    useEffect(() => {
        const subscription = watch((value, {name}) => {
                if (name === 'typeId' && !!value?.typeId) {
                    const type = formItems.current.find((i) => i.code === value.typeId )
                    if(type){
                        setVersionOptions(type.version.sort())
                    }
                }
            }
        );
        return () => subscription.unsubscribe()
    }, [watch])


    return <>
        <Dialog
            className="new-item-dialog"
            open={true}
            maxWidth={'lg'}
            onClose={props.handleClose}
        >
            <DialogTitle className="dialog-title">Новая спецификация</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>

                    <FormGroup className={errors?.typeId ? 'form-field form-field-error' : 'form-field'}>
                        <FormControl error={!!errors?.typeId} size={'small'}>
                            <InputLabel id="type-select">Тип</InputLabel>
                            <Select {...register('typeId', {
                                required: 'Обязательное поле',
                            })}
                                    labelId="type-select"
                                    id="type-select"
                                    label="Тип"
                                    variant="outlined"
                                    defaultValue={''}
                            >
                                {!!formItems.current && formItems.current.map((type) => {
                                    return <MenuItem key={type.code} value={type.code}>{type.name}</MenuItem>;
                                })}
                            </Select>
                            <FormHelperText>{errors?.typeId ? errors.typeId.message as string : ''}</FormHelperText>
                        </FormControl>
                    </FormGroup>
                    <FormGroup className={errors?.version ? 'form-field form-field-error' : 'form-field'}>
                        <FormControl error={!!errors?.version} size={'small'}>
                            <InputLabel id="version-select">Версия</InputLabel>
                            <Select {...register('version', {
                                required: 'Обязательное поле',
                                disabled: watch('typeId') === ''
                            })}
                                    labelId="version-select"
                                    id="version-select"
                                    label="Версия"
                                    variant="outlined"
                                    defaultValue={''}
                            >
                                {!!versionOptions && versionOptions.map((version) => {
                                    return <MenuItem key={`version${version}`} value={version}>{version}</MenuItem>;
                                })}
                            </Select>
                            <FormHelperText>{errors?.typeId ? errors.typeId.message as string : ''}</FormHelperText>
                        </FormControl>
                    </FormGroup>
                    <FormGroup className={errors?.name ? 'form-field form-field-error' : 'form-field'}>
                        <FormControl>
                            <TextField {...register('name', {
                                required: 'Обязательное поле',
                                minLength: {value: 3, message: 'Название должно содержать минимум 3 символa'},
                                maxLength: {value: 100, message: 'Название должно содержать максимум 100 символов'}
                            })}
                                       label="Название расчета"
                                       multiline
                                       rows={3}
                                       variant="outlined"
                                       error={!!errors?.name}
                                       helperText={errors?.name ? errors?.name.message as string : ''}

                            />
                        </FormControl>
                    </FormGroup>

                </DialogContent>
                <DialogActions className="form-buttons">
                    <Button
                        disabled={inProgress}
                        variant={'outlined'}
                        onClick={props.handleClose}
                    >
                        Отмена
                    </Button>
                    <LoadingButton
                        loading={inProgress}
                        type={'submit'}
                        variant={'contained'}
                        disabled={!isValid || inProgress}
                    >
                        Добавить
                    </LoadingButton>
                </DialogActions>
            </form>

        </Dialog>
    </>;
};

export {NewSpecificationDialog};
