class ObjectHelper {

    static pickParams = (object: object, toPick: string[]) => (Object.fromEntries(Object.entries(object).filter(e => toPick.includes(e[0]))));

    static unsetParams = (object: object, toUnset: string[]) => (Object.fromEntries(Object.entries(object).filter(e => !toUnset.includes(e[0]))));

    static isObject = (object: any) => (typeof object === 'object' && object !== null);

    static isNullOrUndefined = (value: any) => (value === undefined || value === null);

    static keysToLowerCase<T>(obj: T): T {
        if (typeof obj === 'object') {
            for (const key in obj) {
                const newKey = key.toLowerCase();
                // @ts-ignore
                obj[newKey] = obj[key];
                if (key !== newKey) {
                    delete obj[key];
                }
            }
        }

        return obj;
    }

    static arrayUnique = (value: any, index: number, array: any[]) => {
        return array.indexOf(value) === index;
    };
}

export default ObjectHelper;
