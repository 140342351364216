import React, {useEffect, useState} from 'react';
import {Unwrapped} from '@jsonforms/material-renderers';
import {useJsonForms, withJsonFormsControlProps} from '@jsonforms/react';
import {
    and,
    ControlProps,
    isControl,
    JsonSchema7,
    optionIs,
    OwnPropsOfEnum,
    RankedTester,
    rankWith
} from '@jsonforms/core';
import {CircularProgress, Grid} from '@mui/material';
import styles from './dependent-autocomplete-renderer.module.scss';
import {useAppDispatch} from '../../../../../../hook/store';
import {getNode} from '../../../../../../store/slice/node-slice';

const {MaterialOneOfEnumControl} = Unwrapped;

const DependentAutocompleteRenderer = (props: ControlProps & OwnPropsOfEnum) => {

    const ctx = useJsonForms();
    const path = props.uischema?.options?.path;
    const pathParams = props.uischema?.options?.pathParams || {};
    const dataMap = props.uischema?.options?.dataMap;
    const [options, setOptions] = useState([]);
    const [type, setType] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!!type) {
            setIsLoading(true);
            const params = {
                type,
                showDraft: 'true',
                ...pathParams
            };
            const search = new URLSearchParams(params).toString();
            const url = `${path}?${search}`;

            const dataFetch = async () => {
                const data = await (
                    await fetch(url)
                ).json();
                setOptions(
                    data.result
                        .map((i: any) => {
                            return {
                                label: dataMap.title.split('.').reduce((p: any, c: any) => (p && p[c]) || null, i).toString(),
                                value: dataMap.value.split('.').reduce((p: any, c: any) => (p && p[c]) || null, i),
                                status: i.status
                            };
                        })
                        .sort( (a: any, b: any) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
                );
                setIsLoading(false);
            };

            dataFetch();
        }
    }, [type]);


    if (ctx.core?.data?.type && ctx.core.data.type !== type) {
        setType(ctx.core.data.type);
    }

    if (!path) {
        return <>
            Path is not configured in Control options.</>;
    }
    if (!dataMap) {
        return <>Data mapping i not configured in Control options.</>;
    }


    const enabled = props.enabled && !(props.schema as JsonSchema7).readOnly;

    return (
        <Grid container>
            <Grid item xs={12} className={styles.loadContainer}>
                {isLoading && (
                    <CircularProgress color="inherit" className={styles.loading} size={20}/>
                )}
                <MaterialOneOfEnumControl
                    t={id => (id)} locale={'ru'}
                    {...props}
                    id={(props.schema as JsonSchema7).$id || 'dependentSelect'}
                    enabled={enabled}
                    data={options.length ? props.data : undefined}
                    options={options}
                />
            </Grid>
        </Grid>
    );
};

export const dependentAutocompleteTester: RankedTester = rankWith(
    4,
    and(
        isControl,
        optionIs('dependentAutocomplete', true)
    )
);

export default withJsonFormsControlProps(DependentAutocompleteRenderer);
