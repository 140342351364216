import React, {useEffect, useState} from 'react';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {Button, FormControl, FormHelperText, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useNavigate} from 'react-router-dom';
import styles from '../../settings.module.scss';
import {AppSuspense} from '../../../../components/app-suspense/app-suspense';
import {showAlert} from '../../../../store/slice/alert-slice';
import {useAppDispatch, useAppSelector} from '../../../../hook/store';
import {UserState, getCurrentUser} from '../../../../store/slice/user-slice';
import {ROUTE_PATH} from '../../../../constants/routes';
import {cleanBreadcrumbs, setBreadcrumbs} from '../../../../store/slice/utils.slice';

interface IUserName {
    username: string;
    fullName: string;
}

const SettingsUserFullname = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [formData, setFormData] = useState<IUserName>({username: '', fullName: ''});
    const {currentUser} = useAppSelector<UserState>(store => store.user);

    useEffect(() => {
        if (!!currentUser) {
            setFormData({
                username: currentUser.username,
                fullName: currentUser.fullName,
            });
        } else {
            dispatch(getCurrentUser({isLogin: false}));
        }
    }, [currentUser]);

    useEffect(() => {
        dispatch(setBreadcrumbs([
            {
                title: 'Настройки',
                path: `/${ROUTE_PATH.settings}`
            },
            {
                title: 'Пользователь',
                path: ''
            }
        ]));
        return () => {
            dispatch(cleanBreadcrumbs());
        };
    }, [dispatch]);

    const handleFormChange = (e: any) => {
        setFormData({...formData, fullName: e.target.value});
    };

    const updateUsername = async () => {
        const data = await fetch('/api/user/current/fullname', {
            method: 'PUT',
            body: JSON.stringify({fullName: formData.fullName}),
            headers: {
                'content-type': 'application/json'
            }
        });
        return data;
    };

    const saveSettings = () => {
        setInProgress(true);
        updateUsername()
            .then((response) => {
                if (response.status === 200) {
                    dispatch(showAlert({
                        message: 'Имя пользователя изменено.',
                        header: 'Изменение настроек пользователя'
                    }));
                    return;
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                if (response) {
                    dispatch(showAlert({
                        message: response.Error,
                        header: 'Изменение настроек пользователя',
                        errorColor: 'red'
                    }));
                }
            })
            .finally(() => {
                setInProgress(false);
            });
    };

    return (
        <>
            <Card className={styles.settingsCard}>
                <CardBody className={styles.settingsBody}>
                    <div className={styles.rowTitle}>Настройки пользователя</div>
                    <AppSuspense condition={!!currentUser}>
                        <div className={styles.row}>
                            <FormControl fullWidth margin={'normal'}>
                                <TextField
                                    label="Логин"
                                    fullWidth
                                    variant={'standard'}
                                    defaultValue={currentUser?.username}
                                    disabled={true}
                                />
                            </FormControl>
                        </div>
                        <div className={styles.row}>
                            <FormControl fullWidth margin={'normal'}>
                                <TextField
                                    label="Имя"
                                    fullWidth
                                    error={!formData.fullName}
                                    variant={'standard'}
                                    defaultValue={currentUser?.fullName}
                                    onChange={handleFormChange}
                                    sx={{
                                        '& .MuiFormLabel-root.Mui-error': {
                                            color: 'rgba(0, 0, 0, 0.6)',
                                        }
                                    }}
                                />
                                <FormHelperText
                                    style={{color: 'red'}}>{!formData?.fullName ? 'Имя не может быть пустым.' : ''}</FormHelperText>
                            </FormControl>
                        </div>
                        <div className={styles.row} style={{justifyContent: 'flex-start'}}>
                            <Button
                                variant={'outlined'}
                                className={styles.settingsBtn}
                                disabled={inProgress}
                                onClick={() => navigate(`/${ROUTE_PATH.settings}`)}
                            >Отмена</Button>
                            <LoadingButton
                                variant={'contained'}
                                className={styles.settingsBtn}
                                onClick={saveSettings}
                                disabled={inProgress || !formData.fullName}
                                loading={inProgress}
                            >Изменить</LoadingButton>
                        </div>
                    </AppSuspense>
                </CardBody>
            </Card>

        </>

    );
};

export {SettingsUserFullname};
