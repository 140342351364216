import {SpecificationItemTab} from '../../../../../interface';
import {ReactComponent as IconWarn} from '../../../../../assets/img/icon/icon-form-warn.svg';
import {ReactComponent as IconCheck} from '../../../../../assets/img/icon/icon-form-check.svg';

interface SpecificationItemTabsProps {
    tabs: SpecificationItemTab[];
    selected: number;
    onSelect: (tab: number) => void;
    onMenuClick?: (item: string) => void;
    onToggleScheme?: () => void;
    showScheme?: boolean;
}


const SpecificationItemTabs = ({
                                   tabs,
                                   selected,
                                   onSelect,
                               }: SpecificationItemTabsProps) => {


    const isActive = (index: number): boolean => {
        return selected === index;
    };

    return (
        <div className={'item-tabs'}>
            <div className="form-tabs">
                {
                    tabs.map((tab, index) => {
                        if (tab.type === 'form') {
                            return <div
                                key={index}
                                className={isActive(index) ? 'tab active' : 'tab'}
                                onClick={() => onSelect(index)}
                            >
                                {tab.error && !isActive(index) && (<IconWarn/>)}
                                {!tab.error && !isActive(index) && (
                                    <span className={tab.filled ? 'check filled' : 'check'}><IconCheck/></span>)}
                                {isActive(index) && (
                                    <span className={tab.filled ? 'check filled' : 'check'}><IconCheck/></span>)}
                                {tab.title}
                                {tab.required && <span>*</span>}
                            </div>;
                        }
                        return null;
                    })
                }
            </div>
        </div>
    );


};

export {SpecificationItemTabs};
