import {DropDownButton} from '@progress/kendo-react-buttons';
import React, {PropsWithChildren} from 'react';
import {DropDownButtonItemClickEvent} from '@progress/kendo-react-buttons/dist/npm/ListButton/models/events';


interface MenuActionsProps extends PropsWithChildren {
    onAction: (action: any) => void
}

const MenuActions = ({children, onAction}: MenuActionsProps) => {

    const handleAction = (e: DropDownButtonItemClickEvent) => {
        onAction(e);
    };

    const itemRender = (itemData: { item: any; itemIndex: number }) => {
        return <div className="action-item">
            <span data-action={itemData.item.text}>{itemData.item.text}</span>
        </div>;
    };

    return (
        <DropDownButton
            size={'small'}
            themeColor={'primary'}
            fillMode={'solid'}
            rounded={'small'}
            icon={'more-vertical'}
            itemRender={itemRender}
            onItemClick={handleAction}
        >
            {children}
        </DropDownButton>
    );
};

export {MenuActions};
