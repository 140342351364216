import {ReactElement, useEffect, useState} from 'react';
import {DropDownButton, DropDownButtonItem} from '@progress/kendo-react-buttons';
import {DropDownButtonItemClickEvent} from '@progress/kendo-react-buttons/dist/npm/ListButton/models/events';
import {
    AppPromptData,
    NodeOptionAction,
    NodeOptionActionListItem,
    ApiNodeItemOptions
} from '../../../../../interface';
import {AppPrompt} from '../../../../../components/app-prompt/app-prompt';
import {useAppSelector} from '../../../../../hook/store';
import {NodeState} from '../../../../../store/slice/node-slice';
import {
    getComponentOptionActionItems,
} from './component-option-actions.meta';
 
interface ComponentOptionActionsProps {
    onAction: (e: DropDownButtonItemClickEvent) => void;
}

const ComponentOptionActions = ({ onAction }: ComponentOptionActionsProps) => {
    const [actionItems, setActionItems] = useState<NodeOptionActionListItem[]>([]);
    const {currentNodeItem} = useAppSelector<NodeState>(store => store.node);

    const itemRender = (itemData: { item: any; itemIndex: number }): ReactElement => {
        return <div className="action-item">
            <span data-action={itemData.item.text}>{itemData.item.text}</span>
        </div>;
    };

    const handleAction = (e: DropDownButtonItemClickEvent): void => {
        onAction(e);
    };

    useEffect(() => {
        if(!currentNodeItem) return;
        setActionItems(getComponentOptionActionItems(currentNodeItem));
    }, [currentNodeItem]);

    return <>
        <DropDownButton
            size={'small'}
            themeColor={'primary'}
            fillMode={'solid'}
            rounded={'small'}
            icon={'more-vertical'}
            itemRender={itemRender}
            onItemClick={handleAction}
        >
            {actionItems.filter(item => item.active).map(item => (
                <DropDownButtonItem key={item.action} text={item.action}/>
            ))}
        </DropDownButton>
    </>;
};

export {ComponentOptionActions};
