import {SpecificationTabWrapper} from '../tab-header/specification-tab-wrapper';
import {AppSuspense} from '../../../../../components/app-suspense/app-suspense';
import {useAppSelector} from '../../../../../hook/store';
import {SpecificationState} from '../../../../../store/slice/spec-slice';
import React, {useEffect, useState} from 'react';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {SpecificationItemCalculation} from '../../components/calculation/specification-item-calculation';
import {ScrollToTop} from '../../../../../ui/scroll-to-top/scroll-to-top';

const SpecificationStructure = () => {
    const {currentSpec} = useAppSelector<SpecificationState>(store => store.spec);
    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect(() => {
        if (!!currentSpec?.id) {
            setIsReady(true);
        }
    }, [currentSpec]);

    return (
        <SpecificationTabWrapper>
            {!!currentSpec && (
                <AppSuspense condition={isReady}>
                    <Card style={{overflow: 'unset'}}>
                        <CardBody style={{padding: 24}}>
                            <SpecificationItemCalculation
                                specId={currentSpec?.id}
                                model={currentSpec?.doc?.model}
                                type={'default'}/>

                        </CardBody>
                    </Card>
                </AppSuspense>
            )}
            <ScrollToTop/>
        </SpecificationTabWrapper>
    );
};

export {SpecificationStructure};
