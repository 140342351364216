import './status-badge.scss';
import {SpecItemStatus} from '../../interface/spec.interface';
import {NodeItemStatus} from '../../interface';

interface StatusBadgeProps{
    status?: SpecItemStatus | NodeItemStatus;
}

const StatusBadge = ({status}: StatusBadgeProps) => {
    let label: string | null;
    switch (status){
        case SpecItemStatus.archive:
            label = 'Архивный';
            break;
        case SpecItemStatus.draft:
            label = 'Черновик';
            break;
        default:
            label = null;
            break;
    }

    if(!label) {
        return <></>;
    }

    return <>
        <div className={`status-badge status-badge-${status}`}>
            {label}
        </div>
    </>;
};

export {StatusBadge};
