import {CSSProperties} from 'react';

const NotFound = () => {

    const style: CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%'
    };

    return <div style={style}>
        <h2>404</h2>
        <div>Page not found</div>
    </div>;
};

export {NotFound};
