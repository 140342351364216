import {GridHeaderCellProps} from '@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps';
import React, {useEffect, useState} from 'react';

import {Popover} from '@mui/material';
import {GridColumnMenuItemContent, GridColumnMenuItemGroup} from '@progress/kendo-react-grid';
import {ReactComponent as IconFilter} from '../../../../assets/img/icon/filter.svg';
import styles from './filters.module.scss';
import {FilterHeader} from './components/filter-header';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {ru} from 'date-fns/esm/locale';
import {IntlProvider} from '@progress/kendo-react-intl';
import './date-form.scss';
import {isValid} from 'date-fns';

interface DateFilterProps extends GridHeaderCellProps {
    selected?: string | null;
    onFilter: (filter: string) => void;
    onReset: () => void;
}

const DateFilter = (props: DateFilterProps) => {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);

    const handleClick = (event: React.MouseEvent<any>): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const canFilter = (): boolean => {
        return !!startDate && !!endDate;
    };

    const applyFilter = (e: React.MouseEvent<HTMLButtonElement>): void => {
        if (canFilter()) {
            props.onFilter(`${startDate};${endDate}`);
        }
    };

    const resetFilter = (e: React.MouseEvent<HTMLButtonElement>): void => {
        props.onReset();
        handleClose();
    };


    const getUtcDate = (date: Date) => {
        const d = new Date(date);
        const userTimezoneOffset = d.getTimezoneOffset() * 60000;
        return new Date(d.getTime() - userTimezoneOffset);
    };

    const getLocalDate = (date: Date) => {
        const d = new Date(date);
        const userTimezoneOffset = d.getTimezoneOffset() * 60000;
        return new Date(d.getTime() + userTimezoneOffset) || null;
    };

    const onStartChange = (e: Date | null) => {
        if (e && isValid(e)) {
            const date = getUtcDate(e);
            setStartDate(date.toISOString());
        } else {
            setStartDate(null);
        }
    };
    const onEndChange = (e: Date | null) => {
        if (e && isValid(e)) {
            const date = getUtcDate(e);
            date.setUTCHours(23, 59, 59);
            setEndDate(date.toISOString());
        } else {
            setEndDate(null);
        }
    };

    const getStartDate = () => {
        if (startDate) {
            return getLocalDate(new Date(startDate));
        }
        return null;
    };

    const getEndDate = () => {
        if (endDate) {
            return getLocalDate(new Date(endDate));
        }
        return null;
    };

    useEffect(() => {
        if (!!props.selected) {
            const dates = props.selected.split(';');

            setStartDate(dates[0] || null);
            setEndDate(dates[1] || null);
        }
    }, [props.selected]);


    const open = Boolean(anchorEl);

    return (
        <div onClick={props.onClick}
             className={!!props.selected ? `${styles.header} ${styles.filtered}` : styles.header}>
            <FilterHeader {...props} handleClick={handleClick}></FilterHeader>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={(e) => e.stopPropagation()}
            >
                <GridColumnMenuItemGroup>
                    <GridColumnMenuItemContent show={true}>
                        <div className="k-columnmenu-item">
                                    <span className="k-icon">
                                        <IconFilter/>
                                    </span>
                            Фильтр
                        </div>
                        <div className={'date_wrapper'}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
                                <IntlProvider locale={'ru'}>
                                    <div className={'date_input'}>
                                        <DatePicker
                                            localeText={{

                                            }}
                                            // size={'small'}
                                            label={'от'}
                                            format="dd.MM.yyyy"
                                            // weekNumber={false}
                                            // fillMode={'outline'}
                                            value={getStartDate()}
                                            onChange={onStartChange}
                                        />
                                    </div>
                                    <div className={'date_input'}>
                                        <DatePicker
                                            // size={'small'}
                                            label={'до'}
                                            format="dd.MM.yyyy"
                                            // fillMode={'outline'}
                                            // weekNumber={false}

                                            value={getEndDate()}
                                            onChange={onEndChange}
                                        />
                                    </div>
                                </IntlProvider>
                            </LocalizationProvider>
                        </div>

                        <div className={styles.actions}>
                            <button
                                className={'k-button k-button-sm k-rounded-sm k-button-solid k-button-solid-primary'}
                                onClick={applyFilter}
                                disabled={!canFilter()}
                            >
                                Применить
                            </button>
                            <button
                                className={'k-button k-button-sm k-rounded-sm k-button-outline k-button-outline-primary'}
                                onClick={resetFilter}
                            >
                                Сбросить
                            </button>
                        </div>
                    </GridColumnMenuItemContent>
                </GridColumnMenuItemGroup>
            </Popover>

        </div>
    );
};

export {DateFilter};
