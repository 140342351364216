import {SpecificationTypeResource} from './spec-type.interface';

export enum ConfigurationAction {
    edit = 'Редактировать',
}

export interface  ConfigurationResourceDataItem extends SpecificationTypeResource{
    typeName: string;
}

export interface ConfigurationResourceDialogData {
    type: string;
    nodeId: number;
}

export enum ConfigurationResourceAction {
    'edit' = 'Редактировать',
    'open' = 'Перейти к компоненту',
}

