import {ColumnInterface} from '../../../../components';

export const specificationColumns: ColumnInterface[] = [
    {
        title: '№',
        field: 'id',
        type: 'integer',
        searchField: 'id',
        show: true,
        sortable: true,
        width: '100px'
    },
    {
        title: 'Тип',
        field: 'typeId',
        type: 'type',
        searchField: 'type',
        show: true,
        sortable: true,
        width: '150px',
    },
    {
        title: 'Название',
        field: 'name',
        type: 'name',
        show: true,
        searchField: 'name',
        sortable: true,
        width: '200px',
    },
    {
        title: 'Состояние',
        field: 'status',
        type: 'status',
        show: true,
        searchField: 'status',
        sortable: true,
        width: '200px'
    },
    {
        title: 'Автор',
        field: 'author',
        type: 'author',
        show: true,
        searchField: 'author',
        sortable: true,
        width: '200px'
    },
    {
        title: 'Дата',
        field: 'lastModified',
        type: 'modified',
        searchField: 'modified',
        show: true,
        sortable: true,
        width: '200px',
    },
    {
        title: 'Общая сумма расчета, ₽',
        field: 'price',
        type: 'integer',
        searchField: 'price',
        show: false,
        sortable: true,
        width: '220px',
    },
    {
        title: 'Время поставки, нед.',
        field: 'deliveryWeeks',
        type: 'integer',
        searchField: 'deliveryWeeks',
        show: false,
        sortable: true,
        width: '200px',
    },
    {
        title: 'Курс валюты',
        field: 'currencyRate',
        type: 'number',
        searchField: 'currencyRate',
        show: false,
        sortable: true,
        width: '200px',
    },
    {
        title: 'Лет техподдержки',
        field: 'supportYears',
        type: 'integer',
        searchField: 'supportYears',
        show: false,
        sortable: true,
        width: '200px',
    },
    {
        title: 'Экспертный режим',
        field: 'expertMode',
        type: 'boolean',
        searchField: 'expertMode',
        show: false,
        sortable: true,
        width: '200px',
    },
];
