import {
    ApiNodeItem,
    AppPromptData,
    NodeAction,
    NodeActionListItem,
    NodeDryRunResponseItem,
    NodeItemStatus
} from '../../../../interface';

export const getComponentActionItems = (hideEdit: boolean, nodeItem: ApiNodeItem): NodeActionListItem[] => {
    return [
        {
            action: NodeAction.manage,
            active: !hideEdit && nodeItem.status !== NodeItemStatus.draft
        },
        {
            action: NodeAction.edit,
            active: !hideEdit && nodeItem.status === NodeItemStatus.draft
        },
        {
            action: NodeAction.delete,
            active: nodeItem.status !== NodeItemStatus.published
        },
        {
            action: NodeAction.archive,
            active: nodeItem.status === NodeItemStatus.published
        },
        {
            action: NodeAction.unarchive,
            active: nodeItem.status === NodeItemStatus.archive
        },
        {
            action: NodeAction.publish,
            active: nodeItem.status === NodeItemStatus.draft
        },
        {
            action: NodeAction.unpublish,
            active: nodeItem.status === NodeItemStatus.published
        },
        {
            action: NodeAction.clone,
            active: true,
        }
    ];
};

export const getConfirmData = (nodeItem: ApiNodeItem, action: NodeAction): AppPromptData => {
    switch (action) {
        case NodeAction.delete:
            return {
                title: 'Удаление компонента',
                message: [`Вы хотите удалить компонент "${nodeItem.name}"`, 'Удаленные компоненты не подлежат восстановлению.'],
                confirmButton: 'Удалить',
                buttonError: true
            };
        case NodeAction.archive:
            return {
                title: 'Архивирование',
                message: [`Вы хотите перенести компонент "${nodeItem.name}" в архив?`],
                confirmButton: 'Подтвердить',
            };
        case NodeAction.unarchive:
            return {
                title: 'Возвращение из архива',
                message: [`Вы хотите вернуть компонент "${nodeItem.name}" в состояние опубликованного?`],
                confirmButton: 'Подтвердить',
            };
        case NodeAction.publish:
            return {
                title: 'Публикация',
                message: [`Вы хотите опубликовать "${nodeItem.name}" и сделать его доступным для использования?`, 'Все несохраненные данные будут потеряны.', 'После публикации редактировать компонент будет нельзя.'],
                confirmButton: 'Опубликовать',
            };
        case NodeAction.unpublish:
            return {
                title: 'Отмена публикации',
                message: [`Вы хотите вернуть компонент "${nodeItem.name}" в черновики?`, 'Компонент станет недоступен для использования.'],
                confirmButton: 'Подтвердить',
            };
        default:
            return {} as AppPromptData;
    }
};

export const getDryRunConfirmData = (nodeItem: ApiNodeItem, action: NodeAction, nodes: NodeDryRunResponseItem[]): AppPromptData => {

    const names = nodes.map((node) => `<a href="components/${node.type}/${node.id}" target="_blank">${node.name}</a>`).join(', \n');

    switch (action) {
        case NodeAction.unpublish:
            return {
                title: 'Отмена публикации',
                message: [`На данный компонент ссылаются другие: \n${names}`,
                    'Эти компоненты также будут переведены в статус "Черновик". Подтвердите операцию.'],
                confirmButton: 'Подтвердить',
            };
        case NodeAction.publish:
            return {
                title: 'Публикация компонента',
                message: [`На данный компонент ссылаются другие: \n${names}`,
                    'Эти компоненты также будут переведены в статус "Опубликован". Подтвердите операцию.'],
                confirmButton: 'Подтвердить',
            };
        default:
            return {} as AppPromptData;
    }
};

export const getDryRunResourceConfirmData = (nodeItem: ApiNodeItem, action: NodeAction, resources: NodeDryRunResponseItem[]): AppPromptData => {

    const names = resources.map((resource) => `<a href="configurations/${resource.id}" target="_blank">${resource.name}</a>`).join(', \n');

    switch (action) {
        case NodeAction.unpublish:
            return {
                title: 'Отмена публикации',
                message: [`Данный компонент используется в качестве ресурса в конфигурациях: \n${names}`,
                    'Изменение статуса может привести к ошибкам в работе конфигураций. Подтвердите операцию.'],
                confirmButton: 'Подтвердить',
            };
        default:
            return {} as AppPromptData;
    }
};


