import {GridHeaderCellProps} from '@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps';
import React, {useState} from 'react';
import styles from './filters.module.scss';
import {Popover, TextField} from '@mui/material';
import {ReactComponent as IconFilter} from '../../../../assets/img/icon/filter.svg';
import {GridColumnMenuItemContent, GridColumnMenuItemGroup} from '@progress/kendo-react-grid';
import {FilterHeader} from './components/filter-header';

interface TextFilterProps extends GridHeaderCellProps {
    selected?: string | null;
    onFilter: (filter: string) => void;
    onReset: () => void;
}

const TextFilter = (props: TextFilterProps) => {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [search, setSearch] =useState<string>(props.selected || '');

    const onSearch = (e: any) => {
        setSearch(e.target.value);
    };

    const handleClick = (event: React.MouseEvent<any>): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const applyFilter = (e: React.MouseEvent<HTMLButtonElement>): void => {
        props.onFilter(search);
        handleClose();
    };

    const resetFilter = (e: React.MouseEvent<HTMLButtonElement>): void => {
        props.onReset();
        handleClose();
    };

    const open = Boolean(anchorEl);

    return (
        <div onClick={props.onClick}
             className={!!props.selected ? `${styles.header} ${styles.filtered}` : styles.header}>
            <FilterHeader {...props} handleClick={handleClick}></FilterHeader>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={(e) => e.stopPropagation()}
            >
                <GridColumnMenuItemGroup>
                    <GridColumnMenuItemContent show={true}>
                        <div className="k-columnmenu-item">
                                    <span className="k-icon">
                                        <IconFilter/>
                                    </span>
                            Фильтр
                        </div>
                        <div style={{padding: '1em'}}>
                            <TextField
                                style={{width: '100%'}}
                                className={'search-field'}
                                label="значение"
                                variant="outlined"
                                defaultValue={search}
                                size={'small'}
                                onChange={onSearch}
                            />
                        </div>
                        <div className={styles.actions}>
                            <button
                                className={'k-button k-button-sm k-rounded-sm k-button-solid k-button-solid-primary'}
                                onClick={applyFilter}
                            >
                                Применить
                            </button>
                            <button
                                className={'k-button k-button-sm k-rounded-sm k-button-outline k-button-outline-primary'}
                                onClick={resetFilter}
                            >
                                Сбросить
                            </button>
                        </div>
                    </GridColumnMenuItemContent>
                </GridColumnMenuItemGroup>
            </Popover>

        </div>
    );
};

export {TextFilter};
