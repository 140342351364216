import {ColumnInterface} from '../../../components';

export const nodeTypeColumns: ColumnInterface[] = [
    {
        title: 'ID',
        field: 'id',
        type: 'integer',
        searchField: 'id',
        show: false,
        sortable: true,
        width: '100px'
    },
    {
        title: 'Название',
        field: 'name',
        type: 'name',
        show: true,
        searchField: 'name',
        sortable: true,
        width: '300px',
    },
    {
        title: 'Состояние',
        field: 'status',
        type: 'status',
        show: true,
        searchField: 'status',
        sortable: true,
        width: '200px',
    },
    {
        title: 'Тип',
        field: 'type',
        type: 'type',
        searchField: 'type',
        show: true,
        sortable: true,
        width: '200px',
    },
    {
        title: 'Дата изменения',
        field: 'lastModified',
        type: 'modified',
        searchField: 'modified',
        show: true,
        sortable: true,
        width: '200px',
    }
];
