export interface ApiRole {
    id: number;
    name: Role;
    displayName: string
}

export enum Role {
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_SALESMAN = 'ROLE_SALESMAN'
}
