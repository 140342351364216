import styled from "@emotion/styled";
import { colors } from "../../themes/colors";
import {font} from "../../themes/fonts";
export const Container = styled.div({
  display: "flex",
  padding: "0",
  backgroundColor: colors.white,
});

export const Logo = styled.div({
  display: "flex",
  alignSelf: "flex-start",
  marginRight: "12px",
});

export const NavigationContainer = styled.div({
  width: "100%",
});

export const MainNavContainer = styled.div({
  width: "100%",
  backgroundColor: colors.headerBlack
});

export const MainNavigation = styled.div({
  display: "flex",
  padding: "8px 0",
  gap: "12px",
});

export const SettingButtonContainer = styled.div({
  marginLeft: "auto",
});

export const SecondaryNavigation = styled.div({
  display: "flex",
});

export const Breadcrumbs = styled.div({
  width: "100%",
  borderBottom: "1px solid",
  borderBottomColor: colors.borderColor,
  display: "flex",
  alignItems: "center",
  height: "50px",

  "& .breadcrumbs": {
    display: "flex",
    padding: "0",
    ...font.body6Medium,
  }
});


export const HeaderContainer = styled.div({
  width: "100%",
  maxWidth: "1180px",
  margin: "0 auto",
  padding: "0 32px"
})
