export interface ToastInterface {
    id: number;
    type: ToastTypeInterface;
    message: string;
}

export enum ToastTypeInterface {
    'success' = 'success',
    'error' = 'error',
    'warning' = 'warning',
    'info' = 'info',
}
