import {
    GridColumnMenuItemContent,
    GridColumnMenuItemGroup
} from '@progress/kendo-react-grid';
import {ReactComponent as IconFilter} from '../../../../assets/img/icon/filter.svg';
import {Popover, TextField} from '@mui/material';
import styles from './filters.module.scss';
import React, {useEffect, useState} from 'react';
import {GridHeaderCellProps} from '@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps';
import {FilterHeader} from './components/filter-header';

interface CheckboxFilterProps extends GridHeaderCellProps {
    options: CheckboxFilterOption[];
    selected?: string | null;
    onFilter: (filter: string) => void;
    onReset: () => void;
}

export interface CheckboxFilterOption {
    name: string,
    value: string,
    checked: boolean,
    version?:number,
}

const CheckboxFilter = (props: CheckboxFilterProps) => {

    const [options, setOptions] = useState<CheckboxFilterOption[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<any>): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOptions(prepareOptions());
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleSearch = (event: any): void => {
        const query = event.target.value.toLowerCase().trim();
        if (!query) {
            setOptions(prepareOptions());
        } else {
            const options = prepareOptions().filter((v) => v.checked === true || v.name.toLowerCase().includes(query));
            setOptions(options);
        }
    };

    const onToggleItem = (e: React.MouseEvent<HTMLDivElement>, id: number): void => {
        e.stopPropagation();
        const items = options.map((option, idx) => {
            return idx === id ? {...option, checked: !option.checked} : option;
        });
        setOptions(items);
    };

    const applyFilter = (e: React.MouseEvent<HTMLButtonElement>): void => {
        props.onFilter(options.filter(o => o.checked).map(o => o.value).join(';'));
    };

    const resetFilter = (e: React.MouseEvent<HTMLButtonElement>): void => {
        props.onReset();
        handleClose();
    };

    const prepareOptions = (): CheckboxFilterOption[] => {
        const {selected, options} = props;
        if(options){
            const checked = selected ? selected.split(';') : [];
            return options.map((option) => {
                if (checked.includes(option.value)) {
                    return {
                        ...option,
                        checked: true
                    };
                }
                return option;
            });
        }
        return [];

    };

    useEffect(() => {
        setOptions(prepareOptions());
    }, [props]);


    const open = Boolean(anchorEl);

    return (
        <div onClick={props.onClick}
             className={!!props.selected ? `${styles.header} ${styles.filtered}` : styles.header}>
            <FilterHeader {...props} handleClick={handleClick}></FilterHeader>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={(e) => e.stopPropagation()}
            >
                <div>
                    {!!options && (
                        <GridColumnMenuItemGroup>
                            <GridColumnMenuItemContent show={true}>
                                <div className="k-columnmenu-item">
                                    <span className="k-icon">
                                        <IconFilter/>
                                    </span>
                                    Фильтр
                                </div>
                                <div style={{padding: '1em'}}>
                                    <TextField
                                        style={{width: '100%'}}
                                        className={'search-field'}
                                        label="Поиск"
                                        variant="outlined"
                                        size={'small'}
                                        onChange={handleSearch}
                                    />
                                </div>
                                <div style={{overflow: 'auto', maxHeight: '310px', minWidth: '250px'}}>
                                    {options.map((item, idx) => (
                                        <div key={idx} className={styles.list_item}
                                             onClick={(e) => {
                                                 onToggleItem(e, idx);
                                             }}
                                        >
                                    <span>
                                      <input
                                          className={`k-checkbox k-checkbox-md k-rounded-md ${styles.checkbox}`}
                                          type="checkbox"
                                          readOnly={true}
                                          tabIndex={0}
                                          checked={item.checked}
                                          onClick={(e) => {
                                              onToggleItem(e, idx);
                                          }}
                                      />
                                      <label
                                          tabIndex={-1}
                                          className={`k-checkbox-label ${styles.checkbox_label}`}
                                          style={{userSelect: 'none', paddingLeft: '4px'}}
                                      >
                                        {item.name}
                                          {item.version && <span className={styles.version}>v{item.version}</span>}
                                      </label>
                                    </span>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.actions}>
                                    <button
                                        className={'k-button k-button-sm k-rounded-sm k-button-solid k-button-solid-primary'}
                                        onClick={applyFilter}
                                    >
                                        Применить
                                    </button>
                                    <button
                                        className={'k-button k-button-sm k-rounded-sm k-button-outline k-button-outline-primary'}
                                        onClick={resetFilter}
                                    >
                                        Сбросить
                                    </button>
                                </div>
                            </GridColumnMenuItemContent>
                        </GridColumnMenuItemGroup>
                    )}
                </div>
            </Popover>
        </div>
    );
};

export {CheckboxFilter};
