import styled from "@emotion/styled";
import { Tab as MuiTab, TabProps } from "@mui/material";
import { colors } from "../../themes/colors";
import { font } from "../../themes/fonts";

export const Tab = styled(MuiTab)<TabProps>({
  "&.MuiTab-root": {
    padding: "0px",
    color: colors.black,
    height: "36px !important",
    minHeight: "36px !important",
    justifyItems: "flex-start",
    minWidth: "0px !important",
    ...font.body5Medium,
  },
  "& .MuiTouchRipple-root": {
    height: "36px !important",
  },
});
