import './section-header.scss';
import {Card} from '@progress/kendo-react-layout';
import {Button, FormControlLabel, Switch, TextField} from '@mui/material';
import {SECTION_HEADER} from '../../constants';
import AddIcon from '@mui/icons-material/Add';
import {useAppDispatch, useAppSelector} from '../../hook/store';
import {
    DataViewState,
    DataViewType,
    setComponentsView,
    setConfigurationsView,
    setDictionaryView
} from '../../store/slice/data-view-slice';
import {ViewTypeButtons} from './components/view-type-buttons';
import {ReactElement, SyntheticEvent, useEffect, useState} from 'react';
import useDebounce from '../../hook/use-debounce';
import {useSearchParams} from 'react-router-dom';
import {setSearchFocus, UtilsState} from '../../store/slice/utils.slice';
import {SettingsState} from '../../store/slice/settings-slice';
import closeIcon from '../../assets/img/icon/closeIcon.svg';


type ConfiguratorHeaderProps = {
    title?: string;
    onCreate?: () => void;
    onSearch?: (searchTerm: string) => void;
    onChangeArchived?: (show: boolean) => void;
    onChangeDraft?: (show: boolean) => void;
    type?: SECTION_HEADER,
    addButtonText?: string,
    style?: 'inline' | 'full'
}


const SectionHeader = ({
                           title,
                           onCreate,
                           onSearch,
                           onChangeArchived,
                           onChangeDraft,
                           type,
                           addButtonText = 'Создать',
                           style
                       }: ConfiguratorHeaderProps) => {
    const dispatch = useAppDispatch();
    const {
        componentsView,
        dictionaryView,
        configurationsView
    } = useAppSelector<DataViewState>(store => store.dataViewType);
    const {searchFocus} = useAppSelector<UtilsState>(store => store.utils);
    const [searchTerm, setSearchTerm] = useState<string>();
    const [searchValue, setSearchValue] = useState<string>();
    const [showArchive, setShowArchive] = useState<boolean>(false);
    const [showDraft, setShowDraft] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const {settings} = useAppSelector<SettingsState>(state => state.settings);

    const debounce = useDebounce(searchTerm);

    const setView = (viewType: DataViewType): void => {
        switch (type) {
            case SECTION_HEADER.nodes:
                dispatch(setComponentsView(viewType));
                break;
            case SECTION_HEADER.dictionary:
                dispatch(setDictionaryView(viewType));
                break;
            case SECTION_HEADER.configurations:
                dispatch(setConfigurationsView(viewType));
                break;
        }
    };

    const handleArchivesChange = (e: SyntheticEvent, value: boolean): void => {
        if (onChangeArchived) {
            onChangeArchived(value);
        }
    };
    const handleDraftChange = (e: SyntheticEvent, value: boolean): void => {
        if (onChangeDraft) {
            onChangeDraft(value);
        }
    };

    const archiveSwitcher = (): ReactElement => {
        return <FormControlLabel
            control={<Switch
                onChange={handleArchivesChange}
                checked={showArchive}
            />}
            label="Показать архивные"/>;
    };

    const draftSwitcher = (): ReactElement => {
        return <FormControlLabel
            control={<Switch
                onChange={handleDraftChange}
                checked={showDraft}
            />}
            label="Показать черновики"/>;
    };

    const getBySection = (): ReactElement | null => {
        switch (type) {
            case SECTION_HEADER.specifications:
                return <>
                    {onChangeArchived && archiveSwitcher()}
                </>;
            case SECTION_HEADER.nodeType:
                return <>
                    {onChangeArchived && archiveSwitcher()}
                    {onChangeDraft && draftSwitcher()}
                </>   ;
            case SECTION_HEADER.nodes:
                return <div>
                    <ViewTypeButtons view={componentsView} setView={setView}/>
                </div>;
            case SECTION_HEADER.dictionary:
                return <div>
                    <ViewTypeButtons view={dictionaryView} setView={setView}/>
                </div>;
            case SECTION_HEADER.configurations:
                return <div>
                    <ViewTypeButtons view={configurationsView} setView={setView}/>
                </div>;
            default:
                return null;
        }
    };

    const handleSearch = (e: SyntheticEvent): void => {
        const target = e.target as HTMLInputElement;
        setSearchTerm(target.value);
    };

    const handleOnBlur = (): void => {
        dispatch(setSearchFocus(false));
    };

    const getWrapperClass = () => {
        if (style === 'inline') {
            return 'wrapper inline';
        } else {
            return 'wrapper';
        }
    };


    useEffect(() => {
        if (debounce !== undefined && onSearch) {
            dispatch(setSearchFocus(true));
            onSearch(debounce);
        }

    }, [debounce]);

    useEffect(() => {
        setSearchValue(searchParams.get('searchQuery') ?? '');
        setShowArchive(!!searchParams.get('showArchive'));
        setShowDraft(!!searchParams.get('showDraft'));
    }, [searchParams]);


    return <Card className="configurator-header-card">
        <div className={getWrapperClass()}>
            <div className={settings?.ssoEnable && title === 'Пользователи'  ? 'title title-warning' : 'title'}>
                <h3>{title || ''}</h3>
                {title === 'Пользователи' && settings?.ssoEnable && (
                    <div className="warning-container">
                        <span />
                        <div className="warning-content">
                            <div className="warning-close">
                                <img src={closeIcon} alt="closeIcon" />
                            </div>
                            <div className="warningContentText">
                                <p>Система сконфигурирована в режиме SSO - пользователи создаются автоматически при первом входе в систему.</p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="configurator-header__actions">
                    {type === SECTION_HEADER.nodes && (
                        <Button
                            sx={{marginRight: '8px'}}
                            variant={'outlined'}
                            href={'/api/node/export/excel'}
                            download
                        >
                            Скачать...
                        </Button>
                    )}
                    {!!onCreate && !(settings?.ssoEnable && title === 'Пользователи') && (
                        <Button
                            type={'button'}
                            variant={'contained'}
                            onClick={onCreate}
                        >
                            <AddIcon/> {addButtonText}
                        </Button>
                    )}
                </div>
            </div>
            <div className="search">
                <TextField
                    key={searchValue}
                    className={'search-field'}
                    label="Поиск"
                    variant="outlined"
                    size={'small'}
                    defaultValue={searchValue}
                    autoFocus={searchFocus}
                    onBlur={handleOnBlur}
                    onChange={handleSearch}
                />
                <div>
                    {
                        getBySection()
                    }
                </div>
            </div>
        </div>
    </Card>;
};

export {SectionHeader};
