export interface ApiProductList {
    result: ApiProduct[];
    total: number;
}


export interface ApiProduct {
    id?: string;
    name: string;
    sku: string;
    productTypeId: string;
    categories: string[];
    prices: ApiProductPrice[];
    properties: ApiProductProperties;
}

export interface ApiProductPrice {
    id?: string;
    priceTypeId: string;
    value: number;
    currency: string;
    unit?: string;
}

export interface ApiProductProperties {
    [key: string]: any;
}

export interface ApiProductPriceType {
    id: string;
    name: string;
    currency: string;
}

export interface ApiProductCategory {
    id: string;
    name: string;
}

export interface ApiProductType {
    id: string;
    name: string;
}


export enum ProductAction {
    edit = 'Редактировать',
    delete = 'Удалить',
}
