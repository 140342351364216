import {useAppDispatch} from '../../../hook/store';
import {ToastInterface, ToastTypeInterface} from '../../../interface';
import {ReactComponent as IconSuccess} from '../../../assets/img/icon/success.svg';
import {ReactComponent as IconWarning} from '../../../assets/img/icon/warning.svg';
import {ReactComponent as IconInfo} from '../../../assets/img/icon/info.svg';
import {ReactComponent as IconClose} from '../../../assets/img/icon/close.svg';
import './toast.scss';
import {removeToast} from '../../../store/slice/toast-slice';
import {Push} from '@progress/kendo-react-animation';
import {useEffect, useState} from 'react';
import {PushDirection} from '@progress/kendo-react-animation/dist/npm/Push';


interface ToastProps {
    toast: ToastInterface;
}

const Toast = ({toast}: ToastProps) => {
    const dispatch = useAppDispatch();
    const [show, setShow] = useState<boolean>(true);
    const [direction, setDirection] = useState<PushDirection>('left');

    const handleClick = () => {
        setShow(false);
    };

    const handleDelete = () => {
        dispatch(removeToast(toast.id));
    };

    const getIcon = () => {
        switch (toast.type) {
            case ToastTypeInterface.success:
                return <IconSuccess/>;
            case ToastTypeInterface.error:
                return <IconWarning/>;
            case ToastTypeInterface.warning:
                return <IconInfo/>;
            case ToastTypeInterface.info:
                return <IconInfo/>;
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setShow(false);
        }, toast.type === ToastTypeInterface.success ? 700 : 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return <>
        <Push appear={true} direction={direction}
              onEntered={() => setDirection('right')}
              onExited={handleDelete}
        >
            {show && <div className={`toast-item toast-${toast.type}`}>
                {getIcon()}
                <div className="content">
                    {toast.message}
                </div>
                <IconClose className="close" onClick={handleClick}/>
            </div>}
        </Push>
    </>;
};

export {Toast};
