import React, {ReactElement, useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import './component-item.clone.scss';
import {ApiCloneNodeRequest} from '../../../../interface';
import {useAppSelector} from '../../../../hook/store';
import {NodeTypeState} from '../../../../store/slice/node-type-slice';
import {NodeState} from '../../../../store/slice/node-slice';
import ObjectHelper from '../../../../helpers/object.helper';

interface ComponentItemConfirmProps {
    data: ApiCloneNodeRequest;
    inProgress: boolean;
    onSetProgress: (inProgress: boolean) => void;
    handleClose: () => void;
    handleSave: () => void;
}

interface MessageDataItem {
    title: string;
    nextValue: string;
    errorText: string;
    error: boolean;
}


const ComponentItemClone = ({data, inProgress, onSetProgress, handleClose, handleSave}: ComponentItemConfirmProps) => {
    const [messageData, setMessageData] = useState<any[]>([]);
    const {allNodeTypes} = useAppSelector<NodeTypeState>(store => store.nodeType);
    const {currentNodeItem} = useAppSelector<NodeState>(store => store.node);

    const buildMessage = (): void => {
        if (!!allNodeTypes && !!data) {
            if (Array.isArray(data.items)) {
                const messageItems: MessageDataItem[] = data.items.map(item => {
                    let propValue;
                    const node = allNodeTypes.find(t => t.id === data.nodeType);
                    const props = node?.typeSchema?.properties || [];
                    if (!!props[item.property]) {
                        propValue = props[item.property].title || '-';
                    } else if (item.property === 'name') {
                        propValue = 'Название';
                    } else {
                        propValue = item.customProperty || '-';
                    }
                    return {
                        title: `${data.nodeName} / ${propValue}`,
                        nextValue: !ObjectHelper.isNullOrUndefined(item.newValue) ? item.newValue.toString().trim() : '',
                        errorText: !ObjectHelper.isNullOrUndefined(item.errorText) ? item.errorText.toString().trim() : '',
                        error: !!item.errorText,
                    };
                });

                setMessageData(messageItems);
            }
        }
    };

    const getValue = (value: any): string | ReactElement => {
        if(value === null){
            return <span style={{fontStyle: 'italic', letterSpacing: '1px'}}>""</span>;
        }
        if(!value.toString().trim()){
            return <span style={{fontStyle: 'italic', letterSpacing: '1px'}}>""</span>;
        }
        return value.toString();
    };

    const onSave = (): void => {
        handleSave();
        onSetProgress(true);
    };

    useEffect(() => {
        buildMessage();
    }, [allNodeTypes, currentNodeItem, data]);

    return <>
        <Dialog
            className="component-item-confirm-dialog"
            open={true}
            maxWidth={'lg'}
            fullWidth={true}
            onClose={handleClose}
        >
            <DialogTitle className="dialog-title">
                Подтвердите клонирование компонета для &quot;{currentNodeItem?.name || data.nodeName}&quot;
                <IconButton
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[600],
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent className="dialog-content">
                <div className="dialog-content__title">
                    В данном компоненте будут клонированы следующие компоненты:
                </div>
                <div className="dialog-content__wrapper">
                    <div className="dialog-content__message">
                        {messageData.map((item, index) => (
                            <div key={index} className={`dialog-content__message__item ${item.error ? 'error' : ''}`}>
                                <div className="title">
                                    <div className="title-wrapper">
                                        <span className="type-name">{item.title}</span>
                                        <span className={'dotted'}></span>
                                    </div>
                                </div>
                                <div className="arrow">=&gt;</div>
                                {!item.error && <div className="new-value">{getValue(item.nextValue)}</div>}
                                {item.error && <div className="new-value">{item.errorText}</div>}
                            </div>
                        ))}
                    </div>
                </div>

            </DialogContent>

            <DialogActions className="dialog-buttons">
                        <Button
                            variant={'outlined'}
                            onClick={handleClose}
                        >
                            Отменить
                        </Button>
                        <LoadingButton
                            loading={inProgress}
                            type={'submit'}
                            variant={'contained'}
                            disabled={inProgress}
                            onClick={onSave}
                        >
                            Подтвердить
                        </LoadingButton>
            </DialogActions>
        </Dialog>
    </>;
};

export {ComponentItemClone};
