import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import baseRequest from '../../utils/base-request';
import {
    ApiProduct,
    ApiProductCategory,
    ApiProductList,
    ApiProductPriceType,
    ApiProductType,
    SearchParams
} from '../../interface';


export interface ProductState {
    products: ApiProduct[] | null;
    currentProduct: ApiProduct | null;
    priceType: ApiProductPriceType[] | null;
    productCategories: ApiProductCategory[] | null;
    productType: ApiProductType[] | null;
    productSearchParams: SearchParams;
    total: number
}


export const getProductList = createAsyncThunk(
    'product/getProductList',
    async function (params: SearchParams, {dispatch}) {
        dispatch(setSearchParams(params));
        const response = await baseRequest({
            method: 'get',
            url: '/api/product',
            params
        }, dispatch);
        return response.data;
    }
);

export const getProduct = createAsyncThunk(
    'product/getProduct',
    async function (id: string, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: `/api/product/${id}`,
        }, dispatch);
        return response.data;
    }
);

export const createProduct = createAsyncThunk(
    'product/createProduct',
    async function (data: Partial<ApiProduct>, {dispatch}) {
        const requestData = {
            sku: '',
            categories: [],
            prices: [],
            properties: {},
            ...data
        };
        const response = await baseRequest({
            method: 'post',
            url: '/api/product/',
            data: requestData
        }, dispatch);
        return response.data;
    }
);

export const updateProduct = createAsyncThunk(
    'product/updateProduct',
    async function (data: ApiProduct, {dispatch}) {
        const response = await baseRequest({
            method: 'put',
            url: '/api/product',
            data
        }, dispatch);
        return response.data;
    }
);

export const deleteProduct = createAsyncThunk<void, string, { state: { product: ProductState } }>(
    'product/deleteProduct',
    async function (id: string, {dispatch, getState}) {
        const response = await baseRequest({
            method: 'delete',
            url: `/api/product/${id}`,
        }, dispatch);
        const {productSearchParams} = getState().product;
        dispatch(getProductList(productSearchParams));
        return response.data;
    }
);


export const getPriceType = createAsyncThunk(
    'product/getPriceType',
    async function (_, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: 'api/product/priceType',
        }, dispatch);
        return response.data;
    }
);

export const getProductCategories = createAsyncThunk(
    'product/getCategory',
    async function (_, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: 'api/product/category',
        }, dispatch);
        return response.data;
    }
);

export const getProductType = createAsyncThunk(
    'product/getProductType',
    async function (_, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: 'api/product/productType',
        }, dispatch);
        return response.data;
    }
);

const initialState: ProductState = {
    products: null,
    currentProduct: null,
    priceType: null,
    productCategories: null,
    productType: null,
    productSearchParams: {} as SearchParams,
    total: 0,
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        clearProductList(state: ProductState) {
            state.products = null;
        },
        setSearchParams(state: ProductState, action: PayloadAction<SearchParams>) {
            state.productSearchParams = action.payload;
        },
        clearCurrentProduct(state: ProductState) {
            state.currentProduct = null;
        },
        resetProductState() {
            return initialState;
        }
    },
    extraReducers: {
        [getProductList.fulfilled as any]: (state: ProductState, action: PayloadAction<ApiProductList>) => {
            state.products = action.payload.result;
            state.total = action.payload.total;
        },
        [getProduct.fulfilled as any]: (state: ProductState, action: PayloadAction<ApiProduct>) => {
            state.currentProduct = action.payload;
        },
        [getPriceType.fulfilled as any]: (state: ProductState, action: PayloadAction<ApiProductPriceType[]>) => {
            state.priceType = action.payload;
        },
        [getProductCategories.fulfilled as any]: (state: ProductState, action: PayloadAction<ApiProductCategory[]>) => {
            state.productCategories = action.payload;
        },
        [getProductType.fulfilled as any]: (state: ProductState, action: PayloadAction<ApiProductType[]>) => {
            state.productType = action.payload;
        },
    }
});

export const {clearProductList, setSearchParams, clearCurrentProduct, resetProductState} = productSlice.actions;
export default productSlice.reducer;
