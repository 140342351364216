import {DropDownButton, DropDownButtonItem,} from '@progress/kendo-react-buttons';

import './specification-actions.scss';
import {
    ApiUser,
    Role,
    SpecificationItemAction,
    SpecificationItemActionData,
    SpecificationItemActionListItem,
    SpecItem,
    SpecItemStatus,
    SpecStatusCommand
} from '../../../../interface';
import React, {useEffect, useState} from 'react';
import {getActionItems} from './specification-actions.meta';
import {ActionPrompt} from '../action-prompt/action-prompt';
import {RenameDialog} from '../rename-dialog/rename-dialog';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../../hook/store';
import {
    changeAuthor,
    changeSpecificationStatus,
    cloneSpecification,
    deleteSpecification
} from '../../../../store/slice/spec-slice';
import {showError, showInfo, showSuccess} from '../../../../store/slice/toast-slice';
import {UserState} from '../../../../store/slice/user-slice';
import {RoleState} from '../../../../store/slice/role-slice';
import {ChangeUser} from './change-user';
import {ROUTE_PATH} from '../../../../constants/routes';
import {Button} from '@mui/material';
import {ReactComponent as BookIcon} from '../../../../assets/img/icon/book.svg';
import {SpecificationComment} from '../../specification-item/components/edit-comment/specification-comment';
import {SpecificationHelp} from '../help/specification-help';

interface GridActionsProps {
    itemData: SpecItem;
    itemPage: boolean;
    onAuthorChange: (user: ApiUser, itemData: SpecItem) => void;
    itemActions?: boolean
    disablePublish?: boolean
}

const SpecificationActions = ({itemData, itemPage, onAuthorChange, itemActions, disablePublish}: GridActionsProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [actionItems, setActionItems] = useState<SpecificationItemActionListItem[]>([]);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [showUserConfirm, setShowUserConfirm] = useState<boolean>(false);
    const [rename, setRename] = useState<boolean>(false);
    const [actionData, setActionData] = useState<SpecificationItemActionData>({action: undefined, item: undefined});
    const {currentUser} = useAppSelector<UserState>(store => store.user);
    const {roleList} = useAppSelector<RoleState>(store => store.role);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showHelp, setShowHelp] = useState<boolean>(false);

    useEffect(() => {
        if (currentUser && Array.isArray(roleList)) {
            const role = roleList.find((r) => r.id === currentUser.role);
            const userRole = role ? role.name : Role.ROLE_SALESMAN;
            setActionItems(getActionItems(itemPage, itemData, userRole));
        }
    }, [itemData, itemPage, currentUser, roleList]);

    const handleAction = (e: any): void => {
        const action = e.item.text;
        setActionData({action, item: itemData});
        if (action === SpecificationItemAction.open) {
            if (e.nativeEvent?.ctrlKey || e.nativeEvent?.metaKey) {
                window.open(`/${ROUTE_PATH.specifications}/${itemData.id}`, '_blank');
            } else {
                navigate(`/${ROUTE_PATH.specifications}/${itemData.id}`);
            }
            e.nativeEvent.stopPropagation();
            return;
        }
        if (action === SpecificationItemAction.rename) {
            setRename(true);
            return;
        }
        if (action === SpecificationItemAction.changeOwner) {
            setShowUserConfirm(true);
            return;
        } 
        setShowConfirm(true);
    };

    const showNotification = (item: SpecItem, command: SpecStatusCommand) => {
        let text;
        switch (command) {
            case SpecStatusCommand.publish:
                text = `Спецификация ${item.id} "${item.name}" опубликована.`;
                break;
            case SpecStatusCommand.archive:
                text = `Спецификация ${item.id} "${item.name}" перенесена в архив.`;
                break;
            case SpecStatusCommand.unarchive:
                text = `Спецификация ${item.id} "${item.name}" возвращена из архива.`;
                break;
        }
        if (text) {
            dispatch(showSuccess(text));
        }

    };

    const changeSpecStatus = (item: SpecItem, command: SpecStatusCommand): void => {
        dispatch(changeSpecificationStatus({id: item.id, command: command})).unwrap()
            .then((data: any) => {
                if (!data?.error) {
                    showNotification(item, command);
                }
            });
    };

    const deleteSpec = (item: SpecItem): void => {
        dispatch(deleteSpecification(item.id)).unwrap()
            .then(() => {
                const text = `Спецификация ${item.id} "${item.name}" удалена.`;
                dispatch(showInfo(text));
                if (itemPage) {
                    navigate(`/${ROUTE_PATH.specifications}`);
                }else{
                    setTimeout( () => {
                        window.location.reload();
                    }, 500);
                }
            })
            .catch((error: any) => {
                throw new Error(error);
            });
    };

    const cloneSpec = (item: SpecItem): void => {
        dispatch(cloneSpecification(item.id)).unwrap()
            .then((data: any) => {
                navigate(`/${ROUTE_PATH.specifications}/${data.clonedId}`);
            })
            .catch((error: any) => {
                throw new Error(error);
            });
    };

    const handleConfirmAction = (): void => {
        if (!actionData.item?.id) {
            return;
        }
        handleConfirmClose();
        switch (actionData.action) {
            case SpecificationItemAction.publish:
                changeSpecStatus(actionData.item, SpecStatusCommand.publish);
                window.location.reload();
                break;
            case SpecificationItemAction.archive:
                changeSpecStatus(actionData.item, SpecStatusCommand.archive);
                window.location.reload();
                break;
            case SpecificationItemAction.unarchive:
                changeSpecStatus(actionData.item, SpecStatusCommand.unarchive);
                window.location.reload();
                break;
            case SpecificationItemAction.delete:
                deleteSpec(actionData.item);
                break;
            case SpecificationItemAction.clone:
                cloneSpec(actionData.item);
                break;
        }
    };

    const handleUserConfirmAction = (user: ApiUser) => {
        setIsLoading(true);
        dispatch(changeAuthor({authorId: user.id, id: itemData.id})).unwrap()
            .then(() => {
                onAuthorChange(user, itemData);
                dispatch(showSuccess('Доступ к спецификации изменен.'));
            })
            .catch(() => {
                dispatch(showError('Не удалось изменить доступ к спецификации.'));
            })
            .finally(() => {
                setIsLoading(false);
                setShowUserConfirm(false);
                window.location.reload();
            });
    };

    const handleConfirmClose = (): void => {
        setShowConfirm(false);
    };

    const isDraft = (): boolean => {
        return itemData.status === SpecItemStatus.draft;
    };


    const itemRender = (itemData: { item: any; itemIndex: number }) => {
        return <div className="action-item">
            <span data-action={itemData.item.text}>{itemData.item.text}</span>
        </div>;
    };

    const handlePublish = () => {
        setActionData({action: SpecificationItemAction.publish, item: itemData});
        setShowConfirm(true);
    };

    const handleHelp = () => {
        setShowHelp(true);
    };

    return <>
        {!!itemActions && (
            <>
                {isDraft() && (
                    <Button type={'button'}
                            disableElevation={true}
                            variant={'contained'}
                            onClick={handlePublish}
                            disabled={disablePublish}
                    >
                        Опубликовать
                    </Button>
                )}

                <SpecificationComment specification={itemData}/>

                <Button
                    className={'icon-button'}
                    disableElevation={true}
                    variant={'outlined'}
                    onClick={handleHelp}
                >
                    <BookIcon/>
                </Button>
            </>
        )}
        <DropDownButton
            className={!!itemActions ? 'icon-button' : ''}
            size={'small'}
            themeColor={'primary'}
            fillMode={'solid'}
            rounded={'small'}
            icon={'more-vertical'}
            itemRender={itemRender}
            onItemClick={handleAction}
        >
            {actionItems.filter(item => item.active).map(item => (
                <DropDownButtonItem key={item.action} text={item.action}/>
            ))}
        </DropDownButton>

        {showConfirm && (
            <ActionPrompt actionData={actionData} onConfirm={handleConfirmAction} onClose={handleConfirmClose}/>
        )}
        {rename && (
            <RenameDialog specification={actionData} onClose={() => setRename(false)}/>
        )}
        {showUserConfirm && (
            <ChangeUser actionData={actionData}
                        onConfirm={handleUserConfirmAction}
                        onClose={() => setShowUserConfirm(false)}
                        author={itemData.author}
                        isLoading={isLoading}
            />
        )}
        {showHelp && (
            <SpecificationHelp onClose={() => setShowHelp(false)}/>
        )}


    </>;
};


export {SpecificationActions};
